import React from "react";

import  "./Cookie.css";




export default function VM() {

    return (

        <>

       {/* <div><NavBar/></div>  */}

        <div className="banner parbase industries-gradient aem-GridColumn aem-GridColumn--default--12">





        <section id="sml_ht_home_banner">

            <article className="container">

            <div className="row">

              <div className="col-md-9 col-sm-12 col-xs-12 ">

                    <h1 className="h2-heading white-color l-top" id="termsedit1s">

                    Cookie Policy

                    </h1>

                  </div>

            </div>

          </article>

        </section>

       

</div>

        
  <section className="privacy" >
    <article className="container">
      <div className="row row-margin">
        

      <div  class="col-md-12 col-sm12 col-xs-12 wow fadeInLeft" data-wow-delay="0.3s" >
        
        <div className="termspara"> 
        
          <div className="termsheadings">
          What is a cookie?
          </div>
          <div className="termsubpoint">A cookie is a small text file placed on your device by the websites that you visit. Cookies are then sent back to the website on each subsequent visit, or to another webpage that recognizes that cookie. Cookies are used in order to make the website work, or to work more efficiently, as well as to provide information to the owners of the website. Such information may be used to provide a more personalized and responsive service. Cookies may be either first party cookies set directly by us to your device, or third-party cookies set by a third-party provider on our behalf. Whenever you use this system, information may be collected through the use of cookies and other similar technologies (e.g., web beacons, tags, scripts, local storage). This Cookie Notice is a part of the Privacy Notice. For more information about us, and how we process personal information, please see our Privacy Notice.</div>
        
          <div className="termsheadings">
       Why we use cookie?
          </div>
          <div >This system uses cookies and web beacons that are classified into the following categories:</div>
         
          <ul id="terms-list">
    <li>“Strictly Necessary” which are necessary for the system to function and cannot be switched off in our systems. They are set in response to actions made by you which amount to a request for services, such as authenticating into the system or managing your session with the requested service.</li>
    <li>"Analytics and Performance” which are necessary for our systems to measure audience and usage statistics, as well as content consumption. These enable us to obtain relevant usage information to produce internal analytics so we can monitor and improve the performance of our systems.</li>
    <li>“Preference or functionality cookies” which allow websites to remember the users’ respective site preferences and choices (with the exclusion of essential functional cookies).</li>
    <li>“ targeting or advertising cookies” which track the users’ respective online activity to create user profiles to send advertising, or to track the user on a website or across several websites for similar marketing purposes.</li>
    </ul>
    <div style={{marginTop:'20px'}}>To view a list of the cookies that are used by the system, click the "Update your cookie preferences" button at the top of the page.</div>

        

          <div className="termsheadings">
          How long will cookies remain on my device?
          </div>
          <div className="termsubpoint">The length of time cookies will stay on your device will depend on whether it is a “persistent” or “session” cookie. A persistent cookie will be stored by a web browser and will remain valid until its set expiration date, unless deleted by you before the expiration date. A session cookie, on the other hand, will expire at the end of your web session, when the web browser is closed.</div>
        
          <div className="termsheadings">
          How can I manage cookies?
          </div>
          <div className="termsubpoint">You can manage cookies in various ways. Please keep in mind, removing or disabling certain cookies may impact your user experience and parts of this system may no longer be fully functional. You can change your cookie preferences and settings for non-essential cookies at any time by clicking on the “Manage cookie preferences” option at the top of this Cookie Notice. You may also be able to change your browser settings to manage cookies. </div>
        
        </div>
        </div> 
        </div>
        
        
       
    
    </article>
  </section>
  
        

        </>
        

    )

  }
