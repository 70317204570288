import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Container, Grid, Typography, Box, Button, Rating, TextField, Slider, IconButton } from '@mui/material';
import { 
  ComputerDesktopIcon,
  UserGroupIcon,
  DocumentIcon,
  ShieldCheckIcon,
  ClipboardDocumentCheckIcon,
  ChartBarIcon,
  DocumentTextIcon,
  CheckCircleIcon,
  LockClosedIcon,
  PhoneIcon,
  ArrowRightIcon,
  ClockIcon,
  CurrencyDollarIcon,
  CheckIcon
} from '@heroicons/react/24/outline';
import './Mortgages.css';
import { useNavigate } from 'react-router-dom';
import InfoModal from './InfoModal';

const servicesData = [
  {
    title: "Processing",
    description: "Comprehensive loan processing services including document verification, credit analysis, and application review for faster approvals.",
    Icon: DocumentTextIcon,
    details: ["Quick approval process", "Secure & compliant", "24/7 expert support"]
  },
  {
    title: "Underwriting",
    description: "Expert risk assessment and underwriting services ensuring thorough evaluation of loan applications with quick turnaround times.",
    Icon: ClipboardDocumentCheckIcon,
    details: ["Quick turnaround", "Risk assessment", "Flexible terms"]
  },
  {
    title: "Closing",
    description: "Efficient closing services handling documentation, coordination with all parties, and ensuring compliance with regulations.",
    Icon: LockClosedIcon,
    details: ["Document preparation", "Coordination with parties", "Regulatory compliance"]
  },
  {
    title: "Loan Due Diligence",
    description: "Thorough loan portfolio analysis and risk assessment services to ensure compliance and minimize potential issues.",
    Icon: ShieldCheckIcon,
    details: ["Risk assessment", "Portfolio analysis", "Compliance monitoring"]
  },
  {
    title: "Quality Control",
    description: "Comprehensive quality assurance and control measures to maintain high standards throughout the loan lifecycle.",
    Icon: CheckCircleIcon,
    details: ["Regular audits", "Risk assessment", "Continuous improvement"]
  },
  {
    title: "IT Services",
    description: "Advanced technology solutions and support for streamlined mortgage operations and enhanced digital experience.",
    Icon: ComputerDesktopIcon,
    details: ["Digital solutions", "Streamlined operations", "Enhanced user experience"]
  }
];

const faqData = [
  {
    title: "What is a good debt-to-income ratio for a home loan?",
    description: "Most lenders prefer a debt-to-income ratio below 43%. Learn how to calculate your DTI and tips to improve your ratio for better loan terms and higher approval chances.",
    duration: "5 min read",
    image: "https://images.unsplash.com/photo-1554224155-6726b3ff858f?auto=format&fit=crop&w=800&q=80",
    link: "#"
  },
  {
    title: "Buying a house without realtor",
    description: "Save on commission fees but understand the risks. Our comprehensive guide covers negotiations, paperwork, legal requirements, and essential steps for a successful purchase.",
    duration: "5 min read",
    image: "https://images.unsplash.com/photo-1570129477492-45c003edd2be?auto=format&fit=crop&w=800&q=80",
    link: "#"
  },
  {
    title: "Timeline for homebuying process",
    description: "From pre-approval to closing, understand each step. Typical timeline: 30-60 days. Learn about potential delays, required documentation, and how to speed up the process.",
    duration: "3 min read",
    image: "https://images.unsplash.com/photo-1560520653-9e0e4c89eb11?auto=format&fit=crop&w=800&q=80",
    link: "#"
  },
  {
    title: "Conventional loan requirements",
    description: "Credit score minimum: 620. Down payment: 3-20%. Learn about income requirements, debt ratios, and documentation needed. Plus tips to improve your approval odds.",
    duration: "3 min read",
    image: "https://images.unsplash.com/photo-1560518883-ce09059eeffa?auto=format&fit=crop&w=800&q=80",
    link: "#"
  }
];

const calculatorData = [
  {
    title: "Mortgage Payment Calculator",
    description: "Estimate your monthly payments including principal, interest, taxes, and insurance (PITI). Adjust loan terms and down payment to see how they affect your payments.",
    type: 'mortgage',
    image: "https://images.unsplash.com/photo-1554224155-6726b3ff858f?auto=format&fit=crop&w=800&q=80",
  },
  {
    title: "Home Affordability Calculator",
    description: "Enter your income, debts, and down payment to find your ideal price range. Includes property tax and insurance estimates based on your location.",
    type: 'affordability',
    image: "https://images.unsplash.com/photo-1570129477492-45c003edd2be?auto=format&fit=crop&w=800&q=80",
  },
  {
    title: "HELOC Calculator",
    description: "Calculate available equity and potential credit line based on your home's value and existing mortgage. Includes current market rates and payment scenarios.",
    type: 'heloc',
    image: "https://images.unsplash.com/photo-1560520653-9e0e4c89eb11?auto=format&fit=crop&w=800&q=80",
  },
  {
    title: "Fixed-Rate Comparison",
    description: "Compare 15, 20, and 30-year fixed-rate options. See total interest paid, monthly payments, and amortization schedules side by side for better decision making.",
    type: 'mortgage',
    image: "https://images.unsplash.com/photo-1560518883-ce09059eeffa?auto=format&fit=crop&w=800&q=80",
  }
];

const Mortgages = () => {
  const navigate = useNavigate();
  // Calculator States
  const [loanAmount, setLoanAmount] = useState(300000);
  const [interestRate, setInterestRate] = useState(3.5);
  const [loanTerm, setLoanTerm] = useState(30);
  const [monthlyPayment, setMonthlyPayment] = useState(0);
  const [selectedCalculator, setSelectedCalculator] = useState('mortgage');
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  // Add new state for active section
  const [activeSection, setActiveSection] = useState('guides'); // Default to guides & FAQs

  // Add product data
  const productData = [
    {
      title: "Conventional Mortgages",
      description: "Get competitive rates starting at 3.5% APR with our traditional mortgage loans. Flexible terms from 15-30 years with down payments as low as 3% for qualified buyers.",
      duration: "Learn More",
      image: "https://images.unsplash.com/photo-1560520653-9e0e4c89eb11?auto=format&fit=crop&w=800&q=80",
      link: "#"
    },
    {
      title: "FHA Loans",
      description: "Perfect for first-time homebuyers. Low down payments of 3.5% with credit scores as low as 580. Government-backed security with flexible credit requirements.",
      duration: "Learn More",
      image: "https://images.unsplash.com/photo-1582407947304-fd86f028f716?auto=format&fit=crop&w=800&q=80",
      link: "#"
    },
    {
      title: "VA Loans",
      description: "Exclusive benefits for veterans and service members. Zero down payment required, no PMI, and competitive rates. Lifetime benefit for qualified military personnel.",
      duration: "Learn More",
      image: "https://images.unsplash.com/photo-1574362848149-11496d93a7c7?auto=format&fit=crop&w=800&q=80",
      link: "#"
    },
    {
      title: "Jumbo Loans",
      description: "Finance luxury homes with loans up to $2M. Competitive rates for high-value properties, flexible terms, and personalized service for sophisticated buyers.",
      duration: "Learn More",
      image: "https://images.unsplash.com/photo-1512917774080-9991f1c4c750?auto=format&fit=crop&w=800&q=80",
      link: "#"
    }
  ];

  // Add state for calculator modal
  const [isCalculatorOpen, setIsCalculatorOpen] = useState(false);
  const [isCredentialsOpen, setIsCredentialsOpen] = useState(false);

  // Credentials data
  const credentialsData = [
    {
      title: "NMLS License",
      id: "NMLS #123456",
      description: "Licensed to operate in all 50 states"
    },
    {
      title: "State Licenses",
      id: "Multiple State IDs",
      description: "Registered with state regulatory bodies"
    },
    {
      title: "Industry Certifications",
      id: "Certified by MBA",
      description: "Member of Mortgage Bankers Association"
    },
    {
      title: "Security Compliance",
      id: "SOC 2 Type II",
      description: "Certified for data security and privacy"
    }
  ];

  // Calculator Functions
  const calculateMortgage = () => {
    const principal = loanAmount;
    const monthlyRate = (interestRate / 100) / 12;
    const numberOfPayments = loanTerm * 12;
    
    const monthlyPayment = principal * 
      (monthlyRate * Math.pow(1 + monthlyRate, numberOfPayments)) / 
      (Math.pow(1 + monthlyRate, numberOfPayments) - 1);
    
    setMonthlyPayment(monthlyPayment.toFixed(2));
  };

  const calculateAffordability = () => {
    // Assuming 28% of monthly income can go to mortgage
    const maxMonthlyPayment = loanAmount * 0.28;
    const monthlyRate = (interestRate / 100) / 12;
    const numberOfPayments = loanTerm * 12;
    
    const affordableAmount = maxMonthlyPayment * 
      (Math.pow(1 + monthlyRate, numberOfPayments) - 1) / 
      (monthlyRate * Math.pow(1 + monthlyRate, numberOfPayments));
    
    setMonthlyPayment(affordableAmount.toFixed(2));
  };

  const calculateHELOC = () => {
    // Assuming 80% of home value minus existing mortgage
    const availableEquity = loanAmount * 0.8;
    setMonthlyPayment(availableEquity.toFixed(2));
  };

  const handleCalculatorClick = (type) => {
    setSelectedCalculator(type);
    setMonthlyPayment(0); // Reset result
  };

  const handleCalculate = () => {
    switch(selectedCalculator) {
      case 'mortgage':
        calculateMortgage();
        break;
      case 'affordability':
        calculateAffordability();
        break;
      case 'heloc':
        calculateHELOC();
        break;
      default:
        calculateMortgage();
    }
  };

  // Add new state for selected info
  const [selectedInfo, setSelectedInfo] = useState(null);
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);
  const [selectedFaq, setSelectedFaq] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);

  // Remove all other declarations of these functions and keep only these ones
  const handleInfoClick = (type) => {
    setSelectedInfo(type);
    setIsInfoModalOpen(true);
  };

  const handleFaqClick = (title) => {
    setSelectedInfo(title);
    setIsInfoModalOpen(true);
  };

  const handleProductClick = (title) => {
    setSelectedInfo(title);
    setIsInfoModalOpen(true);
  };

  // Modal content data
  const infoModalContent = {
    licensing: {
      title: "Licensing Requirements",
      content: [
        {
          subtitle: "Federal Requirements",
          items: [
            {
              title: "NMLS Registration",
              description: "Mandatory registration with the Nationwide Multistate Licensing System & Registry (NMLS)"
            },
            {
              title: "Background Checks",
              description: "Criminal background checks, credit reports, and fingerprinting for all mortgage loan originators"
            }
          ]
        },
        {
          subtitle: "State Requirements",
          items: [
            {
              title: "State-Specific Licenses",
              description: "Individual state licenses and compliance with state-specific regulations"
            },
            {
              title: "Pre-licensing Education",
              description: "Completion of required pre-licensing education courses and examinations"
            }
          ]
        }
      ]
    },
    operational: {
      title: "Operational Compliance",
      content: [
        {
          subtitle: "Federal Compliance",
          items: [
            {
              title: "Federal Law Adherence",
              description: "Strict compliance with TILA, RESPA, ECOA, and other federal regulations"
            },
            {
              title: "Documentation Standards",
              description: "Maintaining comprehensive documentation for all mortgage transactions"
            }
          ]
        },
        {
          subtitle: "Risk Management",
          items: [
            {
              title: "Regular Audits",
              description: "Internal and external audits to ensure compliance with all regulations"
            },
            {
              title: "Risk Assessment",
              description: "Continuous monitoring and assessment of operational risks"
            }
          ]
        }
      ]
    },
    education: {
      title: "Education & Training",
      content: [
        {
          subtitle: "Professional Development",
          items: [
            {
              title: "Continuing Education",
              description: "Ongoing professional education to maintain licenses and certifications"
            },
            {
              title: "Industry Updates",
              description: "Regular training on new regulations and industry best practices"
            }
          ]
        },
        {
          subtitle: "Compliance Training",
          items: [
            {
              title: "Mandatory Training",
              description: "Required compliance training for all mortgage professionals"
            },
            {
              title: "Specialized Courses",
              description: "Advanced training in specific areas of mortgage lending"
            }
          ]
        }
      ]
    }
  };

  // Add detailed content for FAQs and Products
  const faqDetailContent = {
    "What is a good debt-to-income ratio for a home loan?": {
      title: "Understanding Debt-to-Income Ratio",
      content: [{
        subtitle: "DTI Basics",
        items: [
          {
            title: "What is DTI?",
            description: "Debt-to-income ratio (DTI) is the percentage of your monthly income that goes toward paying debts. It's a key factor lenders use to evaluate loan applications."
          },
          {
            title: "Ideal DTI Range",
            description: "Most lenders prefer a DTI below 43%. The lower your DTI, the better your chances of loan approval and favorable terms."
          }
        ]
      }, {
        subtitle: "Improving Your DTI",
        items: [
          {
            title: "Reducing Debt",
            description: "Pay down existing debts, especially high-interest credit cards, to lower your DTI."
          },
          {
            title: "Increasing Income",
            description: "Consider additional income sources or a higher-paying job to improve your DTI ratio."
          }
        ]
      }]
    },
    "Buying a house without realtor": {
      title: "Home Buying Without a Realtor",
      content: [{
        subtitle: "Benefits & Risks",
        items: [
          {
            title: "Potential Savings",
            description: "Save on commission fees which typically range from 2.5-3% of the purchase price."
          },
          {
            title: "Key Challenges",
            description: "Navigate complex negotiations, paperwork, and legal requirements independently."
          }
        ]
      }, {
        subtitle: "Essential Steps",
        items: [
          {
            title: "Market Research",
            description: "Conduct thorough market analysis and property valuations."
          },
          {
            title: "Legal Protection",
            description: "Work with a real estate attorney to review contracts and protect your interests."
          }
        ]
      }]
    },
    "Timeline for homebuying process": {
      title: "Understanding the Homebuying Timeline",
      content: [{
        subtitle: "Pre-Purchase Phase",
        items: [
          {
            title: "Pre-Approval Process",
            description: "Get pre-approved for a mortgage, which typically takes 3-5 business days. This step helps determine your budget and shows sellers you're serious."
          },
          {
            title: "House Hunting",
            description: "Average time is 2-3 months. Work with a realtor or search independently to find properties that match your criteria and budget."
          }
        ]
      }, {
        subtitle: "Purchase Phase",
        items: [
          {
            title: "Offer and Negotiation",
            description: "1-2 weeks for offer submission, counteroffers, and reaching an agreement on price and terms."
          },
          {
            title: "Closing Process",
            description: "30-45 days for mortgage processing, home inspection, appraisal, and final closing procedures."
          }
        ]
      }]
    },
    "Conventional loan requirements": {
      title: "Conventional Loan Requirements Guide",
      content: [{
        subtitle: "Basic Requirements",
        items: [
          {
            title: "Credit Score Requirements",
            description: "Minimum 620 credit score required. Better rates available for scores above 740. Factors include payment history and credit utilization."
          },
          {
            title: "Down Payment Options",
            description: "3-20% down payment required. PMI required for down payments less than 20%. Special programs available for first-time buyers."
          }
        ]
      }, {
        subtitle: "Financial Requirements",
        items: [
          {
            title: "Income and Employment",
            description: "Stable employment history (2+ years preferred). DTI ratio under 43%. All income sources must be verifiable."
          },
          {
            title: "Asset Requirements",
            description: "Sufficient funds for down payment and closing costs. Reserve requirements vary by loan size and type."
          }
        ]
      }]
    }
  };

  const productDetailContent = {
    "Conventional Mortgages": {
      title: "Conventional Mortgage Details",
      content: [{
        subtitle: "Key Features",
        items: [
          {
            title: "Competitive Rates",
            description: "Starting at 3.5% APR with flexible terms from 15-30 years."
          },
          {
            title: "Down Payment Options",
            description: "As low as 3% for qualified buyers with strong credit profiles."
          }
        ]
      }, {
        subtitle: "Requirements",
        items: [
          {
            title: "Credit Score",
            description: "Minimum 620 credit score required, better rates for scores above 740."
          },
          {
            title: "Income Verification",
            description: "Stable employment history and income documentation needed."
          }
        ]
      }]
    },
    "FHA Loans": {
      title: "FHA Loan Program",
      content: [{
        subtitle: "Program Benefits",
        items: [
          {
            title: "Low Down Payment",
            description: "Only 3.5% down payment required with credit scores of 580 or higher."
          },
          {
            title: "Flexible Requirements",
            description: "More lenient credit and income requirements than conventional loans."
          }
        ]
      }, {
        subtitle: "Additional Details",
        items: [
          {
            title: "Mortgage Insurance",
            description: "Required FHA mortgage insurance for the life of the loan."
          },
          {
            title: "Property Standards",
            description: "Must meet FHA property standards and appraisal requirements."
          }
        ]
      }]
    },
    "VA Loans": {
      title: "VA Loan Program Details",
      content: [{
        subtitle: "Military Benefits",
        items: [
          {
            title: "No Down Payment Required",
            description: "100% financing available for qualified veterans and active duty service members. No PMI requirement regardless of down payment."
          },
          {
            title: "Eligibility Requirements",
            description: "Available to veterans, active duty personnel, and eligible surviving spouses. Minimum service requirements vary by service period."
          }
        ]
      }, {
        subtitle: "Program Features",
        items: [
          {
            title: "Competitive Rates",
            description: "Generally lower interest rates than conventional loans. Flexible terms and refinancing options available."
          },
          {
            title: "Property Requirements",
            description: "Must be primary residence. Property must meet VA appraisal standards. Can be used for single-family homes, condos, and multi-unit properties."
          }
        ]
      }]
    },
    "Jumbo Loans": {
      title: "Jumbo Loan Program",
      content: [{
        subtitle: "Loan Features",
        items: [
          {
            title: "High-Value Financing",
            description: "Loans exceeding conventional limits (over $548,250 in most areas). Available for primary homes, second homes, and investment properties."
          },
          {
            title: "Flexible Terms",
            description: "Various term options from 15-30 years. Fixed and adjustable rate options available. Interest-only payment options for qualified borrowers."
          }
        ]
      }, {
        subtitle: "Qualification Requirements",
        items: [
          {
            title: "Credit & Income",
            description: "Minimum credit score of 700 typically required. Higher income and cash reserves required compared to conventional loans."
          },
          {
            title: "Down Payment",
            description: "Usually 10-20% down payment required. Some programs may require up to 30% for higher loan amounts or investment properties."
          }
        ]
      }]
    }
  };

  // Render content based on active section
  const renderSectionContent = () => {
    switch(activeSection) {
      case 'products':
        return (
          <Box sx={{ p: 4 }}>
            <Grid container spacing={4}>
              {productData.map((item, index) => (
                <Grid item xs={12} md={6} key={index}>
                  <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    viewport={{ once: true }}
                    transition={{ duration: 0.5, delay: index * 0.1 }}
                    style={{
                      background: '#ffffff',
                      borderRadius: '16px',
                      padding: '24px',
                      height: '100%',
                      border: '2px solid #e2e8f0',
                      boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
                      cursor: 'pointer',
                      transition: 'all 0.3s ease'
                    }}
                  >
                    <Box>
                      <Typography 
                        variant="h6" 
                        sx={{ 
                          fontSize: '1.5rem',
                          fontWeight: 600,
                          color: '#1A202C',
                          mb: 2,
                          lineHeight: 1.4
                        }}
                      >
                        {item.title}
                      </Typography>
                      <Typography 
                        variant="body2" 
                        sx={{ 
                          fontSize: '1rem',
                          color: '#4A5568',
                          lineHeight: 1.6,
                          mb: 3
                        }}
                      >
                        {item.description}
                      </Typography>
                      <Button 
                        variant="text" 
                        onClick={() => handleProductClick(item.title)}
                        sx={{ 
                          color: '#8B4513',
                          fontWeight: 600,
                          fontSize: '0.9rem',
                          textTransform: 'none',
                          padding: '8px 16px',
                          borderRadius: '8px',
                          backgroundColor: 'rgba(139, 69, 19, 0.1)',
                          '&:hover': {
                            backgroundColor: 'rgba(139, 69, 19, 0.2)'
                          }
                        }}
                      >
                        {item.duration}
                      </Button>
                    </Box>
                  </motion.div>
                </Grid>
              ))}
            </Grid>
          </Box>
        );
      
      case 'calculators':
        return (
          <Box sx={{ p: 4 }}>
            <Grid container spacing={4}>
              {calculatorData.map((item, index) => (
                <Grid item xs={12} md={6} key={index}>
                  <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    viewport={{ once: true }}
                    transition={{ duration: 0.5, delay: index * 0.1 }}
                    style={{
                      background: '#ffffff',
                      borderRadius: '16px',
                      padding: '24px',
                      height: '100%',
                      border: '2px solid #e2e8f0',
                      boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
                      cursor: 'pointer',
                      transition: 'all 0.3s ease'
                    }}
                    onClick={() => {
                      setSelectedCalculator(item.type);
                      setIsCalculatorOpen(true);
                    }}
                  >
                    <Box>
                      <Typography 
                        variant="h6" 
                        sx={{ 
                          fontSize: '1.5rem',
                          fontWeight: 600,
                          color: '#1A202C',
                          mb: 2,
                          lineHeight: 1.4
                        }}
                      >
                        {item.title}
                      </Typography>
                      <Typography 
                        variant="body2" 
                        sx={{ 
                          fontSize: '1rem',
                          color: '#4A5568',
                          lineHeight: 1.6,
                          mb: 3
                        }}
                      >
                        {item.description}
                      </Typography>
                      <Button 
                        variant="text" 
                        sx={{ 
                          color: '#8B4513',
                          fontWeight: 600,
                          fontSize: '0.9rem',
                          textTransform: 'none',
                          padding: '8px 16px',
                          borderRadius: '8px',
                          backgroundColor: 'rgba(139, 69, 19, 0.1)',
                          '&:hover': {
                            backgroundColor: 'rgba(139, 69, 19, 0.2)'
                          }
                        }}
                      >
                        Try Calculator
                      </Button>
                    </Box>
                  </motion.div>
                </Grid>
              ))}
            </Grid>
          </Box>
        );
      
      default: // guides & FAQs
        return (
          <Box sx={{ p: 4 }}>
            <Grid container spacing={4}>
              {faqData.map((item, index) => (
                <Grid item xs={12} md={6} key={index}>
                  <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    viewport={{ once: true }}
                    transition={{ duration: 0.5, delay: index * 0.1 }}
                    style={{
                      background: '#ffffff',
                      borderRadius: '16px',
                      padding: '24px',
                      height: '100%',
                      border: '2px solid #e2e8f0',
                      boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
                      cursor: 'pointer',
                      transition: 'all 0.3s ease'
                    }}
                  >
                    <Box>
                      <Typography 
                        variant="h6" 
                        sx={{ 
                          fontSize: '1.5rem',
                          fontWeight: 600,
                          color: '#1A202C',
                          mb: 2,
                          lineHeight: 1.4
                        }}
                      >
                        {item.title}
                      </Typography>
                      <Typography 
                        variant="body2" 
                        sx={{ 
                          fontSize: '1rem',
                          color: '#4A5568',
                          lineHeight: 1.6,
                          mb: 3
                        }}
                      >
                        {item.description}
                      </Typography>
                      <Box sx={{ 
                        display: 'flex', 
                        justifyContent: 'space-between', 
                        alignItems: 'center',
                        mt: 'auto'
                      }}>
                        <Typography 
                          variant="caption" 
                          sx={{ 
                            color: '#718096',
                            fontSize: '0.875rem',
                            fontWeight: 500
                          }}
                        >
                          {item.duration}
                        </Typography>
                        <Button 
                          variant="text" 
                          onClick={() => handleFaqClick(item.title)}
                          sx={{ 
                            color: '#8B4513',
                            fontWeight: 600,
                            fontSize: '0.9rem',
                            textTransform: 'none',
                            padding: '8px 16px',
                            borderRadius: '8px',
                            backgroundColor: 'rgba(139, 69, 19, 0.1)',
                            '&:hover': {
                              backgroundColor: 'rgba(139, 69, 19, 0.2)'
                            }
                          }}
                        >
                          Read more
                        </Button>
                      </Box>
                    </Box>
                  </motion.div>
                </Grid>
              ))}
            </Grid>
          </Box>
        );
    }
  };

  return (
    <div className="mortgages-page">
      {/* Hero Section */}
      <section className="mortgage-hero">
        <Container maxWidth="lg">
          <Grid container spacing={6} alignItems="center" className="hero-content">
            <Grid item xs={12} md={6}>
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8 }}
                className="hero-text-content"
              >
                <Typography variant="h1" className="hero-title">
                  Smart Mortgage <br />
                  Solutions
                </Typography>
                <Typography 
                  variant="body1" 
                  sx={{ 
                    fontSize: '1.1rem',
                    color: 'rgba(255, 255, 255, 0.9)',
                    marginBottom: '1.5rem',
                    maxWidth: '540px',
                    lineHeight: 1.6,
                    fontWeight: 500,
                    textShadow: '0 1px 2px rgba(0, 0, 0, 0.1)'
                  }}
                >
                  Streamlined mortgage services tailored to your needs. Get started with our expert guidance today.
                </Typography>
                <Box sx={{ mt: 4, mb: 3 }} className="hero-cta">
                  <Button 
                    variant="contained" 
                    className="start-button"
                    endIcon={<ArrowRightIcon className="w-5 h-5" />}
                    onClick={() => {
                      setIsCalculatorOpen(true);
                      setActiveSection('products');
                      window.scrollTo({
                        top: document.querySelector('.faq-section').offsetTop,
                        behavior: 'smooth'
                      });
                    }}
                  >
                    Start Your Journey
                  </Button>
                  <Box className="rating-box">
                    <Rating value={5} readOnly />
                    <Typography variant="body2" className="rating-text">
                      Trusted by thousands of homeowners
                    </Typography>
                  </Box>
                </Box>
                <Box className="hero-features">
                  <Box className="feature-item">
                    <CheckCircleIcon />
                    <p>Quick Approval Process</p>
                  </Box>
                  <Box className="feature-item">
                    <LockClosedIcon />
                    <p>Secure & Compliant</p>
                  </Box>
                  <Box className="feature-item">
                    <PhoneIcon />
                    <p>24/7 Expert Support</p>
                  </Box>
                </Box>
              </motion.div>
            </Grid>
            <Grid item xs={12} md={6}>
              <motion.div
                initial={{ opacity: 0, scale: 0.95 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.8, delay: 0.2 }}
                className="hero-image-container"
              >
                <img 
                  src="https://images.unsplash.com/photo-1560518883-ce09059eeffa?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1000&q=80"
                  alt="Modern Home Exterior" 
                  className="hero-image"
                />
                <Box className="floating-card">
                  <Typography variant="h6">Competitive Rates</Typography>
                  <Typography variant="body2">Starting from</Typography>
                  <Typography variant="h4">3.5% APR</Typography>
                </Box>
              </motion.div>
            </Grid>
          </Grid>
        </Container>
      </section>

      {/* Compliance Section */}
      <section className="why-better-section">
        <Container maxWidth="lg">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.6 }}
            style={{ marginBottom: '2rem' }}
          >
            <Typography variant="h2" className="why-better-title">
              LICENSED & COMPLIANCE SERVICE
            </Typography>
          </motion.div>
          
          <Grid container spacing={6} alignItems="center">
            <Grid item xs={12} md={6}>
              <motion.div
                initial={{ opacity: 0, x: -30 }}
                whileInView={{ opacity: 1, x: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.6 }}
              >
                <img 
                  src="https://images.unsplash.com/photo-1554469384-e58fac16e23a?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1000&q=80"
                  alt="Professional Mortgage Service" 
                  className="testimonial-image"
                />
              </motion.div>
            </Grid>
            <Grid item xs={12} md={6}>
              <motion.div
                initial={{ opacity: 0, x: 30 }}
                whileInView={{ opacity: 1, x: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.6 }}
              >
                <Box>
                  <Typography variant="body1" className="why-better-description">
                    Our mortgage services adhere to all federal and state regulations, ensuring your peace of mind throughout the lending process. We maintain the highest standards of compliance and security in all our operations, providing you with a trustworthy and reliable mortgage experience.
                  </Typography>
                  <Button 
                    variant="contained" 
                    className="learn-more-button"
                    onClick={() => setIsCredentialsOpen(true)}
                  >
                    View Our Credentials
                  </Button>
                </Box>
              </motion.div>
            </Grid>
          </Grid>
        </Container>
      </section>

      {/* Services Section */}
      <section className="tools-section">
        <Container>
          <Box className="section-header" sx={{ mb: 6, textAlign: 'center' }}>
            <Typography variant="h2" className="section-title" sx={{
              fontSize: { xs: '2.5rem', md: '3.5rem' },
              fontWeight: 700,
              color: '#8B4513',
              mb: 2,
              textTransform: 'uppercase'
            }}>
              SERVICES WE OFFER
            </Typography>
            <Typography variant="h5" sx={{
              fontSize: { xs: '1rem', md: '1.2rem' },
              color: '#4A5568',
              maxWidth: '800px',
              mx: 'auto'
            }}>
              Comprehensive mortgage services tailored to meet your specific needs with expertise and efficiency.
            </Typography>
          </Box>
          
          <Grid container spacing={4}>
            {servicesData.map((service, index) => (
              <Grid item xs={12} md={4} key={index}>
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true }}
                  transition={{ duration: 0.5, delay: index * 0.1 }}
                  className="tool-card"
                >
                  <div className="tool-card-inner">
                    <div className="tool-card-front">
                      <service.Icon className="tool-icon" />
                      <Typography variant="h6" className="tool-title">
                        {service.title}
                      </Typography>
                      <Typography variant="body2" className="tool-description">
                        {service.description}
                      </Typography>
                    </div>
                    <div className="tool-card-back">
                      <Typography variant="h6" className="tool-title">
                        {service.title}
                      </Typography>
                      <div className="service-details">
                        {service.details?.map((detail, idx) => (
                          <div key={idx} className="service-detail-item">
                            <CheckIcon />
                            <Typography variant="body2">
                              {detail}
                            </Typography>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </motion.div>
              </Grid>
            ))}
          </Grid>
        </Container>
      </section>

      {/* Requirements Section */}
      <section className="compliance-section">
        <Container maxWidth="lg">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.6 }}
          >
            <Typography 
              variant="h2" 
              className="compliance-title"
            >
              COMPLIANCE & REQUIREMENTS
            </Typography>
          </motion.div>
          
          <Grid container spacing={4} sx={{ mt: 4 }}>
            <Grid item xs={12} md={4}>
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.5 }}
                className="info-card"
              >
                <Box className="card-content">
                  <img 
                    src="https://images.unsplash.com/photo-1589829545856-d10d557cf95f?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=800&q=80"
                    alt="Licensing Requirements" 
                    className="card-image"
                  />
                  <Typography variant="h6" className="card-title">
                    Licensing Requirements
                  </Typography>
                  <Typography variant="body2" sx={{ 
                    px: 3, 
                    pb: 2, 
                    color: '#666',
                    '& > *': {
                      display: 'block',
                      marginBottom: '8px',
                      position: 'relative',
                      paddingLeft: '20px',
                      '&::before': {
                        content: '"•"',
                        position: 'absolute',
                        left: '0',
                        color: '#8B4513',
                        fontWeight: 'bold'
                      }
                    }
                  }}>
                    • NMLS Registration<br/>
                    • State-specific licenses<br/>
                    • Pre-licensing education<br/>
                    • Background checks
                  </Typography>
                  <Button 
                    className="card-button"
                    onClick={() => handleInfoClick('licensing')}
                  >
                    Learn more →
                  </Button>
                </Box>
              </motion.div>
            </Grid>

            <Grid item xs={12} md={4}>
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.5, delay: 0.1 }}
                className="info-card"
              >
                <Box className="card-content">
                  <img 
                    src="https://images.unsplash.com/photo-1554224155-8d04cb21cd6c?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=800&q=80"
                    alt="Operational Compliance" 
                    className="card-image"
                  />
                  <Typography variant="h6" className="card-title">
                    Operational Compliance
                  </Typography>
                  <Typography variant="body2" sx={{ 
                    px: 3, 
                    pb: 2, 
                    color: '#666',
                    '& > *': {
                      display: 'block',
                      marginBottom: '8px',
                      position: 'relative',
                      paddingLeft: '20px',
                      '&::before': {
                        content: '"•"',
                        position: 'absolute',
                        left: '0',
                        color: '#8B4513',
                        fontWeight: 'bold'
                      }
                    }
                  }}>
                    • Federal law adherence<br/>
                    • Documentation standards<br/>
                    • Regular audits<br/>
                    • Risk assessment
                  </Typography>
                  <Button 
                    className="card-button"
                    onClick={() => handleInfoClick('operational')}
                  >
                    Learn more →
                  </Button>
                </Box>
              </motion.div>
            </Grid>

            <Grid item xs={12} md={4}>
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.5, delay: 0.2 }}
                className="info-card"
              >
                <Box className="card-content">
                  <img 
                    src="https://images.unsplash.com/photo-1590283603385-17ffb3a7f29f?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=800&q=80"
                    alt="Continuing Education" 
                    className="card-image"
                  />
                  <Typography variant="h6" className="card-title">
                    Education & Training
                  </Typography>
                  <Typography variant="body2" sx={{ 
                    px: 3, 
                    pb: 2, 
                    color: '#666',
                    '& > *': {
                      display: 'block',
                      marginBottom: '8px',
                      position: 'relative',
                      paddingLeft: '20px',
                      '&::before': {
                        content: '"•"',
                        position: 'absolute',
                        left: '0',
                        color: '#8B4513',
                        fontWeight: 'bold'
                      }
                    }
                  }}>
                    • Continuing education<br/>
                    • Professional development<br/>
                    • Compliance training<br/>
                    • Industry updates
                  </Typography>
                  <Button 
                    className="card-button"
                    onClick={() => handleInfoClick('education')}
                  >
                    Learn more →
                  </Button>
                </Box>
              </motion.div>
            </Grid>
          </Grid>

          <Box sx={{ mt: 4, display: 'flex', gap: 2, justifyContent: 'center' }}>
            <Button 
              variant="outlined" 
              className="action-button"
              onClick={() => navigate('/compliance/guide')}
            >
              <ShieldCheckIcon className="button-icon" />
              Compliance Guide
            </Button>
            <Button 
              variant="outlined" 
              className="action-button"
              onClick={() => navigate('/compliance/documentation')}
            >
              <DocumentTextIcon className="button-icon" />
              Documentation
            </Button>
          </Box>
        </Container>
      </section>

      {/* FAQ Section */}
      <section className="faq-section">
        <Container maxWidth="lg">
          <Box className="section-header">
            <Typography variant="h2" className="faq-heading">
              GOT QUESTIONS?
            </Typography>
            <Typography variant="h3" className="faq-subheading">
              WE'VE GOT ANSWERS
            </Typography>
          </Box>
          
          <Box sx={{ 
            display: 'flex',
            justifyContent: 'center',
            gap: 2,
            mb: 6,
            p: 1,
            borderRadius: '20px',
            background: 'rgba(139, 69, 19, 0.08)',
            width: 'fit-content',
            mx: 'auto'
          }}>
            <Button 
              variant="contained" 
              onClick={() => setActiveSection('products')}
              sx={{
                minWidth: '160px',
                py: 1.5,
                borderRadius: '16px',
                textTransform: 'none',
                fontSize: '1rem',
                fontWeight: 500,
                background: activeSection === 'products' 
                  ? 'linear-gradient(135deg, #8B4513, #D2691E)' 
                  : 'transparent',
                color: activeSection === 'products' ? '#fff' : '#8B4513',
                boxShadow: activeSection === 'products' 
                  ? '0 4px 12px rgba(139, 69, 19, 0.3)' 
                  : 'none',
                '&:hover': {
                  background: activeSection === 'products'
                    ? 'linear-gradient(135deg, #8B4513, #D2691E)'
                    : 'rgba(139, 69, 19, 0.15)'
                }
              }}
            >
              Our products
            </Button>
            <Button 
              variant="contained" 
              onClick={() => setActiveSection('calculators')}
              sx={{
                minWidth: '160px',
                py: 1.5,
                borderRadius: '16px',
                textTransform: 'none',
                fontSize: '1rem',
                fontWeight: 500,
                background: activeSection === 'calculators' 
                  ? 'linear-gradient(135deg, #8B4513, #D2691E)' 
                  : 'transparent',
                color: activeSection === 'calculators' ? '#fff' : '#8B4513',
                boxShadow: activeSection === 'calculators' 
                  ? '0 4px 12px rgba(139, 69, 19, 0.3)' 
                  : 'none',
                '&:hover': {
                  background: activeSection === 'calculators'
                    ? 'linear-gradient(135deg, #8B4513, #D2691E)'
                    : 'rgba(139, 69, 19, 0.15)'
                }
              }}
            >
              Calculators
            </Button>
            <Button 
              variant="contained" 
              onClick={() => setActiveSection('guides')}
              sx={{
                minWidth: '160px',
                py: 1.5,
                borderRadius: '16px',
                textTransform: 'none',
                fontSize: '1rem',
                fontWeight: 500,
                background: activeSection === 'guides' 
                  ? 'linear-gradient(135deg, #8B4513, #D2691E)' 
                  : 'transparent',
                color: activeSection === 'guides' ? '#fff' : '#8B4513',
                boxShadow: activeSection === 'guides' 
                  ? '0 4px 12px rgba(139, 69, 19, 0.3)' 
                  : 'none',
                '&:hover': {
                  background: activeSection === 'guides'
                    ? 'linear-gradient(135deg, #8B4513, #D2691E)'
                    : 'rgba(139, 69, 19, 0.15)'
                }
              }}
            >
              Guides & FAQs
            </Button>
          </Box>

          {renderSectionContent()}

          {/* Calculator Modal */}
          <AnimatePresence>
            {isCalculatorOpen && (
              <motion.div 
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                style={{
                  position: 'fixed',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  background: 'rgba(0, 0, 0, 0.6)',
                  backdropFilter: 'blur(8px)',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  zIndex: 1000,
                  padding: '20px'
                }}
              >
                <motion.div 
                  initial={{ scale: 0.95, y: 20 }}
                  animate={{ scale: 1, y: 0 }}
                  exit={{ scale: 0.95, y: 20 }}
                  transition={{ type: "spring", stiffness: 300, damping: 30 }}
                  style={{
                    background: '#fff',
                    borderRadius: '24px',
                    width: '100%',
                    maxWidth: '560px',
                    maxHeight: '90vh',
                    overflow: 'auto',
                    position: 'relative',
                    boxShadow: '0 20px 50px rgba(0, 0, 0, 0.2)'
                  }}
                >
                  <Box sx={{ 
                    p: 4, 
                    borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                  }}>
                    <Typography variant="h5" sx={{ fontWeight: 600 }}>
                      {selectedCalculator === 'mortgage' ? 'Mortgage Calculator' :
                       selectedCalculator === 'affordability' ? 'Affordability Calculator' :
                       'HELOC Calculator'}
                    </Typography>
                    <IconButton 
                      onClick={() => setIsCalculatorOpen(false)}
                      sx={{
                        width: 36,
                        height: 36,
                        borderRadius: '50%',
                        background: 'rgba(0, 0, 0, 0.05)',
                        '&:hover': {
                          background: 'rgba(0, 0, 0, 0.1)',
                          transform: 'rotate(90deg)'
                        },
                        transition: 'all 0.3s ease'
                      }}
                    >
                      ×
                    </IconButton>
                  </Box>
                  
                  <Box sx={{ p: 4 }}>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          label="Loan Amount"
                          type="number"
                          value={loanAmount}
                          onChange={(e) => setLoanAmount(Number(e.target.value))}
                          InputProps={{
                            startAdornment: <Box component="span" sx={{ mr: 1 }}>$</Box>
                          }}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Typography gutterBottom>Interest Rate (%)</Typography>
                        <Slider
                          value={interestRate}
                          onChange={(e, newValue) => setInterestRate(newValue)}
                          min={0}
                          max={10}
                          step={0.1}
                          valueLabelDisplay="auto"
                          sx={{
                            color: '#8B4513',
                            '& .MuiSlider-thumb': {
                              background: 'linear-gradient(135deg, #8B4513, #D2691E)',
                            },
                            '& .MuiSlider-track': {
                              background: 'linear-gradient(135deg, #8B4513, #D2691E)',
                            }
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Typography gutterBottom>Loan Term (Years)</Typography>
                        <Slider
                          value={loanTerm}
                          onChange={(e, newValue) => setLoanTerm(newValue)}
                          min={5}
                          max={30}
                          step={5}
                          valueLabelDisplay="auto"
                          marks={[
                            { value: 5, label: '5y' },
                            { value: 15, label: '15y' },
                            { value: 30, label: '30y' },
                          ]}
                          sx={{
                            color: '#8B4513',
                            '& .MuiSlider-thumb': {
                              background: 'linear-gradient(135deg, #8B4513, #D2691E)',
                            },
                            '& .MuiSlider-track': {
                              background: 'linear-gradient(135deg, #8B4513, #D2691E)',
                            }
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Button 
                          variant="contained"
                          onClick={handleCalculate}
                          fullWidth
                          sx={{
                            background: 'linear-gradient(135deg, #8B4513, #D2691E)',
                            color: '#fff',
                            padding: '12px',
                            fontSize: '1rem',
                            fontWeight: 600,
                            textTransform: 'none',
                            '&:hover': {
                              background: 'linear-gradient(135deg, #D2691E, #8B4513)',
                            }
                          }}
                        >
                          Calculate
                        </Button>
                      </Grid>
                    </Grid>

                    <AnimatePresence>
                      {monthlyPayment > 0 && (
                        <motion.div
                          initial={{ opacity: 0, y: 10 }}
                          animate={{ opacity: 1, y: 0 }}
                          exit={{ opacity: 0, y: -10 }}
                          transition={{ duration: 0.3 }}
                          style={{
                            marginTop: '32px',
                            padding: '24px',
                            background: 'linear-gradient(135deg, rgba(139, 69, 19, 0.1), rgba(210, 105, 30, 0.1))',
                            borderRadius: '16px',
                            textAlign: 'center'
                          }}
                        >
                          <Typography variant="h6" sx={{ color: '#8B4513', mb: 1 }}>
                            {selectedCalculator === 'mortgage' ? 'Monthly Payment' :
                             selectedCalculator === 'affordability' ? 'You Can Afford' :
                             'Available Equity'}
                          </Typography>
                          <Typography variant="h4" sx={{ color: '#8B4513', fontWeight: 700 }}>
                            ${Number(monthlyPayment).toLocaleString()}
                          </Typography>
                        </motion.div>
                      )}
                    </AnimatePresence>
                  </Box>
                </motion.div>
              </motion.div>
            )}
          </AnimatePresence>

          {/* Credentials Modal */}
          <AnimatePresence>
            {isCredentialsOpen && (
              <motion.div 
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                style={{
                  position: 'fixed',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  background: 'rgba(0, 0, 0, 0.6)',
                  backdropFilter: 'blur(8px)',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  zIndex: 1000,
                  padding: '20px'
                }}
              >
                <motion.div 
                  initial={{ scale: 0.95, y: 20 }}
                  animate={{ scale: 1, y: 0 }}
                  exit={{ scale: 0.95, y: 20 }}
                  transition={{ type: "spring", stiffness: 300, damping: 30 }}
                  style={{
                    background: '#fff',
                    borderRadius: '24px',
                    width: '100%',
                    maxWidth: '600px',
                    maxHeight: '90vh',
                    overflow: 'auto',
                    position: 'relative',
                    boxShadow: '0 20px 50px rgba(0, 0, 0, 0.2)'
                  }}
                >
                  <Box sx={{ 
                    p: 4, 
                    borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                  }}>
                    <Typography variant="h5" sx={{ fontWeight: 600 }}>
                      Our Credentials & Licenses
                    </Typography>
                    <IconButton 
                      onClick={() => setIsCredentialsOpen(false)}
                      sx={{
                        width: 36,
                        height: 36,
                        borderRadius: '50%',
                        background: 'rgba(0, 0, 0, 0.05)',
                        '&:hover': {
                          background: 'rgba(0, 0, 0, 0.1)',
                          transform: 'rotate(90deg)'
                        },
                        transition: 'all 0.3s ease'
                      }}
                    >
                      ×
                    </IconButton>
                  </Box>
                  
                  <Box sx={{ p: 4 }}>
                    <Grid container spacing={3}>
                      {credentialsData.map((credential, index) => (
                        <Grid item xs={12} key={index}>
                          <Box
                            sx={{
                              p: 3,
                              borderRadius: '16px',
                              border: '1px solid rgba(139, 69, 19, 0.2)',
                              background: 'linear-gradient(135deg, rgba(139, 69, 19, 0.05), rgba(210, 105, 30, 0.05))',
                              transition: 'all 0.3s ease',
                              '&:hover': {
                                transform: 'translateY(-2px)',
                                boxShadow: '0 4px 20px rgba(139, 69, 19, 0.1)'
                              }
                            }}
                          >
                            <Typography variant="h6" sx={{ color: '#8B4513', mb: 1 }}>
                              {credential.title}
                            </Typography>
                            <Typography variant="subtitle1" sx={{ color: '#1A202C', fontWeight: 600, mb: 1 }}>
                              {credential.id}
                            </Typography>
                            <Typography variant="body2" sx={{ color: '#4A5568' }}>
                              {credential.description}
                            </Typography>
                          </Box>
                        </Grid>
                      ))}
                      <Grid item xs={12}>
                        <Typography variant="body2" sx={{ textAlign: 'center', color: '#718096', mt: 2 }}>
                          For verification or more information about our credentials, please contact our compliance department.
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                </motion.div>
              </motion.div>
            )}
          </AnimatePresence>

          {/* Info Modal */}
          <InfoModal 
            isOpen={isInfoModalOpen}
            onClose={() => setIsInfoModalOpen(false)}
            title={selectedInfo ? (
              faqDetailContent[selectedInfo]?.title || 
              productDetailContent[selectedInfo]?.title || 
              infoModalContent[selectedInfo]?.title
            ) : ''}
            content={selectedInfo ? (
              faqDetailContent[selectedInfo]?.content || 
              productDetailContent[selectedInfo]?.content || 
              infoModalContent[selectedInfo]?.content
            ) : []}
          />
        </Container>
      </section>

      {/* Why Choose Us Section */}
      <section className="why-choose-section">
        <Container maxWidth="lg">
          <Box className="section-header" sx={{ mb: 6, textAlign: 'center' }}>
            <Typography variant="h2" className="section-title" sx={{
              fontSize: { xs: '2.5rem', md: '3.5rem' },
              fontWeight: 700,
              color: '#8B4513',
              mb: 2,
              textTransform: 'uppercase'
            }}>
              Why Choose Us
            </Typography>
            <Typography variant="h5" sx={{
              fontSize: { xs: '1rem', md: '1.2rem' },
              color: '#4A5568',
              maxWidth: '800px',
              mx: 'auto'
            }}>
              Your Trusted Partner in Mortgage Solutions
            </Typography>
          </Box>

          <Grid container spacing={4}>
            {/* Stats Section */}
            <Grid item xs={12}>
              <Box sx={{
                p: { xs: 2, md: 4 },
                background: 'linear-gradient(135deg, rgba(139, 69, 19, 0.02), rgba(210, 105, 30, 0.08))',
                borderRadius: '30px',
                position: 'relative',
                overflow: 'hidden'
              }}>
                <Box sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  background: 'radial-gradient(circle at 30% 50%, rgba(139, 69, 19, 0.08) 0%, transparent 50%), radial-gradient(circle at 70% 50%, rgba(210, 105, 30, 0.08) 0%, transparent 50%)',
                  zIndex: 0
                }} />
                
                <Grid container spacing={3} sx={{ position: 'relative', zIndex: 1 }}>
                  {[
                    { label: 'Professional Experience', icon: ClockIcon, description: 'Years of dedicated service' },
                    { label: 'Happy Clients', icon: UserGroupIcon, description: 'Satisfied homeowners' },
                    { label: 'Success Rate', icon: CheckCircleIcon, description: 'Loan approvals' },
                    { label: 'Loans Processed', icon: CurrencyDollarIcon, description: 'Total value funded' }
                  ].map((stat, index) => (
                    <Grid item xs={12} sm={6} md={3} key={index}>
                      <motion.div
                        initial={{ opacity: 0, y: 20 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        viewport={{ once: true }}
                        transition={{ duration: 0.5, delay: index * 0.1 }}
                      >
                        <Box sx={{
                          position: 'relative',
                          p: 3,
                          '&::before': {
                            content: '""',
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            background: '#fff',
                            borderRadius: '24px',
                            transform: 'skew(-6deg)',
                            transition: 'all 0.3s ease',
                            boxShadow: '0 4px 20px rgba(139, 69, 19, 0.08)'
                          },
                          '&:hover::before': {
                            transform: 'skew(-3deg) scale(1.02)',
                            boxShadow: '0 8px 30px rgba(139, 69, 19, 0.15)'
                          },
                          '&::after': {
                            content: '""',
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '4px',
                            background: 'linear-gradient(90deg, #8B4513, #D2691E)',
                            borderRadius: '4px 4px 0 0',
                            transform: 'skew(-6deg)',
                            transformOrigin: 'top'
                          }
                        }}>
                          <Box sx={{
                            position: 'relative',
                            zIndex: 1,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            gap: 2
                          }}>
                            <Box sx={{
                              width: 65,
                              height: 65,
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              position: 'relative'
                            }}>
                              <Box sx={{
                                position: 'absolute',
                                width: '100%',
                                height: '100%',
                                background: 'linear-gradient(135deg, rgba(139, 69, 19, 0.1), rgba(210, 105, 30, 0.1))',
                                borderRadius: '20px',
                                transform: 'rotate(45deg)',
                                transition: 'all 0.3s ease'
                              }} />
                              <stat.icon className="w-8 h-8" style={{ 
                                color: '#8B4513',
                                position: 'relative',
                                zIndex: 1
                              }} />
                            </Box>
                            <Box sx={{ textAlign: 'center' }}>
                              <Typography variant="h6" sx={{
                                color: '#8B4513',
                                fontWeight: 600,
                                fontSize: '1.1rem',
                                mb: 1,
                                position: 'relative'
                              }}>
                                {stat.label}
                              </Typography>
                              <Typography variant="body2" sx={{
                                color: '#666',
                                fontSize: '0.9rem',
                                position: 'relative'
                              }}>
                                {stat.description}
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                      </motion.div>
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </Grid>

            {/* Features Grid */}
            <Grid item xs={12} md={6}>
              <motion.div
                initial={{ opacity: 0, x: -20 }}
                whileInView={{ opacity: 1, x: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.5 }}
              >
                <Box sx={{
                  p: 4,
                  borderRadius: '24px',
                  background: 'linear-gradient(145deg, #fff, #f8f9fa)',
                  boxShadow: '0 4px 20px rgba(139, 69, 19, 0.1)',
                  height: '100%',
                  position: 'relative',
                  overflow: 'hidden'
                }}>
                  <Box sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '5px',
                    background: 'linear-gradient(90deg, #8B4513, #D2691E)'
                  }} />
                  
                  <Typography variant="h4" sx={{
                    fontSize: '2rem',
                    fontWeight: 700,
                    color: '#8B4513',
                    mb: 4,
                    position: 'relative',
                    display: 'inline-block',
                    '&::after': {
                      content: '""',
                      position: 'absolute',
                      bottom: -8,
                      left: 0,
                      width: '60%',
                      height: '3px',
                      background: 'linear-gradient(90deg, #8B4513, transparent)'
                    }
                  }}>
                    Expert Guidance
                  </Typography>

                  <Grid container spacing={3}>
                    {[
                      {
                        title: 'Personalized Solutions',
                        description: 'Customized mortgage plans for your unique needs',
                        icon: UserGroupIcon
                      },
                      {
                        title: 'Expert Advisory',
                        description: 'Professional guidance at every step',
                        icon: DocumentTextIcon
                      },
                      {
                        title: 'Fast Processing',
                        description: 'Streamlined and efficient approvals',
                        icon: ClockIcon
                      },
                      {
                        title: 'Best Rates',
                        description: 'Competitive and transparent pricing',
                        icon: CurrencyDollarIcon
                      }
                    ].map((item, index) => (
                      <Grid item xs={12} key={index}>
                        <motion.div
                          initial={{ opacity: 0, x: -20 }}
                          whileInView={{ opacity: 1, x: 0 }}
                          viewport={{ once: true }}
                          transition={{ duration: 0.3, delay: index * 0.1 }}
                        >
                          <Box sx={{
                            display: 'flex',
                            gap: 3,
                            p: 2,
                            borderRadius: '16px',
                            background: 'linear-gradient(145deg, #fff, #f8f9fa)',
                            boxShadow: '8px 8px 20px rgba(139, 69, 19, 0.08), -8px -8px 20px #ffffff',
                            transition: 'all 0.3s ease',
                            '&:hover': {
                              transform: 'translateY(-5px)',
                              boxShadow: '12px 12px 24px rgba(139, 69, 19, 0.12), -12px -12px 24px #ffffff'
                            }
                          }}>
                            <Box sx={{
                              minWidth: '50px',
                              height: '50px',
                              borderRadius: '12px',
                              background: 'linear-gradient(135deg, rgba(139, 69, 19, 0.1), rgba(210, 105, 30, 0.1))',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center'
                            }}>
                              <item.icon className="w-6 h-6" style={{ color: '#8B4513' }} />
                            </Box>
                            <Box>
                              <Typography variant="h6" sx={{
                                fontSize: '1.1rem',
                                fontWeight: 600,
                                color: '#8B4513',
                                mb: 0.5
                              }}>
                                {item.title}
                              </Typography>
                              <Typography variant="body2" sx={{
                                color: '#666',
                                lineHeight: 1.5
                              }}>
                                {item.description}
                              </Typography>
                            </Box>
                          </Box>
                        </motion.div>
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              </motion.div>
            </Grid>

            <Grid item xs={12} md={6}>
              <motion.div
                initial={{ opacity: 0, x: 20 }}
                whileInView={{ opacity: 1, x: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.5 }}
              >
                <Box sx={{
                  p: 4,
                  borderRadius: '24px',
                  background: 'linear-gradient(145deg, #fff, #f8f9fa)',
                  boxShadow: '0 4px 20px rgba(139, 69, 19, 0.1)',
                  height: '100%',
                  position: 'relative',
                  overflow: 'hidden'
                }}>
                  <Box sx={{
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    width: '5px',
                    height: '100%',
                    background: 'linear-gradient(180deg, #8B4513, #D2691E)'
                  }} />

                  <Typography variant="h4" sx={{
                    fontSize: '2rem',
                    fontWeight: 700,
                    color: '#8B4513',
                    mb: 4,
                    position: 'relative',
                    display: 'inline-block',
                    '&::after': {
                      content: '""',
                      position: 'absolute',
                      bottom: -8,
                      left: 0,
                      width: '60%',
                      height: '3px',
                      background: 'linear-gradient(90deg, #8B4513, transparent)'
                    }
                  }}>
                    Our Commitment
                  </Typography>

                  <Grid container spacing={3}>
                    {[
                      {
                        title: 'Transparent Process',
                        description: 'Clear communication and no hidden fees',
                        icon: DocumentTextIcon
                      },
                      {
                        title: '24/7 Support',
                        description: 'Always here when you need us',
                        icon: PhoneIcon
                      },
                      {
                        title: 'Digital Solutions',
                        description: 'Modern tools for your convenience',
                        icon: ComputerDesktopIcon
                      },
                      {
                        title: 'Rate Promise',
                        description: 'Best-in-market rate guarantee',
                        icon: ShieldCheckIcon
                      }
                    ].map((item, index) => (
                      <Grid item xs={12} key={index}>
                        <motion.div
                          initial={{ opacity: 0, x: 20 }}
                          whileInView={{ opacity: 1, x: 0 }}
                          viewport={{ once: true }}
                          transition={{ duration: 0.3, delay: index * 0.1 }}
                        >
                          <Box sx={{
                            position: 'relative',
                            p: 3,
                            borderRadius: '16px',
                            background: 'linear-gradient(145deg, #fff, #f8f9fa)',
                            boxShadow: '8px 8px 20px rgba(139, 69, 19, 0.08), -8px -8px 20px #ffffff',
                            transition: 'all 0.3s ease',
                            overflow: 'hidden',
                            '&:hover': {
                              transform: 'translateY(-5px)',
                              boxShadow: '12px 12px 24px rgba(139, 69, 19, 0.12), -12px -12px 24px #ffffff',
                              '& .hover-gradient': {
                                opacity: 1
                              }
                            }
                          }}>
                            <Box className="hover-gradient" sx={{
                              position: 'absolute',
                              top: 0,
                              left: 0,
                              right: 0,
                              bottom: 0,
                              background: 'linear-gradient(135deg, rgba(139, 69, 19, 0.05), rgba(210, 105, 30, 0.05))',
                              opacity: 0,
                              transition: 'opacity 0.3s ease'
                            }} />
                            
                            <Box sx={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: 2,
                              position: 'relative',
                              zIndex: 1
                            }}>
                              <Box sx={{
                                width: '48px',
                                height: '48px',
                                borderRadius: '14px',
                                background: '#fff',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                boxShadow: '4px 4px 10px rgba(139, 69, 19, 0.1), -4px -4px 10px #ffffff'
                              }}>
                                <item.icon className="w-6 h-6" style={{ color: '#8B4513' }} />
                              </Box>
                              <Box>
                                <Typography variant="h6" sx={{
                                  fontSize: '1.1rem',
                                  fontWeight: 600,
                                  color: '#8B4513',
                                  mb: 0.5
                                }}>
                                  {item.title}
                                </Typography>
                                <Typography variant="body2" sx={{
                                  color: '#666',
                                  lineHeight: 1.5
                                }}>
                                  {item.description}
                                </Typography>
                              </Box>
                            </Box>
                          </Box>
                        </motion.div>
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              </motion.div>
            </Grid>
          </Grid>
        </Container>
      </section>

      {/* Contact & Support Section */}
      <section className="contact-support-section" style={{ marginTop: '6rem' }}>
        <Container maxWidth="lg">
          <Box className="section-header" sx={{ mb: 8, textAlign: 'center' }}>
            <Typography variant="h2" className="section-title" sx={{
              fontSize: { xs: '2.5rem', md: '3.5rem' },
              fontWeight: 700,
              color: '#8B4513',
              mb: 2,
              textTransform: 'uppercase'
            }}>
              Contact & Support
            </Typography>
            <Typography variant="h5" sx={{
              fontSize: { xs: '1rem', md: '1.2rem' },
              color: '#4A5568',
              maxWidth: '800px',
              mx: 'auto'
            }}>
              We're Here to Help You Every Step of the Way
            </Typography>
          </Box>

          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.5 }}
              >
                <Box sx={{
                  p: 4,
                  borderRadius: '24px',
                  background: 'linear-gradient(145deg, #fff, #f8f9fa)',
                  boxShadow: '0 4px 20px rgba(139, 69, 19, 0.1)',
                  position: 'relative',
                  overflow: 'hidden'
                }}>
                  <Box sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '5px',
                    background: 'linear-gradient(90deg, #8B4513, #D2691E)'
                  }} />

                  <Typography variant="h4" sx={{
                    fontSize: '2rem',
                    fontWeight: 700,
                    color: '#8B4513',
                    mb: 4,
                    position: 'relative',
                    display: 'inline-block',
                    '&::after': {
                      content: '""',
                      position: 'absolute',
                      bottom: -8,
                      left: 0,
                      width: '60%',
                      height: '3px',
                      background: 'linear-gradient(90deg, #8B4513, transparent)'
                    }
                  }}>
                    Get in Touch
                  </Typography>

                  <form onSubmit={(e) => {
                    e.preventDefault();
                    // Handle form submission here
                    console.log('Form submitted');
                    // Reset form
                    e.target.reset();
                    // Show success message
                    setShowSuccessMessage(true);
                    // Hide success message after 5 seconds
                    setTimeout(() => {
                      setShowSuccessMessage(false);
                    }, 5000);
                  }}>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <Box sx={{ position: 'relative' }}>
                          <TextField
                            fullWidth
                            required
                            label="Full Name"
                            variant="outlined"
                            sx={{
                              '& .MuiOutlinedInput-root': {
                                borderRadius: '12px',
                                backgroundColor: '#fff',
                                '&:hover fieldset': {
                                  borderColor: '#8B4513',
                                },
                                '&.Mui-focused fieldset': {
                                  borderColor: '#8B4513',
                                }
                              },
                              '& .MuiInputLabel-root': {
                                color: '#666',
                                '&.Mui-focused': {
                                  color: '#8B4513'
                                }
                              },
                              '& .MuiOutlinedInput-input': {
                                color: '#8B4513',
                                '&::placeholder': {
                                  color: '#666',
                                  opacity: 0.7
                                }
                              }
                            }}
                          />
                          <UserGroupIcon style={{ 
                            position: 'absolute',
                            right: '14px',
                            top: '50%',
                            transform: 'translateY(-50%)',
                            color: '#8B4513',
                            width: '20px',
                            height: '20px',
                            opacity: 0.5
                          }} />
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                        <Box sx={{ position: 'relative' }}>
                          <TextField
                            fullWidth
                            required
                            type="email"
                            label="Email Address"
                            variant="outlined"
                            sx={{
                              '& .MuiOutlinedInput-root': {
                                borderRadius: '12px',
                                backgroundColor: '#fff',
                                '&:hover fieldset': {
                                  borderColor: '#8B4513',
                                },
                                '&.Mui-focused fieldset': {
                                  borderColor: '#8B4513',
                                }
                              },
                              '& .MuiInputLabel-root': {
                                color: '#666',
                                '&.Mui-focused': {
                                  color: '#8B4513'
                                }
                              },
                              '& .MuiOutlinedInput-input': {
                                color: '#8B4513',
                                '&::placeholder': {
                                  color: '#666',
                                  opacity: 0.7
                                }
                              }
                            }}
                          />
                          <DocumentTextIcon style={{ 
                            position: 'absolute',
                            right: '14px',
                            top: '50%',
                            transform: 'translateY(-50%)',
                            color: '#8B4513',
                            width: '20px',
                            height: '20px',
                            opacity: 0.5
                          }} />
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                        <Box sx={{ position: 'relative' }}>
                          <TextField
                            fullWidth
                            required
                            label="Phone Number"
                            variant="outlined"
                            sx={{
                              '& .MuiOutlinedInput-root': {
                                borderRadius: '12px',
                                backgroundColor: '#fff',
                                '&:hover fieldset': {
                                  borderColor: '#8B4513',
                                },
                                '&.Mui-focused fieldset': {
                                  borderColor: '#8B4513',
                                }
                              },
                              '& .MuiInputLabel-root': {
                                color: '#666',
                                '&.Mui-focused': {
                                  color: '#8B4513'
                                }
                              },
                              '& .MuiOutlinedInput-input': {
                                color: '#8B4513',
                                '&::placeholder': {
                                  color: '#666',
                                  opacity: 0.7
                                }
                              }
                            }}
                          />
                          <PhoneIcon style={{ 
                            position: 'absolute',
                            right: '14px',
                            top: '50%',
                            transform: 'translateY(-50%)',
                            color: '#8B4513',
                            width: '20px',
                            height: '20px',
                            opacity: 0.5
                          }} />
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          required
                          label="Message"
                          multiline
                          rows={4}
                          variant="outlined"
                          sx={{
                            '& .MuiOutlinedInput-root': {
                              borderRadius: '12px',
                              backgroundColor: '#fff',
                              '&:hover fieldset': {
                                borderColor: '#8B4513',
                              },
                              '&.Mui-focused fieldset': {
                                borderColor: '#8B4513',
                              }
                            },
                            '& .MuiInputLabel-root': {
                              color: '#666',
                              '&.Mui-focused': {
                                color: '#8B4513'
                              }
                            },
                            '& .MuiOutlinedInput-input': {
                              color: '#8B4513',
                              '&::placeholder': {
                                color: '#666',
                                opacity: 0.7
                              }
                            }
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <AnimatePresence>
                          {showSuccessMessage && (
                            <motion.div
                              initial={{ opacity: 0, y: 20 }}
                              animate={{ opacity: 1, y: 0 }}
                              exit={{ opacity: 0, y: -20 }}
                              transition={{ duration: 0.5 }}
                            >
                              <Box sx={{
                                p: 3,
                                borderRadius: '16px',
                                background: 'linear-gradient(135deg, rgba(139, 69, 19, 0.1), rgba(210, 105, 30, 0.1))',
                                display: 'flex',
                                alignItems: 'center',
                                gap: 2,
                                mb: 3
                              }}>
                                <Box sx={{
                                  width: '48px',
                                  height: '48px',
                                  borderRadius: '50%',
                                  background: 'linear-gradient(135deg, #8B4513, #D2691E)',
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  color: '#fff'
                                }}>
                                  <CheckCircleIcon className="w-6 h-6" />
                                </Box>
                                <Box>
                                  <Typography variant="h6" sx={{ color: '#8B4513', fontWeight: 600, mb: 0.5 }}>
                                    Message Received!
                                  </Typography>
                                  <Typography variant="body2" sx={{ color: '#666' }}>
                                    We'll get back to you within 24 hours.
                                  </Typography>
                                </Box>
                              </Box>
                            </motion.div>
                          )}
                        </AnimatePresence>
                      </Grid>
                      <Grid item xs={12}>
                        <Button
                          type="submit"
                          fullWidth
                          variant="contained"
                          sx={{
                            background: 'linear-gradient(135deg, #8B4513, #D2691E)',
                            color: '#fff',
                            py: 2,
                            fontSize: '1.1rem',
                            fontWeight: 600,
                            borderRadius: '12px',
                            textTransform: 'none',
                            boxShadow: '0 4px 15px rgba(139, 69, 19, 0.2)',
                            '&:hover': {
                              background: 'linear-gradient(135deg, #D2691E, #8B4513)',
                              transform: 'translateY(-2px)',
                              boxShadow: '0 6px 20px rgba(139, 69, 19, 0.3)',
                            },
                            transition: 'all 0.3s ease'
                          }}
                        >
                          Send Message
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                </Box>
              </motion.div>
            </Grid>

            <Grid item xs={12} md={6}>
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    viewport={{ once: true }}
                    transition={{ duration: 0.5 }}
                  >
                    <Box sx={{
                      p: 4,
                      borderRadius: '24px',
                      background: 'linear-gradient(145deg, #fff, #f8f9fa)',
                      boxShadow: '0 4px 20px rgba(139, 69, 19, 0.1)',
                      position: 'relative',
                      overflow: 'hidden',
                      '&:hover': {
                        transform: 'translateY(-5px)',
                        boxShadow: '0 8px 30px rgba(139, 69, 19, 0.15)',
                      },
                      transition: 'all 0.3s ease'
                    }}>
                      <Box sx={{
                        position: 'absolute',
                        top: 0,
                        right: 0,
                        width: '5px',
                        height: '100%',
                        background: 'linear-gradient(180deg, #8B4513, #D2691E)'
                      }} />

                      <Typography variant="h4" sx={{
                        fontSize: '2rem',
                        fontWeight: 700,
                        color: '#8B4513',
                        mb: 4
                      }}>
                        Support Hours
                      </Typography>

                      <Grid container spacing={3}>
                        {[
                          { day: 'Monday - Friday', hours: '9:00 AM - 6:00 PM' },
                          { day: 'Saturday', hours: '10:00 AM - 4:00 PM' },
                          { day: 'Sunday', hours: 'Closed' }
                        ].map((schedule, index) => (
                          <Grid item xs={12} key={index}>
                            <Box sx={{
                              p: 2,
                              borderRadius: '12px',
                              background: 'rgba(139, 69, 19, 0.05)',
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center'
                            }}>
                              <Typography sx={{ color: '#8B4513', fontWeight: 600 }}>
                                {schedule.day}
                              </Typography>
                              <Typography sx={{ color: '#666' }}>
                                {schedule.hours}
                              </Typography>
                            </Box>
                          </Grid>
                        ))}
                      </Grid>
                    </Box>
                  </motion.div>
                </Grid>

                <Grid item xs={12}>
                  <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    viewport={{ once: true }}
                    transition={{ duration: 0.5, delay: 0.2 }}
                  >
                    <Box sx={{
                      p: 4,
                      borderRadius: '24px',
                      background: 'linear-gradient(145deg, #fff, #f8f9fa)',
                      boxShadow: '0 4px 20px rgba(139, 69, 19, 0.1)',
                      position: 'relative',
                      overflow: 'hidden',
                      '&:hover': {
                        transform: 'translateY(-5px)',
                        boxShadow: '0 8px 30px rgba(139, 69, 19, 0.15)',
                      },
                      transition: 'all 0.3s ease'
                    }}>
                      <Box sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '5px',
                        background: 'linear-gradient(90deg, #8B4513, #D2691E)'
                      }} />

                      <Typography variant="h4" sx={{
                        fontSize: '2rem',
                        fontWeight: 700,
                        color: '#8B4513',
                        mb: 4
                      }}>
                        Emergency Support
                      </Typography>

                      <Grid container spacing={3}>
                        <Grid item xs={12}>
                          <Box sx={{
                            p: 3,
                            borderRadius: '16px',
                            background: 'linear-gradient(135deg, rgba(139, 69, 19, 0.05), rgba(210, 105, 30, 0.05))',
                            display: 'flex',
                            alignItems: 'center',
                            gap: 2
                          }}>
                            <Box sx={{
                              width: '48px',
                              height: '48px',
                              borderRadius: '12px',
                              background: '#fff',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              boxShadow: '0 4px 12px rgba(139, 69, 19, 0.1)'
                            }}>
                              <PhoneIcon className="w-6 h-6" style={{ color: '#8B4513' }} />
                            </Box>
                            <Box>
                              <Typography sx={{ color: '#666', mb: 0.5 }}>
                                24/7 Emergency Line
                              </Typography>
                              <Typography sx={{ color: '#8B4513', fontWeight: 700, fontSize: '1.2rem' }}>
                                1-800-MORTGAGE
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item xs={12}>
                          <Box sx={{
                            p: 3,
                            borderRadius: '16px',
                            background: 'linear-gradient(135deg, rgba(139, 69, 19, 0.05), rgba(210, 105, 30, 0.05))',
                            display: 'flex',
                            alignItems: 'center',
                            gap: 2
                          }}>
                            <Box sx={{
                              width: '48px',
                              height: '48px',
                              borderRadius: '12px',
                              background: '#fff',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              boxShadow: '0 4px 12px rgba(139, 69, 19, 0.1)'
                            }}>
                              <DocumentTextIcon className="w-6 h-6" style={{ color: '#8B4513' }} />
                            </Box>
                            <Box>
                              <Typography sx={{ color: '#666', mb: 0.5 }}>
                                Email Support
                              </Typography>
                              <Typography sx={{ color: '#8B4513', fontWeight: 700, fontSize: '1.2rem' }}>
                                support@digitechub.com
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  </motion.div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </section>
    </div>
  );
};

export default Mortgages; 