import React, { useState } from 'react'
import { Link } from 'react-router-dom';

export default function TechnologyModal() {
 
  return (
    <div>
    <div className="col-md-12 col-sm-12 col-xs-12">
      <h2 className="submenu-h2-heading mb10" style={{paddingTop:'10px',paddingLeft:'20px',paddingBottom:'20px'}}>Technologies</h2>
    </div>

    <div id="business-container" className="container">
      <div className="row g-2">
        <div className="col-6">
          
          <div className="row">
            <Link to="/asp-business-one" className="abc">
            Blockchain
            </Link>
          </div>{" "}
          <hr />
          <div className="row">
            <Link to="/hana" className="abc">
            Generative AI
            </Link>
          </div>{" "}
          <hr />
          <div className="row">
            <Link to="/bo-bi" className="abc">
            AI Enabled Image Objet Recognition
            </Link>
          </div>{" "}
          <hr />
          <div className="row">
            <Link to="/iot-integration" className="abc">
            NFC's
            </Link>
          </div>{" "}
          <hr />
          <div className="row">
            <Link to="/customization-consulting" className="abc">
              {" "}
              Sping Secuirty, OWASP 
            </Link>
          </div>{" "}
          <hr />
        </div>

        <div className="col-6">
         
          <div className="row">
            <Link to="/developement" className="abc">
            Distributed Computing with IOT;s
            </Link>
          </div>{" "}
          <hr />
          <div className="row">
            <Link to="/bpo-kpo" className="abc">
            Drones with Blockchain
            </Link>
          </div>{" "}
          <hr />
          <div className="row">
            <Link to="/cloud-services" className="abc">
            Cyber Security
            </Link>
          </div>{" "}
          <hr />
       
        </div>
      </div>
    </div>
  </div>
  )
}
