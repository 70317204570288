import React, { useState } from "react";
import "./DropdownMenu.css";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

const Dropdownmenu5 = () => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleLinkClick = () => {
    setIsHovered(false);
  };

  return (
    <div
      className="dropdown"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <span className="ok">Innovations</span>
      {isHovered && (
        <div className="dropdown-content-5">
          <h3 className="allplatforms">
            Our Innovations <FontAwesomeIcon icon={faArrowRight} />
          </h3>

          <hr className="linehr" />
          <div className="dard">
            <div className="case-study-section">
              <h4 className="section-title small-text">E-Commerce Solutions</h4>
              <Link className="link-aj" to="/platform/E-cart/casestudt-bharatmake" onClick={handleLinkClick}>
                BharatMake.com
              </Link>
              <Link className="link-aj" to="/platform/E-cart/casestudy-digitalkeystore" onClick={handleLinkClick}>
                Digital Key Store
              </Link>
              <Link className="link-aj" to="/platform/E-cart/casestudy-vitualwallet" onClick={handleLinkClick}>
                Virtual Wallet
              </Link>
            </div>

            <div className="case-study-section">
              <h4 className="section-title small-text">Security & Compliance</h4>
              <Link className="link-aj" to="/platform/cloudecipher/casestudy-Empowering-taxcollection" onClick={handleLinkClick}>
                Tax Collection Department
              </Link>
              <Link className="link-aj" to="/plaform/cloudcipher/casestudy-Enhancing-Data-Integrity" onClick={handleLinkClick}>
                Integrity in PharmaCo's R&D Division
              </Link>
              <Link className="link-aj" to="/platform/cloudcipher/casestudy-Securing-Operations-Bank" onClick={handleLinkClick}>
                Bank Security
              </Link>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Dropdownmenu5;
