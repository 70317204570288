import React from 'react';
import './CaseStudy.css';
import CsForm from './CsForm.js';

const CsDigitalKeyStore = () => {
  return (
    <div className="main-casestudydiv">
      <section className="caseStudysection2">
        <div className="casestudytitle">
          <div className="casestudylable">CASE STUDY</div>
          <h1 className="case-headind1">
            {" "}
            DigitalKeyStore - Revolutionizing Digital Software Distribution.
          </h1>
        </div>
      </section>
 
      <div className="part2 col">
        <section className="para-in case-pt75">
          <article className="case-container">
            <div className="row-k">
              <div
                className="row-in case-mb20 fadeInUp animated"
                data-wow-delay="0.3s"
                style={{
                  visibility: "visible",
                  WebkitAnimationDelay: "0.3s",
                  MozAnimationDelay: "0.3s",
                  animationDelay: "0.3s",
                }}
              >
                <p className="part2-para">
                DigitalKeyStore, an e-commerce platform specializing in the digital delivery of antivirus software keys, developed a custom platform to handle its web and mobile application operations, akin to Shopify but with unique, tailored features. This case study explores how DigitalKeyStore seamlessly integrated various components to offer a streamlined, secure, and user-friendly shopping experience.
                </p>
                <p className="part2-para">
                The goal was to provide a seamless experience for customers purchasing antivirus software keys while ensuring a secure, efficient back-end operation.
                </p>
              </div>
            </div>
            <div className="row-k case-mt45">
              <div className="row-in2 ">
                <div
                  className="yellow-bg pad-all wow fadeInUp animated"
                  data-wow-delay="1.3s"
                  style={{
                    visibility: "visible",
                    WebkitAnimationDelay: "1.3s",
                    MozAnimationDelay: "1.3s",
                    animationDelay: "1.3s",
                  }}
                >
                  <div className="row1 row-k">
                    <div className="col1">
                      <p>
                        {" "}
                        <strong>Key Challenges</strong>
                      </p>
                      <ul className="case-list-items">
                        <li>
                        Scalability: Managing high volumes of transactions during peak sale periods.
                        </li>
                        <li>
                        Security: Ensuring secure transactions and protecting sensitive customer data.
                        </li>
                        <li>
                        User Experience: Providing a seamless, intuitive shopping experience on both web and mobile platforms.
                        </li>
                        <li>
                        Digital Key Management: Efficiently distributing and managing a vast inventory of digital keys.
                        </li>
                      </ul>
                    </div>
                    <div className="col2">
                      <p>
                        {" "}
                        <strong>Ready to experience?</strong>
                      </p>
                      <a
                        href="#rfs"
                        className="scrollto-target pdf"
                        title="Talk To Experts"
                      >
                        TALK TO EXPERTS
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </article>
        </section>
      </div>
      <div className="part3">
        <section  className="case-pt0 case-pb75">
          <article className="case-container">
            <div className="row-in2">
              <div
                className="row-in2 wow fadeInUp animated"
                data-wow-delay="0.3s"
                style={{
                  visibility: "visible",
                  WebkitAnimationDelay: "0.3s",
                  MozAnimationDelay: "0.3s",
                  animationDelay: "0.3s",
                }}
              >
                <h2 className="h2-heading-cs text-center">The Solution</h2>
                <h3 className=" para-txt col-md-part-2 pd-lr light-gray text-center mb50">
                DigitalKeyStore's custom-developed platform included
                </h3>
              </div>
              <div
                className="col-part1 wow fadeInLeft animated"
                data-wow-delay="0.6s"
                style={{
                  visibility: "visible",
                  WebkitAnimationDelay: "0.6s",
                  MozAnimationDelay: "0.6s",
                  animationDelay: "0.6s",
                }}
              >
                <p>
                  <strong>E-commerce Platform</strong>
                </p>
                <ul className="case-list-items">
                  <li>
                  A scalable architecture to handle surges in traffic and transactions. 
                  </li>
                  <li>
                  Advanced security protocols to safeguard transactions and customer data.
                  </li>
                  <li>
                  A user-friendly interface for easy navigation and purchasing on both web and mobile apps.
                  </li>
                  
                </ul>
                <p>
                  <strong>Digital Key Distribution System</strong>
                </p>
                <ul className="case-list-items">
                  <li>
                  An automated system for the secure distribution of digital keys post-purchase.
                  </li>
                  <li>
                    Real-time inventory management to track available keys and prevent overselling.
                  </li>
                </ul>
                <p>
                  <strong>Mobile and Web Applications</strong>
                </p>
                <ul className="case-list-items">
                  <li>
                  Custom-designed mobile and web applications, providing a consistent brand experience.
                  </li>
                  <li>
                  Features such as wish lists, user reviews, and easy checkout to enhance user engagement.
                  </li>
                </ul>
              </div>
            </div>
            <div className="row case-mt45">
              <div className="">
                <div
                  className="yellow-bg pad-all wow fadeInUp animated"
                  data-wow-delay="1.3s"
                  style={{
                    visibility: "visible",
                    WebkitAnimationDelay: "1.3s",
                    MozAnimationDelay: "1.3s",
                    animationDelay: "1.3s",
                  }}
                >
                  <h2 className="head-case">
                  Implementation
                  </h2>
                  <div className="row case-txt-para">
                    <div className="col1">
                      <ul className="case-list-items">
                        <li>
                        Phase 1 – Design and Development: Meticulous planning to design a platform that catered to the specific needs of digital software sales.
                        </li>
                        <li>
                        Phase 2 – Beta Testing: A pilot launch with a select user base to refine user interface and iron out any operational kinks.
                        </li>
                        <li>	Phase 3 – Full Launch: A full-scale launch of the platform, with ongoing monitoring and user feedback collection.</li>
                        <li>Phase 4 – Continuous Improvement: Regular updates and feature enhancements based on customer feedback and technological advancements..</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
              
          </article>
        </section>
      </div>
      <div className="part4 col">
        <section className="para-in ">
          <article className="case-container">
            
            <div className="row-k ">
              <div className="row-in2 ">
                <div
                  className=" pad-all wow fadeInUp animated">
                  <div className="part4-row1 row-k">
                    <div className="col1">
                    <p>
                  <strong>Results</strong>
                </p>
                <ul className="case-list-items">
                  <li>
                  Enhanced User Experience: Customer satisfaction rates soared due to the intuitive design and reliability of the platform.  
                  </li>
                  <li>
                  Increased Sales: Streamlined operations and an enhanced user experience led to a 40% increase in sales.
                  </li>
                  <li>
                  Efficient Key Distribution: The automated key distribution system significantly reduced the delivery time of digital products.
                  </li>
                </ul>
                    </div>
                    <div className="col2">
                    <p>
                  <strong>Conclusion</strong>
                </p>
                <ul className="-list-items">
                  <li>
                  DigitalKeyStore's custom-developed e-commerce platform set a new standard in the digital distribution of software keys. By prioritizing customer experience, security, and operational efficiency, DigitalKeyStore successfully carved out a niche in the competitive digital software market.
                  </li>
                  
                </ul>
                    </div>
                    

                  </div>
                </div>
              </div>
            </div>
          </article>
        </section>
      </div>
        
      <CsForm />
    </div>
  );
};
 
export default CsDigitalKeyStore;