import React, { useEffect } from 'react';
import './CaseStudyCard.css'; // Import the CSS file

const CaseStudyCard = ({ imageUrl, title, description ,linkUrl}) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      const cards = document.querySelectorAll('.card');
      const hoverLinks = document.querySelectorAll('a.hover');
      
      if (cards.length > 0) {
        cards.forEach(card => {
          card.classList.remove('hover');
        });
      }
      
      if (hoverLinks.length > 0) {
        hoverLinks.forEach(a => {
          a.classList.remove('hover');
        });
      }
    }, 1800);

    return () => clearTimeout(timer);
  }, []);


  const HoverLink = ({ href, children }) => {
    return (
      <a href={href} className="hover">{children}</a>
    );
  }

  return (
    <div className="card">
      <div className="card-img" style={{backgroundImage: `url(${imageUrl})`}}>
        <div className="overlay">
          <div className="overlay-content">
          {/* <HoverLink href={linkUrl}>Case Study</HoverLink> */}
          </div>
        </div>
      </div>
      
      <div className="card-content">
        <a href="#!">
          <h2 className='casestudyjsx-h2'>{title}</h2>
          <div className='casestudyjsx-p'>{description}</div>
        </a>
      </div>
    </div>
  );
}

export default CaseStudyCard;
