import "./template.css";
 
import { Link } from "react-router-dom";
import ReadModal2 from "./readMore.js";
import React, { useState, useRef, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CarouselSlider from "./Carousel.js";
import CaseStudyCard from "./CaseStudyCard.js";

import Accordion from "./Accordion";
import "./Accordion.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
 
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
 
const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
};
 
function ManagedServices() {
  const [activeModal, setActiveModal] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const sliderRef = useRef(null);
  const modalRef = useRef(null);
  const [selectedSlide, setSelectedSlide] = useState(null);
  const settings = {
    // dots: true,
    // infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
 
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
 
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 3000,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const slideData = [
    {
      id: 1,
      heading: "Expertise Across Domains",
      text: "Our team brings in-depth knowledge across ADM, ERP, Cloud, and Testing, ensuring a holistic approach to managing your IT landscape. ",
      buttonLabel: "Read More",
      modalContent: {
        // modalHeading: 'Modal Heading 1',
        modalText:
          "CloudCipher seamlessly integrates with existing workflows and software ecosystems, minimizing disruption and streamlining the adoption process. Whether migrating from another platform or implementing new security measures, CloudCipher ensures a smooth transition with minimal downtime.",
      },
    },
    {
      id: 2,
      heading: "Customized Solutions",
      text: " We understand that one size does not fit all, offering tailored services that align with your specific business requirements.  ",
      buttonLabel: "Read More",
      modalContent: {
        modalHeading: "Modal Heading 2",
        modalText: "Modal Text 2",
      },
    },
 
    {
      id: 3,
      heading: "Proactive Management",
      text: " Our proactive approach to maintenance and support helps in identifying and resolving issues before they impact your operations.  ",
      buttonLabel: "Read More",
      modalContent: {
        modalHeading: "Modal Heading 3",
        modalText: "Modal Text 3",
      },
    },
 
    {
      id: 4,
      heading: "Focus on Innovation",
      text: "While we manage your current technology needs, we also keep an eye on emerging technologies that can further enhance your business operations.  ",
      buttonLabel: "Read More",
      modalContent: {
        modalHeading: "Modal Heading 4",
        modalText: "Modal Text 4",
      },
    },
 
    {
      id: 5,
      heading: "Reliable Support",
      text: '  With our round-the-clock support, we ensure your business operations are always running without any hitches.  "',
      buttonLabel: "Read More",
      modalContent: {
        modalHeading: "Modal Heading 5",
        modalText: "Modal Text 5",
      },
    },
 
    // {
    //   id: 6,
    //   heading: 'Sixth Slide',
    //   text: 'A different text for the second slide.',
    //   buttonLabel: 'Read More',
    //   modalContent: {
    //     modalHeading: 'Modal Heading 6',
    //     modalText: 'Modal Text 6',
    //   },
    // },
 
    // {
    //   id: 7,
    //   heading: 'Seventh Slide',
    //   text: 'A different text for the second slide.',
    //   buttonLabel: 'Read More',
    //   modalContent: {
    //     modalHeading: 'Modal Heading 7',
    //     modalText: 'Modal Text 7',
    //   },
    // },
 
    // {
    //   id: 8,
    //   heading: 'Eighth Slide',
    //   text: 'A different text for the second slide.',
    //   buttonLabel: 'Read More',
    //   modalContent: {
    //     modalHeading: 'Modal Heading 8',
    //     modalText: 'Modal Text 8',
    //   },
    // },
    // ... (more slides)
  ];

  let items = [
    {
      title: "Q: How do your managed services improve operational efficiency? ",
      content:
        "A: By taking over the responsibility of managing your IT infrastructure, we free up your resources, allowing you to focus on strategic initiatives while we ensure your operations run smoothly.   ",
    },
    {
      title: "Q: Can you tailor your managed services to fit our specific business needs? ",
      content:
        "A: Absolutely. Our services are highly customizable, designed to meet the unique needs and challenges of your business.  ",
    },
    {
      title: "Q: How do you ensure the security of our data and systems?",
      content:
        "A: We adhere to the highest standards of security protocols and practices, ensuring your data and systems are protected against all forms of digital threats.   ",
    },
   
    
   
  ];
 

 
  const openModal = (slide) => {
    setSelectedSlide(slide);
    setModalOpen(true);
  };
 
  const closeModal = () => {
    setModalOpen(false);
  };
 
  // Handle clicks outside the modal to close it
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        closeModal();
      }
    };
 
    window.addEventListener("mousedown", handleOutsideClick);
 
    return () => {
      window.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);
 
  const modalContent = (
    <>
      <p className="modal-np"> The Purpose Behind Our Managed Services</p>
     
      {/* <br/> <strong>Design, Development, and Implementation</strong>  */}
      At Digitec Hub, we offer an extensive suite of Managed Services tailored
      to streamline and optimize your business operations. Our holistic approach
      ensures your applications, systems, and platforms are efficiently managed,
      fully integrated, and thoroughly tested, guaranteeing reliability,
      performance, and security. Whether you're looking to enhance your ADM
      processes, implement ERP solutions, cloud disparate systems, or ensure
      your applications are rigorously tested, our expert team is here to
      provide end-to-end support.
      <hr/>
     
    <strong>Supporting Business Goals</strong> 
 
    The primary purpose of Managed Services is to ensure that technology infrastructure supports and accelerates business goals. By outsourcing tasks such as application development, maintenance, ERP implementation, cloud management, and testing services, organizations can focus on core business activities without being bogged down by the complexities of managing technology.
<br/><br/>

<strong> Comprehensive Coverage</strong>
The range of services offered, including Application Development and Maintenance (ADM), Enterprise Resource Planning (ERP), Cloud, and Testing Services, is designed to provide comprehensive coverage of technology needs. This ensures that businesses have access to a wide array of expertise and support to address various aspects of their technology infrastructure.

 <br/><br/><strong>Enabling Focus on Core Competencies</strong> 
 By allowing a managed service provider to handle technology management, businesses can focus on their core competencies and activities that drive growth and innovation. Outsourcing technology management enables organizations to allocate resources more efficiently and concentrate on activities where they can add the most value.







      {/* Your dynamic content here */}
      {/* You can pass any JSX content you want */}
    </>
  );
 
  return (
    <div id="maindivs">
      <div id="carouselExampleCaptions" className="carousel slide">
        <div className="carousel-inner">
          <div className="carousel-item active">
            <img
              src="/Images/managedsevicesbgimage.jpg"
              className="d-block w-100"
              style={{ height: "auto",opacity:"0.35" }}
              alt="slider first"
            />
            <div className="carousel-caption  d-md-block">
              <div className="component-1 text-dark">Managed Services </div>
 
              <div
                className="component-paras"
                style={{ color: "black", marginTop: "-20px" ,fontWeight:"700",fontStyle:'Italic'}}
              >
                "Seamless Operations, Uninterrupted Growth – Comprehensive
                Managed Services for Your Business"
              </div>
            </div>
          </div>
        </div>
      </div>
 
      <div className="container-np2">
        {/* <!-- section2 title --> */}
        <div className="cont2-titles">
          {/* <div class="cont2-titletext">What is Managed Services ? </div> */}
        </div>
 
        {/* <!-- section part --> */}
        <div className="container21">
          {/* <!-- left part --> */}
 
          <div className="cont2-left cont2-text">
            <div
              className="left-text"
              style={{
                // fontFamily: "arial",
 
                fontFamily: "system-ui",
               
              }}
            >
              In today's rapidly evolving digital landscape, staying ahead means
              ensuring your technology infrastructure not only supports but also
              accelerates your business goals. Our Managed Services are designed
              to take the weight off your shoulders, covering everything from
              Application Development and Maintenance (ADM) to Enterprise
              Resource Planning (ERP), Cloud, and Testing Services. Let us
              manage your technology, so you can focus on what you do best –
              growing your business.
            </div>
          </div>
 
          {/* right part */}
          <div className="cont2-right cont2-text">
            <div className="right-text">
              <img
                className="content2img"
                src="/Images/system-file.gif"
                alt="Description of the image"
              />
            </div>
          </div>
        </div>
        <div className="readmore">
          <ReadModal2 buttonText="Read More" modalContent={modalContent} />
        </div>
      </div>
 
      {/* section3 */}
      <section className="section3">
        <div className="cont2-title title ">Our Offerings</div>
 
        <div className="section3parts-manageservice">
          {/* <div class="content-np item31" style={{ display: "flex", flexDirection: "column", justifyContent: "flex-end" }}>
              <div>
                <strong style={{ paddingLeft: '20px' }}>
                Custom Software Development:
               </strong>
                <p class="aligned-paragraph item3text" style={{ marginBottom: "30px" }}>
                Tailored solutions to meet your unique business requirements, ensuring functionality, and user experience are at the forefront.
 
 
                </p>
              </div>
            </div> */}
 
          {/* <div class="content-np item322">
              <strong class="item32-heading item31-conent"style={{
                paddingLeft:'15px',color:'black'
              }} >
              Application Modernization:
   
              </strong>
              <div class=" item31-conent-img  "></div>
              <p class="aligned-paragraph item3text mt-2" style={{color:'black'}}>
              Upgrading legacy systems to modern, scalable, and efficient technologies.
 
              </p>
            </div> */}
 
          {/* <div class="content-np item33">
              <strong class="item32-heading"
                style={{
                paddingLeft:'15px'
              }}>
              Continuous Maintenance & Support:
    </strong>
              <p class="aligned-paragraph item3text">
              Ongoing application maintenance and support to ensure optimal performance and quick issue resolution.
 
              </p>
             
            </div> */}
 
          <div className="content-np item34">
            <strong
              style={{
                paddingLeft: "15px",
              }}
            >
              Managed ADM Services
            </strong>
            <p className="aligned-paragraph item3text" style={{ color: "black" }}>
              Comprehensive support for application development and maintenance,
              ensuring your software meets evolving business and technology
              requirements.
            </p>
            <div className="readmore">
              <button className="read1" onClick={scrollToTop}>
                <Link
                  className="adm-readmore"
                  to="/service/AdmServices"
                  smooth
                >
                  {" "}
                  Read more
                </Link>
              </button>
            </div>
          </div>
          <div className="content-np item35">
            <strong
              style={{
                paddingLeft: "15px",
              }}
            >
              ERP Management
            </strong>
            <p className="aligned-paragraph item3text">
              Expert management of ERP systems, including implementation,
              customization, and ongoing support, to optimize business processes
              and data analytics.
            </p>
            <div className="readmore">
              <button className="read1" onClick={scrollToTop}>
                <Link 
                  className="adm-readmore" 
                  to="/service/ERPservice" 
                  smooth
                >
                  {" "}
                  Read more
                </Link>
              </button>
            </div>
          </div>
 
          <div className="content-np item36">
            <strong
              style={{
                paddingLeft: "15px",
              }}
            >
              Cloud Services
            </strong>
            <p className="aligned-paragraph item3text">
              Expert planning and execution of cloud migration, ensuring minimal
              disruption to your operations. Continuous optimization of cloud
              resources for enhanced performance.
            </p>
 
            <div className="readmore">
              <button className="read1" onClick={scrollToTop}>
                <Link
                  className="adm-readmore"
                  to="/service/CloudServices"
                  smooth
                >
                  {" "}
                  Read more
                </Link>
              </button>
            </div>
          </div>
 
          <div className="content-np item37">
            <strong
              style={{
                paddingLeft: "15px",
              }}
            >
              Testing and QA Services
            </strong>
            <p className="aligned-paragraph item3text" style={{ color: "black" }}>
              Rigorous testing and quality assurance services to ensure your
              applications and systems operate flawlessly, meet user
              expectations, and comply with industry standards.
            </p>
 
            <div className="readmore">
              <button className="read1" onClick={scrollToTop}>
                <Link
                  className="adm-readmore"
                  to="/service/TestingandQA"
                  smooth
                >
                  {" "}
                  Read more
                </Link>
              </button>
            </div>
          </div>
        </div>
        {/* <div class="readmore">
            <button class="read1">Read More</button>
          </div> */}
      </section>
 
      <div className="section4">
        <div className="main-cont">
          <div className="Cardcomp">
            <div className="card-container">
              <div className="row-ca">
                <div className="col-ca">
                  <div className="card-head text-center">
                    <h2 className="head-t ">USP of Managed Services</h2>
                    {/* <p className="t-color">
                    Delivering domain expertise and technology-driven offerings
                    to help you turn digital challenges into opportunities.
                  </p> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card-container">
            <div className="row-ck">
              <div className="col-ck">
                <div className="card-wrapper">
                  <div className="main-card">
                    <div className="innercon">
                      <div className="card-content1">
                        <h2>
                          Expertise Across Domains{" "}
                          <FontAwesomeIcon icon={faArrowRight} />
                        </h2>
                        {/* <p>vbhf</p> */}
                      </div>
                      <img
                        className="img-ico"
                        src="/Images/organigram.gif"
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="hover-card">
                    <div className="hover-content">
                      <h2>Expertise Across Domains</h2>
                      <p>
                        Our team brings in-depth knowledge across ADM, ERP,
                        Integration, and Testing, ensuring a holistic approach
                        to managing your IT landscape.{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-ck">
                <div className="card-wrapper">
                  <div className="main-card">
                    <div className="innercon">
                      <div className="card-content1">
                        <h2>
                          Customized Solutions{" "}
                          <FontAwesomeIcon icon={faArrowRight} />{" "}
                        </h2>
                        {/* <p>vbhf</p> */}
                      </div>
                      <img src="/Images/hand.gif" alt="" />
                    </div>
                  </div>
                  <div className="hover-card">
                    <div className="hover-content">
                      <h2>Customized Solutions</h2>
                      <p>
                        We understand that one size does not fit all, offering
                        tailored services that align with your specific business
                        requirements.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-ck">
                <div className="card-wrapper">
                  <div className="main-card">
                    <div className="innercon">
                      <div className="card-content1">
                        <h2>
                          Proactive Management{" "}
                          <FontAwesomeIcon icon={faArrowRight} />
                        </h2>
                        {/* <p>vbhf</p> */}
                      </div>
                      <img src="/Images/diagram.gif" alt="" />
                    </div>
                  </div>
                  <div className="hover-card">
                    <div className="hover-content">
                      <h2>Proactive Management</h2>
                      <p>
                        {" "}
                        Our proactive approach to maintenance and support helps
                        in identifying and resolving issues before they impact
                        your operations.{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
 
              <div className="col-ck">
                <div className="card-wrapper">
                  <div className="main-card">
                    <div className="innercon">
                      <div className="card-content1">
                        <h2>
                          Focus on Innovation{" "}
                          <FontAwesomeIcon icon={faArrowRight} />
                        </h2>
                        {/* <p>vbhf</p> */}
                      </div>
                      <img src="/Images/implementation.gif" alt="" />
                    </div>
                  </div>
                  <div className="hover-card">
                    <div className="hover-content">
                      <h2>Focus on Innovation</h2>
                      <p>
                        While we manage your current technology needs, we also
                        keep an eye on emerging technologies that can further
                        enhance your business operations.{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-ck">
                <div className="card-wrapper">
                  <div className="main-card">
                    <div className="innercon">
                      <div className="card-content1">
                        <h2>
                          Reliable Support
                          <FontAwesomeIcon icon={faArrowRight} />
                        </h2>
                        {/* <p>vbhf</p> */}
                      </div>
                      <img src="/Images/social-care.gif" alt="" />
                    </div>
                  </div>
                  <div className="hover-card">
                    <div className="hover-content">
                      <h2>Reliable Support</h2>
                      <p>
                        With our round-the-clock support, we ensure your
                        business operations are always running without any
                        hitches.{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-ck">
                <div className="card-wrapper">
                  <div className="main-card">
                    <div className="innercon">
                      <div className="card-content1">
                        <h2>
                          Scalability and Flexibility{" "}
                          <FontAwesomeIcon icon={faArrowRight} />
                        </h2>
                        {/* <p>vbhf</p> */}
                      </div>
                      <img src="/Images/presentation.gif" alt="" />
                    </div>
                  </div>
                  <div className="hover-card">
                    <div className="hover-content">
                      <h2>Scalability and Flexibility</h2>
                      <p>
                        Our solutions are designed to accommodate changes in
                        workload, user requirements, and market demands,
                        ensuring optimal performance and resource utilization at
                        all times.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
 
              {/* <div className='col-ck'>
        <div className="card-wrapper">
            <div className="main-card">
            <div className="innercon">
                  <div className="card-content1">
                    <h2>cvgd <FontAwesomeIcon icon={faArrowRight} /></h2>
                   
                  </div>
                  <img src="/Images/cybercardico.svg" alt="" />
                </div>
            </div>
            <div className="hover-card">
                <div className="hover-content">
                <h2>bhhgjfx</h2>
                    <p>vndxhdmnhit bnxilhk cvbnrildh</p>
                   
                </div>
            </div>
        </div>
        </div> */}
              {/* <div className='col-ck'>
        <div className="card-wrapper">
        <div className="main-card">
        <div className="innercon">
                  <div className="card-content1">
                    <h2>cvgd <FontAwesomeIcon icon={faArrowRight} /></h2>
                   
                  </div>
                  <img src="/Images/cybercardico.svg" alt="" />
                </div>
        </div>
        <div className="hover-card">
            <div className="hover-content">
            <h2>bhhgjfx</h2>
            <p>vndxhdmnhit bnxilhk cvbnrildh</p>
                   
            </div>
        </div>
    </div>
    </div> */}
              {/* <div className='col-ck'>
    <div className="card-wrapper">
        <div className="main-card">
        <div className="innercon">
                  <div className="card-content1">
                    <h2>cvgd <FontAwesomeIcon icon={faArrowRight} /></h2>
                   
                  </div>
                  <img src="/Images/cybercardico.svg" alt="" />
                </div>
        </div>
        <div className="hover-card">
            <div className="hover-content">
              <h2>bhhgjfx</h2>
                   
              <p>vndxhdmnhit bnxilhk cvbnrildh</p>
                   
            </div>
        </div>
    </div>
    </div> */}
            </div>
          </div>
        </div>
 
        {/* <div className='sliders'> */}
        {/* Previous and Next buttons */}
        {/* <button className="nav-button-slider prev" onClick={() => sliderRef.current?.slickPrev()}>
    &lt;
  </button>
  <button className="nav-button-slider next" onClick={() => sliderRef.current?.slickNext()}>
    &gt;
  </button> */}
        {/* Modal */}
        {/* {isModalOpen && selectedSlide && (
    <div className="modal-overlay" ref={modalRef}>
      <div className="modal-content-m" style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
       
        <div id="close-m" onClick={closeModal}>
          <i className="fas fa-times closeicon"></i>
        </div>
        <h2>{selectedSlide. modalContent.modalHeading}</h2>
        <p>{selectedSlide. modalContent.modalText}</p>
      </div>
    </div>
  )} */}
 
        {/* <div className="slidercontainer">
    <Slider ref={sliderRef} {...settings}>
      {slideData.map((slide) => (
        <div key={slide.id} className="slider-item">
          <div className="slidecontents">
            <h2 className='slideheading h2-np slidecontent'>{slide.heading}</h2>
            <p className='slidetext p-np slidecontent'>{slide.text}</p> */}
        {/* <button className='slidebutton read1' onClick={() => openModal(slide)}>
              {slide.buttonLabel}
            </button> */}
        {/* </div>
        </div>
      ))}
    </Slider>
  </div>
</div> */}
 
        {/* <CarouselSlider/> */}
      </div>
 
      <div className="section5">
        <h1 className="section5heading">How We are Handling</h1>
        <div className="section5jsxcard">
          <CaseStudyCard
            imageUrl="/Images/casestudyimg1.jpg"
            title="Initial Consultation and Assessment"
            description="We start with a comprehensive assessment of your current technology infrastructure and business needs.  "
            // linkUrl="/platform/E-cart/casestudy-vitualwallet"
          />
          <CaseStudyCard
            imageUrl="/Images/casestudyimg2.jpg"
            title="Strategic Planning"
            description="Developing a strategic plan tailored to your managed service requirements, ensuring alignment with your business goals. "
            // linkUrl=""
          />
          <CaseStudyCard
            imageUrl="/Images/casestudyimg3.jpg"
            title="Implementation and Transition"
            description=" Smooth implementation and transition to our managed services, with minimal disruption to your operations.  "
            // linkUrl="/platform/E-cart/casestudy-vitualwallet"
          />
          <CaseStudyCard
            imageUrl="/Images/casestudyimg4.jpg"
            title="Ongoing Management and Optimization"
            description="Continuous monitoring, management, and optimization of your IT systems and processes. "
            // linkUrl=""
          />
 
          {/* <div class="readmore">
<button class="read">show More</button>
</div> */}
 
          {/* section 6 */}
        </div>

        


      </div>

      <section className="FAQ">
        <div className="FAQbody">
          <h1 className="section5heading text-dark" >FAQ's for this service</h1>
          <Accordion items={items} />
        </div>
      </section>
    </div>
 
    //   </div>
  );
}
 
export default ManagedServices;
 