import React from 'react';
import { Container, Typography, Box, Grid, Paper, Button } from '@mui/material';
import { motion } from 'framer-motion';
import { AcademicCapIcon, BookOpenIcon, UserGroupIcon, ChartBarIcon } from '@heroicons/react/24/outline';

const EducationTraining = () => {
  const educationPrograms = [
    {
      title: "Continuing Education",
      description: "Ongoing professional development programs",
      Icon: AcademicCapIcon,
      courses: [
        {
          name: "Annual NMLS Renewal",
          duration: "8 hours",
          topics: ["Federal regulations", "Ethics", "Consumer protection", "Non-traditional mortgage products"]
        },
        {
          name: "State-Specific Updates",
          duration: "4 hours",
          topics: ["State regulations", "Compliance requirements", "Lending practices", "Local market trends"]
        }
      ]
    },
    {
      title: "Professional Development",
      description: "Career advancement and skill enhancement",
      Icon: BookOpenIcon,
      courses: [
        {
          name: "Advanced Mortgage Lending",
          duration: "12 hours",
          topics: ["Complex loan scenarios", "Portfolio analysis", "Risk assessment", "Market analysis"]
        },
        {
          name: "Leadership Training",
          duration: "16 hours",
          topics: ["Team management", "Decision making", "Strategic planning", "Communication skills"]
        }
      ]
    },
    {
      title: "Compliance Training",
      description: "Regulatory compliance and risk management",
      Icon: UserGroupIcon,
      courses: [
        {
          name: "Regulatory Compliance",
          duration: "10 hours",
          topics: ["Fair lending laws", "Anti-money laundering", "Data privacy", "Consumer protection"]
        },
        {
          name: "Risk Management",
          duration: "8 hours",
          topics: ["Risk assessment", "Mitigation strategies", "Compliance monitoring", "Audit preparation"]
        }
      ]
    },
    {
      title: "Industry Updates",
      description: "Latest trends and market developments",
      Icon: ChartBarIcon,
      courses: [
        {
          name: "Market Trends",
          duration: "6 hours",
          topics: ["Economic indicators", "Housing market analysis", "Interest rate impacts", "Industry forecasts"]
        },
        {
          name: "Technology Updates",
          duration: "6 hours",
          topics: ["Digital mortgages", "Automation tools", "Security practices", "Innovation trends"]
        }
      ]
    }
  ];

  return (
    <Container maxWidth="lg" sx={{ 
      py: 8,
      mt: { xs: 8, md: 12 }
    }}>
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
      >
        <Typography variant="h2" sx={{ 
          mb: 2, 
          color: '#1A202C',
          fontWeight: 700,
          textAlign: 'center',
          fontSize: { xs: '2.5rem', md: '3.5rem' },
          background: 'linear-gradient(135deg, #8B4513, #D2691E)',
          WebkitBackgroundClip: 'text',
          WebkitTextFillColor: 'transparent'
        }}>
          Education & Training
        </Typography>
        
        <Typography variant="h4" sx={{ 
          mb: 4, 
          color: '#8B4513',
          fontWeight: 600,
          textAlign: 'center',
          fontSize: { xs: '1.2rem', md: '1.5rem' },
          textTransform: 'uppercase',
          letterSpacing: '0.1em'
        }}>
          Professional Development & Growth
        </Typography>
        
        <Typography variant="body1" sx={{ 
          mb: 8, 
          color: '#4A5568',
          textAlign: 'center',
          maxWidth: '800px',
          mx: 'auto',
          fontSize: { xs: '1rem', md: '1.1rem' },
          lineHeight: 1.8
        }}>
          Our comprehensive education and training programs ensure our team maintains the highest standards of professional excellence and regulatory compliance.
        </Typography>

        <Grid container spacing={4}>
          {educationPrograms.map((program, index) => (
            <Grid item xs={12} key={index}>
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
              >
                <Paper elevation={0} sx={{
                  p: 4,
                  borderRadius: '16px',
                  border: '1px solid rgba(139, 69, 19, 0.2)',
                  background: 'linear-gradient(135deg, rgba(139, 69, 19, 0.05), rgba(210, 105, 30, 0.05))',
                  transition: 'all 0.3s ease',
                  '&:hover': {
                    transform: 'translateY(-4px)',
                    boxShadow: '0 4px 20px rgba(139, 69, 19, 0.15)'
                  }
                }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
                    <program.Icon className="w-8 h-8" style={{ color: '#8B4513', marginRight: '12px' }} />
                    <Box>
                      <Typography variant="h5" sx={{ color: '#8B4513', fontWeight: 600 }}>
                        {program.title}
                      </Typography>
                      <Typography variant="body1" sx={{ color: '#4A5568' }}>
                        {program.description}
                      </Typography>
                    </Box>
                  </Box>
                  
                  <Grid container spacing={3}>
                    {program.courses.map((course, idx) => (
                      <Grid item xs={12} md={6} key={idx}>
                        <Box sx={{
                          p: 3,
                          borderRadius: '12px',
                          border: '1px solid rgba(139, 69, 19, 0.1)',
                          background: 'rgba(255, 255, 255, 0.5)'
                        }}>
                          <Typography variant="h6" sx={{ color: '#1A202C', mb: 1 }}>
                            {course.name}
                          </Typography>
                          <Typography variant="subtitle2" sx={{ color: '#8B4513', mb: 2 }}>
                            Duration: {course.duration}
                          </Typography>
                          <Box sx={{ pl: 2 }}>
                            {course.topics.map((topic, topicIdx) => (
                              <Typography 
                                key={topicIdx} 
                                variant="body2" 
                                sx={{ 
                                  color: '#4A5568',
                                  mb: 1,
                                  position: 'relative',
                                  pl: 3,
                                  '&::before': {
                                    content: '"•"',
                                    position: 'absolute',
                                    left: 0,
                                    color: '#8B4513',
                                    fontWeight: 'bold'
                                  }
                                }}
                              >
                                {topic}
                              </Typography>
                            ))}
                          </Box>
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </Paper>
              </motion.div>
            </Grid>
          ))}
        </Grid>

        <Box sx={{ mt: 6, textAlign: 'center' }}>
          <Button
            variant="contained"
            sx={{
              background: 'linear-gradient(135deg, #8B4513, #D2691E)',
              color: '#fff',
              px: 4,
              py: 2,
              fontSize: '1.1rem',
              fontWeight: 600,
              '&:hover': {
                background: 'linear-gradient(135deg, #D2691E, #8B4513)',
              }
            }}
          >
            Request Training Information
          </Button>
        </Box>
      </motion.div>
    </Container>
  );
};

export default EducationTraining; 