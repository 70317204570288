import React from 'react';
import { Container, Typography, Box, Grid, Paper, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import { motion } from 'framer-motion';
import { ChevronDownIcon, ShieldCheckIcon, DocumentTextIcon, ScaleIcon, UserGroupIcon } from '@heroicons/react/24/outline';

const ComplianceGuide = () => {
  const guideData = [
    {
      category: "Federal Compliance",
      icon: ShieldCheckIcon,
      items: [
        {
          title: "Truth in Lending Act (TILA)",
          content: "Comprehensive guidelines for credit terms disclosure, including APR calculations, payment schedules, and total costs of borrowing.",
          requirements: [
            "Clear disclosure of loan terms",
            "Accurate APR calculations",
            "Right of rescission notices",
            "Regular statement requirements"
          ]
        },
        {
          title: "Real Estate Settlement Procedures Act (RESPA)",
          content: "Requirements for real estate settlement processes, including disclosure of settlement costs and prohibition of kickbacks.",
          requirements: [
            "Standardized settlement forms",
            "Good faith estimate provision",
            "Affiliated business disclosures",
            "Escrow account management"
          ]
        }
      ]
    },
    {
      category: "State Regulations",
      icon: ScaleIcon,
      items: [
        {
          title: "State Licensing Requirements",
          content: "State-specific guidelines for mortgage lender licensing, including education requirements and financial responsibilities.",
          requirements: [
            "State-specific education",
            "Financial stability proof",
            "Bond requirements",
            "Regular reporting obligations"
          ]
        },
        {
          title: "Consumer Protection Laws",
          content: "State-level consumer protection regulations affecting mortgage lending practices and disclosures.",
          requirements: [
            "Fair lending practices",
            "Rate and fee disclosures",
            "Marketing restrictions",
            "Complaint handling procedures"
          ]
        }
      ]
    },
    {
      category: "Internal Controls",
      icon: DocumentTextIcon,
      items: [
        {
          title: "Quality Control Standards",
          content: "Internal quality control measures to ensure compliance with all applicable regulations and standards.",
          requirements: [
            "Regular audits schedule",
            "Documentation reviews",
            "Process validations",
            "Error resolution procedures"
          ]
        },
        {
          title: "Risk Management Protocols",
          content: "Comprehensive risk assessment and management procedures for mortgage operations.",
          requirements: [
            "Risk assessment matrix",
            "Mitigation strategies",
            "Monitoring procedures",
            "Reporting requirements"
          ]
        }
      ]
    },
    {
      category: "Staff Requirements",
      icon: UserGroupIcon,
      items: [
        {
          title: "Training Requirements",
          content: "Mandatory training and certification requirements for mortgage professionals.",
          requirements: [
            "Annual compliance training",
            "Role-specific certifications",
            "Continuing education",
            "Performance assessments"
          ]
        },
        {
          title: "Code of Conduct",
          content: "Professional standards and ethical guidelines for mortgage operations staff.",
          requirements: [
            "Ethical guidelines",
            "Professional conduct",
            "Conflict of interest policies",
            "Reporting procedures"
          ]
        }
      ]
    }
  ];

  return (
    <Container maxWidth="lg" sx={{ 
      py: 8,
      mt: { xs: 8, md: 12 }
    }}>
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
      >
        <Typography variant="h2" sx={{ 
          mb: 2, 
          color: '#1A202C',
          fontWeight: 700,
          textAlign: 'center',
          fontSize: { xs: '2.5rem', md: '3.5rem' },
          background: 'linear-gradient(135deg, #8B4513, #D2691E)',
          WebkitBackgroundClip: 'text',
          WebkitTextFillColor: 'transparent'
        }}>
          Compliance Guide
        </Typography>
        
        <Typography variant="h4" sx={{ 
          mb: 4, 
          color: '#8B4513',
          fontWeight: 600,
          textAlign: 'center',
          fontSize: { xs: '1.2rem', md: '1.5rem' },
          textTransform: 'uppercase',
          letterSpacing: '0.1em'
        }}>
          Comprehensive Regulatory Framework
        </Typography>
        
        <Typography variant="body1" sx={{ 
          mb: 8, 
          color: '#4A5568',
          textAlign: 'center',
          maxWidth: '800px',
          mx: 'auto',
          fontSize: { xs: '1rem', md: '1.1rem' },
          lineHeight: 1.8
        }}>
          Our detailed compliance guide outlines all regulatory requirements and internal standards to ensure complete adherence to mortgage industry regulations.
        </Typography>

        <Grid container spacing={4}>
          {guideData.map((category, index) => (
            <Grid item xs={12} key={index}>
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
              >
                <Paper elevation={0} sx={{
                  p: 4,
                  borderRadius: '16px',
                  border: '1px solid rgba(139, 69, 19, 0.2)',
                  background: 'linear-gradient(135deg, rgba(139, 69, 19, 0.05), rgba(210, 105, 30, 0.05))',
                }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
                    <category.icon className="w-8 h-8" style={{ color: '#8B4513', marginRight: '12px' }} />
                    <Typography variant="h5" sx={{ color: '#8B4513', fontWeight: 600 }}>
                      {category.category}
                    </Typography>
                  </Box>

                  {category.items.map((item, idx) => (
                    <Accordion 
                      key={idx}
                      sx={{
                        background: 'rgba(255, 255, 255, 0.5)',
                        mb: 2,
                        '&:before': { display: 'none' },
                        boxShadow: 'none',
                        border: '1px solid rgba(139, 69, 19, 0.1)',
                        borderRadius: '8px !important',
                        '&.Mui-expanded': {
                          margin: '0 0 16px 0',
                        }
                      }}
                    >
                      <AccordionSummary
                        expandIcon={<ChevronDownIcon className="w-5 h-5" style={{ color: '#8B4513' }} />}
                        sx={{
                          '& .MuiAccordionSummary-content': {
                            margin: '12px 0',
                          }
                        }}
                      >
                        <Typography variant="h6" sx={{ color: '#1A202C', fontWeight: 600 }}>
                          {item.title}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography variant="body1" sx={{ color: '#4A5568', mb: 3 }}>
                          {item.content}
                        </Typography>
                        <Box sx={{ pl: 2 }}>
                          {item.requirements.map((req, reqIdx) => (
                            <Typography 
                              key={reqIdx}
                              variant="body2" 
                              sx={{ 
                                color: '#4A5568',
                                mb: 1,
                                position: 'relative',
                                pl: 3,
                                '&::before': {
                                  content: '"•"',
                                  position: 'absolute',
                                  left: 0,
                                  color: '#8B4513',
                                  fontWeight: 'bold'
                                }
                              }}
                            >
                              {req}
                            </Typography>
                          ))}
                        </Box>
                      </AccordionDetails>
                    </Accordion>
                  ))}
                </Paper>
              </motion.div>
            </Grid>
          ))}
        </Grid>
      </motion.div>
    </Container>
  );
};

export default ComplianceGuide; 