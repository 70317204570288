import React, { useState, useRef, useEffect } from "react";
import "./Accordion.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";

function Accordion({ items }) {
  const [activeIndex, setActiveIndex] = useState(null);
  const contentRefs = useRef([]);

  // Debug: Log items when they change
  useEffect(() => {
    console.log('Items changed:', items);
  }, [items]);

  // Debug: Log active index when it changes
  useEffect(() => {
    console.log('Active index changed to:', activeIndex);
  }, [activeIndex]);

  useEffect(() => {
    // Initialize refs
    contentRefs.current = Array(items?.length)
      .fill()
      .map((_, i) => contentRefs.current[i] || React.createRef());
    
    // Debug: Log refs
    console.log('Content refs initialized:', contentRefs.current);
  }, [items]);

  const handleClick = (index) => {
    setActiveIndex(prevIndex => prevIndex === index ? null : index);
  };

  // Debug mounting
  useEffect(() => {
    console.log('Accordion mounted with items:', items);
    return () => console.log('Accordion unmounting');
  }, [items]);

  if (!items || items.length === 0) {
    console.log('No items to render');
    return null;
  }

  return (
    <div className="accordion">
      {items.map((item, index) => {
        const isActive = index === activeIndex;
        
        return (
          <div 
            key={index} 
            className={`accordion-item ${isActive ? 'active' : ''}`}
          >
            <div
              className="accordion-title"
              onClick={() => handleClick(index)}
              role="button"
              tabIndex={0}
              onKeyPress={(e) => {
                if (e.key === 'Enter' || e.key === ' ') {
                  handleClick(index);
                }
              }}
            >
              <span className="accordion-title-text">
                {item.title}
              </span>
              <FontAwesomeIcon 
                icon={faChevronDown} 
                className={`accordion-icon ${isActive ? 'rotate' : ''}`}
              />
            </div>
            <div 
              className={`accordion-content ${isActive ? 'active' : ''}`}
              style={{ 
                maxHeight: isActive ? '1000px' : '0',
                opacity: isActive ? 1 : 0,
                visibility: isActive ? 'visible' : 'hidden'
              }}
            >
              <div className="accordion-content-inner">
                {item.content}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default Accordion;