import React, { useState } from "react";
import "./DropdownMenu.css"; // Import the CSS file for styling
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

const DropdownMenu3 = () => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleLinkClick = () => {
    setIsHovered(false);
  };

  return (
    <div
      className="dropdown"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <span className="ok">Solutions</span>
      {isHovered && (
        <div className="dropdown-content-3">
          <h3 className="allplatforms">
            All Solutions <FontAwesomeIcon icon={faArrowRight} />
          </h3>

<hr className='linehr' />
           <div className='dard'>
            <Link className='link-aj' to="/solution/DroneSolution" onClick={handleLinkClick}>Drone Solutions</Link>

            <Link className='link-aj' to="/solution/SuplyCL" onClick={handleLinkClick}>Supplychain & Logistics</Link>

            <Link className='link-aj' to="/solution/FinancialS" onClick={handleLinkClick}>Financial Services</Link>

            <Link className='link-aj' to="/solution/HealthcareAndPharma" onClick={handleLinkClick}>Healthcare and Pharma</Link>

            <Link className='link-aj' to="/solution/E-commerce" onClick={handleLinkClick}>E commerce</Link>

            <Link className='link-aj' to="/solution/Automative" onClick={handleLinkClick}>Automotive</Link>
         
          </div>
        </div>
      )}
    </div>
  );
};

export default DropdownMenu3;
