import React from 'react';
import { motion } from 'framer-motion';
import { Box, Typography, IconButton, Grid } from '@mui/material';

const InfoModal = ({ isOpen, onClose, title, content = [] }) => {
  if (!isOpen) return null;

  // Check if content is empty or doesn't have items
  const hasContent = Array.isArray(content) && content.length > 0 && 
    content.some(section => Array.isArray(section.items) && section.items.length > 0);

  return (
    <motion.div 
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        background: 'rgba(0, 0, 0, 0.6)',
        backdropFilter: 'blur(8px)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 1000,
        padding: '20px'
      }}
    >
      <motion.div 
        initial={{ scale: 0.95, y: 20 }}
        animate={{ scale: 1, y: 0 }}
        exit={{ scale: 0.95, y: 20 }}
        transition={{ type: "spring", stiffness: 300, damping: 30 }}
        style={{
          background: '#fff',
          borderRadius: '24px',
          width: '100%',
          maxWidth: '600px',
          maxHeight: '90vh',
          overflow: 'auto',
          position: 'relative',
          boxShadow: '0 20px 50px rgba(0, 0, 0, 0.2)'
        }}
      >
        <Box sx={{ 
          p: 4, 
          borderBottom: '1px solid rgba(139, 69, 19, 0.2)',
          background: 'linear-gradient(135deg, rgba(139, 69, 19, 0.05), rgba(210, 105, 30, 0.05))',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}>
          <Typography variant="h5" sx={{ 
            fontWeight: 700,
            color: '#8B4513',
            textTransform: 'uppercase',
            letterSpacing: '0.05em',
            fontSize: { xs: '1.2rem', md: '1.5rem' },
            background: 'linear-gradient(135deg, #8B4513, #D2691E)',
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent'
          }}>
            {title || 'Information'}
          </Typography>
          <IconButton 
            onClick={onClose}
            sx={{
              width: 40,
              height: 40,
              borderRadius: '50%',
              background: 'rgba(139, 69, 19, 0.1)',
              color: '#8B4513',
              '&:hover': {
                background: 'rgba(139, 69, 19, 0.2)',
                transform: 'rotate(90deg)'
              },
              transition: 'all 0.3s ease'
            }}
          >
            ×
          </IconButton>
        </Box>
        
        <Box sx={{ p: 4 }}>
          {!hasContent ? (
            <Box sx={{ 
              textAlign: 'center', 
              py: 6,
              background: 'linear-gradient(135deg, rgba(139, 69, 19, 0.02), rgba(210, 105, 30, 0.02))',
              borderRadius: '16px',
              border: '1px dashed rgba(139, 69, 19, 0.2)'
            }}>
              <Typography variant="h6" sx={{ 
                color: '#8B4513',
                mb: 1,
                fontWeight: 600
              }}>
                No Information Available
              </Typography>
              <Typography variant="body2" sx={{ color: '#4A5568' }}>
                Details for this section will be available soon.
              </Typography>
            </Box>
          ) : (
            <Grid container spacing={3}>
              {content.map((section, index) => (
                <Grid item xs={12} key={index}>
                  <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.3, delay: index * 0.1 }}
                  >
                    <Box
                      sx={{
                        p: 3,
                        borderRadius: '16px',
                        border: '1px solid rgba(139, 69, 19, 0.2)',
                        background: 'linear-gradient(135deg, rgba(139, 69, 19, 0.05), rgba(210, 105, 30, 0.05))',
                        mb: 2,
                        transition: 'all 0.3s ease',
                        '&:hover': {
                          transform: 'translateY(-2px)',
                          boxShadow: '0 4px 20px rgba(139, 69, 19, 0.1)'
                        }
                      }}
                    >
                      <Typography variant="h6" sx={{ 
                        color: '#8B4513',
                        mb: 2,
                        fontWeight: 600,
                        display: 'flex',
                        alignItems: 'center',
                        '&::before': {
                          content: '""',
                          width: 4,
                          height: 20,
                          background: 'linear-gradient(135deg, #8B4513, #D2691E)',
                          marginRight: 2,
                          borderRadius: 1
                        }
                      }}>
                        {section.subtitle}
                      </Typography>
                      {Array.isArray(section.items) && section.items.map((item, idx) => (
                        <Box 
                          key={idx} 
                          sx={{ 
                            mb: 2,
                            pl: 3,
                            borderLeft: '2px solid rgba(139, 69, 19, 0.1)'
                          }}
                        >
                          <Typography 
                            variant="subtitle1" 
                            sx={{ 
                              color: '#1A202C',
                              fontWeight: 600,
                              mb: 1,
                              fontSize: '1.1rem'
                            }}
                          >
                            {item.title}
                          </Typography>
                          <Typography 
                            variant="body2" 
                            sx={{ 
                              color: '#4A5568',
                              lineHeight: 1.6
                            }}
                          >
                            {item.description}
                          </Typography>
                        </Box>
                      ))}
                    </Box>
                  </motion.div>
                </Grid>
              ))}
            </Grid>
          )}
        </Box>
      </motion.div>
    </motion.div>
  );
};

export default InfoModal; 