import "./template.css";

import { Link } from "react-router-dom";
import ReadModal2 from "./readMore.js";
import React, { useState, useRef, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CarouselSlider from "./Carousel.js";
import CaseStudyCard from "./CaseStudyCard.js";


import Accordion from "./Accordion";
import "./Accordion.css";


import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
};

function AI() {
  const [activeModal, setActiveModal] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const sliderRef = useRef(null);
  const modalRef = useRef(null);
  const [selectedSlide, setSelectedSlide] = useState(null);
  const settings = {
    // dots: true,
    // infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },

      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },

      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 3000,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const slideData = [
    {
      id: 1,
      heading: "Cutting-Edge AI Expertise",
      text: " Our team brings unparalleled expertise in the latest AI technologies, ensuring you stay ahead of the curve.  ",
      buttonLabel: "Read More",
      modalContent: {
        // modalHeading: 'Modal Heading 1',
        modalText:
          "CloudCipher seamlessly integrates with existing workflows and software ecosystems, minimizing disruption and streamlining the adoption process. Whether migrating from another platform or implementing new security measures, CloudCipher ensures a smooth transition with minimal downtime.",
      },
    },
    {
      id: 2,
      heading: "Tailored AI Solutions",
      text: "  We provide solutions precisely customized to your business needs, ensuring maximum effectiveness and efficiency. ",
      buttonLabel: "Read More",
      modalContent: {
        modalHeading: "Modal Heading 2",
        modalText: "Modal Text 2",
      },
    },

    {
      id: 3,
      heading: "Seamless Integration",
      text: "  Our managed services are designed for smooth integration with your existing workflows, minimizing disruption and maximizing utility.  ",
      buttonLabel: "Read More",
      modalContent: {
        modalHeading: "Modal Heading 3",
        modalText: "Modal Text 3",
      },
    },

    {
      id: 4,
      heading: "Focus on Innovation and Growth",
      text: "  By handling the complexities of AI integration and management, we enable you to focus on core business innovation and growth.  ",
      buttonLabel: "Read More",
      modalContent: {
        modalHeading: "Modal Heading 4",
        modalText: "Modal Text 4",
      },
    },

    {
      id: 5,
      heading: "Comprehensive Support and Optimization",
      text: " Our ongoing management, support, and optimization services ensure your AI solutions continue to evolve with your business needs.  ",
      buttonLabel: "Read More",
      modalContent: {
        modalHeading: "Modal Heading 5",
        modalText: "Modal Text 5",
      },
    },

    // {
    //   id: 6,
    //   heading: 'Sixth Slide',
    //   text: 'A different text for the second slide.',
    //   buttonLabel: 'Read More',
    //   modalContent: {
    //     modalHeading: 'Modal Heading 6',
    //     modalText: 'Modal Text 6',
    //   },
    // },

    // {
    //   id: 7,
    //   heading: 'Seventh Slide',
    //   text: 'A different text for the second slide.',
    //   buttonLabel: 'Read More',
    //   modalContent: {
    //     modalHeading: 'Modal Heading 7',
    //     modalText: 'Modal Text 7',
    //   },
    // },

    // {
    //   id: 8,
    //   heading: 'Eighth Slide',
    //   text: 'A different text for the second slide.',
    //   buttonLabel: 'Read More',
    //   modalContent: {
    //     modalHeading: 'Modal Heading 8',
    //     modalText: 'Modal Text 8',
    //   },
    // },
    // ... (more slides)
  ];


  let items = [
    {
      title: "Q: How can your AI-based managed services transform our business operations?  ",
      content:
        "A: By automating routine tasks, enhancing data processing, and providing deep insights, our services not only streamline operations but also open up new avenues for innovation and strategic decision-making.  ",
    },
    {
      title: "Q: Are your AI solutions adaptable to our industry-specific needs?",
      content:
        "A: Absolutely. Our AI solutions are highly customizable, designed to address the unique challenges and opportunities within your specific industry.   ",
    },
    {
      title: "Q: What measures do you take to ensure data security in AI processes?  ",
      content:
        "A: We implement stringent data security protocols, including encryption and secure data handling practices, to protect your information throughout the AI processing lifecycle.   ",
    },
   
    
   
  ];
 

  const openModal = (slide) => {
    setSelectedSlide(slide);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  // Handle clicks outside the modal to close it
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        closeModal();
      }
    };

    window.addEventListener("mousedown", handleOutsideClick);

    return () => {
      window.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const modalContent = (
    <>
      <p className="modal-np">The Purpose Behind Our  Artificial Intelligence </p>
      
    
      Digitec Hub's AI-Based Managed Services are designed to provide
      comprehensive support across various AI domains, including Generative AI,
      Optical Character Recognition (OCR), Object Recognition, and
      Annotation-Based Training. By integrating these cutting-edge technologies,
      we offer solutions that automate processes, improve accuracy, and generate
      insights, enabling businesses to achieve operational excellence and foster
      innovation. Our expert team ensures seamless implementation and
      management, allowing you to harness the full potential of AI technologies.
      <hr/>

      <strong> Prediction and Forecasting</strong>
      AI enables predictive analytics and forecasting by analyzing historical data patterns and identifying trends, allowing businesses and organizations to anticipate future events and make proactive decisions.
      <br />
      <br />

      <strong>Augmentation</strong>  AI enhances human capabilities by providing intelligent tools and systems that can analyze vast amounts of data, extract insights, and support decision-making processes across various domains.
      <br />
      <br />

      <strong>Innovation</strong>
      AI fosters innovation by enabling the development of new technologies, products, and services that were previously unattainable or too complex to implement. AI-driven innovations have the potential to transform industries and create new opportunities for growth and development.
    <br/>
    <br/>
      <strong>Efficiency and Optimization</strong>
      AI optimizes processes and systems by continuously learning from data and feedback, leading to improved performance, resource allocation, and cost-effectiveness in various domains.
      {/* Your dynamic content here */}
      {/* You can pass any JSX content you want */}
    </>
  );

  return (
    <div>
      <div id="maindivs">
        <div id="carouselExampleCaptions" className="carousel slide">
          <div className="carousel-inner">
            <div className="carousel-item active">
              <img
                src="/Images/aimainbg2.png"
                className="d-block w-100"
                style={{ height: "700px",opacity:"0.45" }}
                alt="slider first"
              />
              <div className="carousel-caption d-md-block">
                <div className="component-1 text-dark"> Artificial Intelligence </div>
                <div
                  className="component-paras"
                  style={{
                    color: "black",
                    marginTop: "-20px",
                    fontStyle: "italic",
                    fontWeight:"700"
                  }}
                >
                  "Empowering Innovation, Enhancing Intelligence – Elevate Your
                  Business with AI-Based Managed Services"
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container-np2">
          {/* <!-- section2 title --> */}
          <div className="cont2-titles">
            {/* <div class="cont2-titletext">
              What is Artificial Intelligence Services{" "}
            </div> */}
          </div>

          {/* <!-- section part --> */}
          <div className="container21">
            {/* <!-- left part --> */}

            <div className="cont2-left cont2-text">
              <div
                className="left-text"
                style={{
                  // fontFamily: "arial",
                  fontSize: "22px",
                  fontFamily: "system-ui",
                }}
              >
                In the era of digital transformation, leveraging Artificial
                Intelligence is no longer just an option but a necessity for
                staying competitive. Our AI-Based Managed Services harness the
                power of Generative AI, OCR, Object Recognition, and advanced
                Annotation Techniques to revolutionize your operations, enhance
                decision-making, and unlock new opportunities for innovation and
                growth.
              </div>
            </div>

            {/* right part */}
            <div className="cont2-right cont2-text">
              <div className="right-text">
                <img
                  className="content2img"
                  src="/Images/artificial-intelligence.png"
                  alt="Description of the image"
                />
              </div>
            </div>
          </div>
          <div className="readmore">
            <ReadModal2 buttonText="Read More" modalContent={modalContent} />
          </div>
        </div>

        {/* section3 */}
        <section className="section3">
          <div className="cont2-title title ">We are Offerings</div>

          <div className="section3parts-manageservice">
            {/* <div class="content-np item31">
              <div>
                <strong
                  style={{ paddingLeft: '20px' }}
                >
                  {" "}
                  SmartVision AI Integration
                </strong>
                <p className="aligned-paragraph item3text">
                  Implementing Generative AI in our SmartVision platform for
                  advanced surveillance and data analysis capabilities.
                </p>
              </div>
            </div> */}

            {/* <div class="content-np item322">
              <strong
                class="item32-heading item31-conent"
                style={{
                  paddingLeft:'15px',color:'black'
                }}
              >
                Content Generation
              </strong>
              <div class=" item31-conent-img  "></div>
              <p className="aligned-paragraph item3text" style={{color:'black'}}> 
                Utilizing AI to create unique, high-quality content for various
                digital platforms.
              </p>
            </div> */}

            {/* <div class="content-np item33">
              <strong
                class="item32-headings"
                style={{
                  paddingLeft:'15px'
                }}
              >
                {" "}
                AI-Enabled Chatbots for E-Commerce
              </strong>
              <p className="aligned-paragraph item3text">
                Developing sophisticated chatbots that can handle customer
                queries, improve service efficiency, and personalize user
                experiences in e-commerce.
              </p>
              
            </div> */}

            <div className="content-np item34">
              <strong
                style={{
                  paddingLeft: "15px",
                }}
              >
                Generative AI Services
              </strong>
              <p className="aligned-paragraph item3text" style={{ color: "black" }}>
                Create new content, designs, or data models with our Generative
                AI solutions, driving innovation across your product lines and
                services.
              </p>
              <div className="readmore">
              <button className="read1" onClick={scrollToTop}>
                  <Link className="adm-readmore" to="/service/GenAI" smooth>
                    {" "}
                    Read more
                  </Link>
                </button>
              </div>
            </div>

            <div className="content-np item35">
              <strong
                style={{
                  paddingLeft: "15px",
                }}
              >
                OCR Solutions
              </strong>
              <p className="aligned-paragraph item3text">
                Transform documents into actionable digital data with high
                accuracy, enhancing data accessibility and process efficiency
              </p>

              <div className="readmore">
              <button className="read1" onClick={scrollToTop}>
                  <Link className="adm-readmore" to="/service/OCRservice" smooth>
                    {" "}
                    Read more
                  </Link>
                </button>
              </div>
            </div>

            <div className="content-np item36">
              <strong
                style={{
                  paddingLeft: "15px",
                }}
              >
                Object Recognition Services
              </strong>
              <p className="aligned-paragraph item3text">
                Automate the identification and categorization of objects within
                images or videos, optimizing operations in surveillance, quality
                control, and inventory management.
              </p>
            </div>

            <div className="content-np item37">
              <strong
                style={{
                  paddingLeft: "15px",
                }}
              >
              Machine Learning Managed Services 
              </strong>
              <p className="aligned-paragraph item3text" style={{ color: "black" }}>
              Unlocking Potential, Driving Innovation – Comprehensive Machine Learning Managed 

Services.
              </p>
              <div className="readmore">
              <button className="read1" onClick={scrollToTop}>
                  <Link className="adm-readmore" to="/Service/AI_ML" smooth>
                    {" "}
                    Read more
                  </Link>
                </button>
              </div>
            </div>
          </div>
          {/* <div className="readmore">
            <button className="read1">Read More</button>
          </div> */}
        </section>

        <div className="section4">
        
          <div className="main-cont">
            <div className="Cardcomp">
              <div className="card-container">
                <div className="row-ca">
                  <div className="col-ca">
                    <div className="card-head text-center">
                      <h2 className="head-t ">USP of AI Services</h2>
                      {/* <p className="t-color">
                    Delivering domain expertise and technology-driven offerings
                    to help you turn digital challenges into opportunities.
                  </p> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-container">
              <div className="row-ck">
                <div className="col-ck">
                  <div className="card-wrapper">
                    <div className="main-card">
                      <div className="innercon">
                        <div className="card-content1">
                          <h2>
                            Cutting-Edge AI Expertise{" "}
                            <FontAwesomeIcon icon={faArrowRight} />
                          </h2>
                          {/* <p>vbhf</p> */}
                        </div>
                        <img src="/Images/cutting-edge.gif" alt="" />
                      </div>
                    </div>
                    <div className="hover-card">
                      <div className="hover-content">
                        <h2>Cutting-Edge AI Expertise</h2>
                        <p>
                          Our team brings unparalleled expertise in the latest
                          AI technologies, ensuring you stay ahead of the curve.{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-ck">
                  <div className="card-wrapper">
                    <div className="main-card">
                      <div className="innercon">
                        <div className="card-content1">
                          <h2>
                            Tailored AI Solutions{" "}
                            <FontAwesomeIcon icon={faArrowRight} />{" "}
                          </h2>
                          {/* <p>vbhf</p> */}
                        </div>
                        <img src="/Images/hand.gif" alt="" />
                      </div>
                    </div>
                    <div className="hover-card">
                      <div className="hover-content">
                        <h2>Tailored AI Solutions</h2>
                        <p>
                          We provide solutions precisely customized to your
                          business needs, ensuring maximum effectiveness and
                          efficiency.{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-ck">
                  <div className="card-wrapper">
                    <div className="main-card">
                      <div className="innercon">
                        <div className="card-content1">
                          <h2>
                            Seamless Integration{" "}
                            <FontAwesomeIcon icon={faArrowRight} />
                          </h2>
                          {/* <p>vbhf</p> */}
                        </div>
                        <img src="/Images/copywriting.gif" alt="" />
                      </div>
                    </div>
                    <div className="hover-card">
                      <div className="hover-content">
                        <h2>Seamless Integration</h2>
                        <p>
                          Our managed services are designed for smooth
                          integration with your existing workflows, minimizing
                          disruption and maximizing utility.{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-ck">
                  <div className="card-wrapper">
                    <div className="main-card">
                      <div className="innercon">
                        <div className="card-content1">
                          <h2>
                            Focus on Innovation and Growth{" "}
                            <FontAwesomeIcon icon={faArrowRight} />
                          </h2>
                          {/* <p>vbhf</p> */}
                        </div>
                        <img src="/Images/implementation.gif" alt="" />
                      </div>
                    </div>
                    <div className="hover-card">
                      <div className="hover-content">
                        <h2>Focus on Innovation and Growth</h2>
                        <p>
                          By handling the complexities of AI integration and
                          management, we enable you to focus on core business
                          innovation and growth. .
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-ck">
                  <div className="card-wrapper">
                    <div className="main-card">
                      <div className="innercon">
                        <div className="card-content1">
                          <h2>
                            Comprehensive Support and Optimization
                            <FontAwesomeIcon icon={faArrowRight} />
                          </h2>
                          {/* <p>vbhf</p> */}
                        </div>
                        <img src="/Images/social-care.gif" alt="" />
                      </div>
                    </div>
                    <div className="hover-card">
                      <div className="hover-content">
                        <h2>Comprehensive Support and Optimization</h2>
                        <p>
                          Our ongoing management, support, and optimization
                          services ensure your AI solutions continue to evolve
                          with your business needs.{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-ck">
                  <div className="card-wrapper">
                    <div className="main-card">
                      <div className="innercon">
                        <div className="card-content1">
                          <h2>
                            Robust Security{" "}
                            <FontAwesomeIcon icon={faArrowRight} />
                          </h2>
                          {/* <p>vbhf</p> */}
                        </div>
                        <img src="/Images/shield.gif" alt="" />
                      </div>
                    </div>
                    <div className="hover-card">
                      <div className="hover-content">
                        <h2>Robust Security </h2>
                        <p>
                          We use advanced encryption, access controls, and
                          threat detection to protect your data and AI
                          infrastructure from internal and external risks.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <div className='col-ck'>
        <div className="card-wrapper">
            <div className="main-card">
            <div className="innercon">
                  <div className="card-content1">
                    <h2>cvgd <FontAwesomeIcon icon={faArrowRight} /></h2>
                    
                  </div>
                  <img src="/Images/cybercardico.svg" alt="" />
                </div>
            </div>
            <div className="hover-card">
                <div className="hover-content">
                <h2>bhhgjfx</h2>
                    <p>vndxhdmnhit bnxilhk cvbnrildh</p>
                    
                </div>
            </div>
        </div>
        </div>
        <div className='col-ck'>
        <div className="card-wrapper">
        <div className="main-card">
        <div className="innercon">
                  <div className="card-content1">
                    <h2>cvgd <FontAwesomeIcon icon={faArrowRight} /></h2>
                    
                  </div>
                  <img src="/Images/cybercardico.svg" alt="" />
                </div>
        </div>
        <div className="hover-card">
            <div className="hover-content">
            <h2>bhhgjfx</h2>
            <p>vndxhdmnhit bnxilhk cvbnrildh</p>
                    
            </div>
        </div>
    </div>
    </div> */}
                {/* <div className='col-ck'>
    <div className="card-wrapper">
        <div className="main-card">
        <div className="innercon">
                  <div className="card-content1">
                    <h2>cvgd <FontAwesomeIcon icon={faArrowRight} /></h2>
                    
                  </div>
                  <img src="/Images/cybercardico.svg" alt="" />
                </div>
        </div>
        <div className="hover-card">
            <div className="hover-content">
              <h2>bhhgjfx</h2>
                   
              <p>vndxhdmnhit bnxilhk cvbnrildh</p>
                    
            </div>
        </div>
    </div>
    </div> */}
              </div>
            </div>
          </div>

          {/* <CarouselSlider/> */}
        </div>

        <div className="section5">
          <h1 className="section5heading">How We are Handling</h1>
          <div className="section5jsxcard">
            <CaseStudyCard
              imageUrl="/Images/casestudyimg1.jpg"
              title="In-depth Consultation"
              description="Starting with a detailed understanding of your business objectives and technological landscape. "
              // linkUrl="/platform/E-cart/casestudy-vitualwallet"
            />
            <CaseStudyCard
              imageUrl="/Images/casestudyimg2.jpg"
              title="Strategic AI Implementation"
              description=" Developing and deploying strategic AI solutions that align with your business goals and operational requirements.  "
              linkUrl=""
            />
            <CaseStudyCard
              imageUrl="/Images/casestudyimg3.jpg"
              title="Continuous Training and Refinement"
              description="  Providing ongoing training for AI models to ensure they adapt and improve over time.  "
              linkUrl=""
            />

            <CaseStudyCard
              imageUrl="/Images/casestudyimg4.jpg"
              title="Dedicated Support"
              description="Offering dedicated support to address any challenges and ensure the smooth operation of AI technologies in your business. "
              linkUrl=""
            />

            {/* <div className="readmore">
  <button className="read">show More</button>
  </div> */}

            {/* section 6 */}
          </div>
        </div>

        <section className="FAQ">
        <div className="FAQbody">
          <h1 className="section5heading text-dark" >FAQ's for this service</h1>
          <Accordion items={items} />
        </div>
      </section>


      </div>
    </div>
  );
}

export default AI;
