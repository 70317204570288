import React, { useState } from 'react';
import './About.css'; // Import your CSS file for styling
import { Button } from 'react-bootstrap';
import { Modal } from 'react-bootstrap';

function About() {
  const [showModal, setShowModal] = useState(false);
  const [selectedTeamMember, setSelectedTeamMember] = useState(null);

  const handleCardClick = (teamMember) => {
    setSelectedTeamMember(teamMember);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedTeamMember(null);
  };
  const [ID, setId] = useState("");

  const getHoverID = (title) => {
    setId(title);
  };

  const TEAM = [
   
    {imgURL:"https://demo.expertit.in/uploads/kiran_More_ac44fa81e8.png",
    title:"Kiran More",
    position:"CEO",
   description :"My strategic vision involves value addition, gap analysis, planning, team building, and seamless system integration, fostering a culture of continuous learning and innovation within organizations."
   },
    {
      imgURL:"https://demo.expertit.in/uploads/Dipeeka_aff94a7da3.png",
    title:"Dipika Saxena",
    position:"Director-HR",
    description :" Deepika, a veteran HR leader, excels in strategic talent management and aims to enhance HR practices at Digitechub Inc for organizational success.                                               "
    },
      {imgURL:"https://demo.expertit.in/uploads/altaf_ad342064b4.png",
     title:"Altaf Pathan",
    position:"Director-Business Development",
      description :" Results-driven business leader with a successful and demonstrable track record of developing and implementing commercial,organization and business development strategies"
    },
     
      {imgURL:"https://demo.expertit.in/uploads/Akashdeep_b296a295e2.png",
     title:"Dr. Akashdeep Bhardwaj",
    position:"CISO",
    description :"Dr. Akashdeep Bhardwaj, a 24-year IT industry veteran and academic, is our Cybersecurity Mentor and vCISO. With a Ph.D. in Cloud Security, MBA, and Engineering Degree, he has published over 135 research papers and books. He has worked as Technology Leader for multinational organizations and is considered the best fast bowling all-rounder."
    },
      {imgURL:"https://demo.expertit.in/uploads/kirti_595744bbda.png",
     title:"Ms. Keerti",
    position:"Director-Marketing",
    description :" Ms. Keerti Indian, also known as Ms. Keerti Gupta Saxena, is a skilled writer and author with a Master's degree in Technology,known for her engaging content, social media, and innovative website designs."
    }    
  ]

  return (
    <>
     {/* <div id="carouselExampleCaptions" className="carousel slide">

<div className="carousel-inner">

  <div id='abtbannerimg' className="carousel-item active" style={{backgroundImage: "url('/Images/about-gif')"}}>
  
   
  </div>
  
  
</div>



</div> */}

<div id="carouselExampleCaptions" className="carousel slidey">
        <div className="carousel-inner">
          <div className="carousel-item active">
            <img
              src="/Images/about-us.webp"
              className="d-block w-100"
              style={{ height: "100vh !important"  }}
              alt="slider first"
            />
            <div className="carousel-caption  d-md-block">
              <div className="component-1 "style={{color:"white"}}>About US </div>

              <div
                className="component-paras"
                style={{ color: "white", marginTop: "-20px", fontStyle: "italic" ,fontWeight:"700",}}
              >
                "We're all about making new ideas happen. We make cool digital stuff & put it to work for you. Find out how we do things differently to help you in the digital world."
              </div>
            </div>
          </div>
        </div>
      </div>
   

      <div className="about-content bg-white py-0  mt-5">
        <div className="container">
        <div className="cont2-title title ">Welcome to DigiTecHub Inc,</div>
        <div className="row align-items-center mb-0">
            
            <div className="col-12">
            <h2 className="aboutheadings" ></h2>
            <p className='left-text'style={{marginLeft:'0',fontFamily:'system-ui',fontSize:'24px'}}>At DigiTecHub Inc,We help businesses change using technology. We make businesses better with our products & services.<br/> Let's make your business succeed in the digital world.We want to be the leaders in changing how businesses use
                technology. We imagine a future where all companies use tech
                really well, making things easier & more successful in the
                digital world.</p>
            </div>
          </div>
        <div className="row align-items-center mb-0">
            
            <div className="col-12">
            <h2 className="aboutheadings" ></h2>
              <p className="left-text" style={{marginLeft:'0',fontFamily:'system-ui',fontSize:'24px'}}>
             we want to help your business succeed. We
                work with companies all over the world to find new and
                personalized solutions to the problems of today's digital world.
                We use our knowledge and the latest tech to help our clients
                grow a lot.              </p>
            </div>
          </div>
          <div className="row align-items-center mb-0">
            
            <div className="col-12">
            <h2 className="aboutheadings" ></h2>
              <p className="left-text" style={{marginLeft:'0',fontFamily:'system-ui',fontSize:'24px'}}>
              We think we're doing well when our clients do well. Everything we do is to help your business in a real, long-lasting way. Your goals are our goals, & we'll work together to turn problems into chances to do better. Let's team up to make your business grow & succeed.              </p>
            </div>
          </div>
          <div className="row align-items-center mb-0">
            
            <div className="col-12">
            <h2 className="aboutheadings" ></h2>
              <p className="left-text" style={{marginLeft:'0',fontFamily:'system-ui',fontSize:'24px'}}>
               We're all about making new ideas happen. We make cool digital stuff & put it to work for you. Find out how we do things differently to help you in the digital world.
              </p>
            </div>
          </div>
         
          <div className="row align-items-center mb-0">
            
            <div className="col-12">
            <h2 className="aboutheadings" ></h2>
              <p className="left-text" style={{marginLeft:'0',fontFamily:'system-ui',fontSize:'24px'}}>
              Innovation at the Core We think new ideas are really important at DigiTechub Inc. We use creative thinking & the latest tech in everything we do. We're always looking at what's new, trying out cool tech, & working with smart people to make sure our clients are ahead in the changing digital world.              </p>
            </div>
          </div>
         
         
         
          
         
        </div>
      </div>

      <div className="abt-head-sk" style={{ fontFamily: 'Oswald ', fontSize: '60px' }}>
        Our Team
      </div>

      <div className="main">
        <ul className="cards">
          {TEAM.map((team) => (
            <li key={team.title} className="cards_item" onClick={() => handleCardClick(team)}>
              <CardItem imageUrl={team.imgURL} title={team.title} position={team.position} />
            </li>
          ))}
        </ul>
      </div>
       {/* Modal for displaying selected team member details */}
       {selectedTeamMember && ( 
        <Modal show={showModal} onHide={handleCloseModal } className="centered-modal">
          <Modal.Header closeButton>
            <Modal.Title>{selectedTeamMember.title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <img src={selectedTeamMember.imgURL} alt={selectedTeamMember.title} className="img-fluid mb-3" />
            <p>{selectedTeamMember.description}</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseModal}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
}

function CardItem({ imageUrl, title, description, position }) {
  return (
    <li className="cards_item">
      <div className="card">
        <div className="card_image">
          <img src={imageUrl}  alt="Card" />
        </div>
        <div className="card_content">
          <h2 className="card_title"><b>{title}</b></h2>
          <h2 className="card_title">{position}</h2>
        </div>
      </div>
    </li>
  );
}

export default About;
