import React from 'react';
import './SocialShare.css';
import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { FaFacebookF } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa";

const SocialShare = () => {
    const shareUrl = window.location.href;

    const openShareWindow = (url) => {
        const width = 600;
        const height = 400;
        const left = window.innerWidth / 2 - width / 2;
        const top = window.innerHeight / 2 - height / 2;
        const options = `width=${width},height=${height},top=${top},left=${left}`;

        window.open(url, '_blank', options);
    };

    return (
        <div className="share-container">
            <p className="share-text">Share</p>
            <div style={{ display: 'flex', gap: '0.5rem' }}>
                <div
                    className="share-button twitter"
                    onClick={() => openShareWindow(`https://twitter.com/intent/tweet?url=${shareUrl}`)}
                >
                    <TwitterIcon />
                </div>
                <div
                    className="share-button facebook"
                    onClick={() => openShareWindow(`https://www.facebook.com/sharer/sharer.php?u=${shareUrl}`)}
                >
                    {/* <FacebookIcon /> */}
                    <FaFacebookF/>
                </div>
                <div
                    className="share-button linkedin"
                    onClick={() => openShareWindow(`https://www.linkedin.com/shareArticle?mini=true&url=${shareUrl}`)}
                >
                    {/* <LinkedInIcon /> */}
                    <FaLinkedinIn/>
                </div>
            </div>
        </div>
    );
};

export default SocialShare;
