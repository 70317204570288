import React from "react";
import "./Footer.css";
import { Link } from "react-router-dom";

export default function Footer() {
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <div id="footercontainer" className="footercontainer">
      <footer>
        <div className="container">
          <div className="row justify-content-between">
            {/* Logo and About Column */}
            <div className="col-lg-4 col-md-6 mb-4 footer-brand">
              <Link to="/" onClick={scrollToTop}>
                <img className="img-footer" src="/Images/Digitechub_SVG_file_white.svg" alt="DigiTechHub Logo" />
              </Link>
              <p className="brand-description">
                Empowering businesses through innovative digital solutions. We transform ideas into reality with cutting-edge technology and expertise.
              </p>
              <div className="footer-cta">
                <Link to="/Contact" onClick={scrollToTop} className="cta-button">
                  Start Your Journey
                </Link>
              </div>
            </div>

            {/* Quick Links Column */}
            <div className="col-lg-2 col-md-6 mb-4">
              <h6 id="footer-title">Company</h6>
              <ul className="footer-links">
                <li><Link to="/about-us" onClick={scrollToTop} id="text">About Us</Link></li>
                <li><Link to="/blog" onClick={scrollToTop} id="text">Blog</Link></li>
                <li><Link to="/Contact" onClick={scrollToTop} id="text">Contact Us</Link></li>
                <li><Link to="/" onClick={scrollToTop} id="text">Corporate</Link></li>
              </ul>
            </div>

            {/* Services Column */}
            <div className="col-lg-2 col-md-6 mb-4">
              <h6 id="footer-title">Solutions</h6>
              <ul className="footer-links">
                <li><Link to="/platforms" onClick={scrollToTop} id="text">Platforms</Link></li>
                <li><Link to="/services" onClick={scrollToTop} id="text">Services</Link></li>
                <li><Link to="/solutions" onClick={scrollToTop} id="text">Solutions</Link></li>
                <li><Link to="/case-studies" onClick={scrollToTop} id="text">Case Studies</Link></li>
              </ul>
            </div>

            {/* Contact Column */}
            <div className="col-lg-3 col-md-6 mb-4">
              <h6 id="footer-title">Get in Touch</h6>
              <ul className="footer-links contact-info">
                <li>
                  <span className="contact-label">Email</span>
                  <a href="mailto:info@digitechub.com" id="text">info@digitechub.com</a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        {/* Copyright */}
        <div className="footer-bottom">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-6">
                <p className="copyright-text">
                  © {new Date().getFullYear()} DigiTechHub. All rights reserved.
                </p>
              </div>
              <div className="col-md-6">
                <ul className="legal-links">
                  <li><Link to="/Terms" onClick={scrollToTop}>Terms of Use</Link></li>
                  <li><Link to="/Privacy" onClick={scrollToTop}>Privacy Policy</Link></li>
                  <li><Link to="/Cookie" onClick={scrollToTop}>Cookie Policy</Link></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}
