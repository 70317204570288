import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { Container, Grid, Paper, Typography, Box, Chip, Collapse } from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  ChevronRightIcon,
  LightBulbIcon,
  RocketLaunchIcon,
  ClockIcon,
  UserGroupIcon,
  BoltIcon,
  SparklesIcon,
  ChartBarIcon,
  WrenchScrewdriverIcon,
  CircleStackIcon,
  GlobeAltIcon,
  CpuChipIcon,
  CursorArrowRaysIcon,
  PresentationChartLineIcon,
  ServerIcon,
  ShieldCheckIcon,
  WindowIcon,
  DevicePhoneMobileIcon,
  DeviceTabletIcon,
  PhoneIcon,
  ArrowTrendingUpIcon,
  CloudArrowUpIcon,
  CogIcon,
  ChevronDownIcon
} from '@heroicons/react/24/outline';
import "./Home.css";
import './Modal.css';
import { Link } from 'react-router-dom';
import ReactConfetti from 'react-confetti';
import CountUp from 'react-countup';
import { Globe } from './Globe';
import { useMediaQuery } from '@mui/material';
import './HeroModal.css';

// Styled components
const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  height: '100%',
  background: 'rgba(255, 255, 255, 0.05)',
  backdropFilter: 'blur(10px)',
  border: '1px solid rgba(255, 255, 255, 0.1)',
  transition: 'all 0.3s ease-in-out',
  '&:hover': {
    transform: 'translateY(-8px)',
    background: 'rgba(255, 255, 255, 0.1)',
    borderColor: 'rgba(139, 69, 19, 0.3)',
  },
}));

const GatewaySection = styled('section')(({ theme }) => ({
  padding: theme.spacing(10, 0),
  background: 'linear-gradient(135deg, #8B4513 0%, #A0522D 100%)',
  color: '#fff',
  position: 'relative',
  overflow: 'hidden',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: 'radial-gradient(circle at 50% 50%, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0) 50%)',
  },
}));

const VisionSection = styled('section')(({ theme }) => ({
  padding: theme.spacing(10, 0),
  background: 'linear-gradient(135deg, #f5e6d3 0%, #fff 100%)',
  position: 'relative',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: 'radial-gradient(circle at 50% 50%, rgba(139, 69, 19, 0.05) 0%, rgba(139, 69, 19, 0.02) 50%)',
    pointerEvents: 'none',
  }
}));

// Welcome popup component commented out temporarily
/*
const WelcomePopup = ({ isOpen, onClose }) => {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className={`welcome-popup-overlay ${isOpen ? 'active' : ''}`} onClick={onClose}>
      {isOpen && (
        <ReactConfetti
          width={windowSize.width}
          height={windowSize.height}
          numberOfPieces={200}
          recycle={false}
          colors={['#8B4513', '#A0522D', '#D2691E', '#DEB887', '#F4A460']}
        />
      )}
      <motion.div 
        className={`welcome-popup ${isOpen ? 'active' : ''}`}
        onClick={e => e.stopPropagation()}
        initial={{ opacity: 0, scale: 0.95, y: 30 }}
        animate={{ opacity: 1, scale: 1, y: 0 }}
        transition={{ 
          duration: 0.5,
          type: "spring",
          stiffness: 300,
          damping: 20
        }}
      >
        <button className="welcome-popup-close" onClick={onClose}>×</button>
        
        <motion.div
          className="welcome-popup-content"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.2, duration: 0.5 }}
        >
          <motion.div 
            className="welcome-popup-icon-wrapper"
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            transition={{ 
              delay: 0.3,
              type: "spring",
              stiffness: 400,
              damping: 15
            }}
          >
            <RocketLaunchIcon className="welcome-popup-feature-icon" />
          </motion.div>
          
          <motion.h2 
            className="welcome-popup-title"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.4, duration: 0.5 }}
          >
            Exciting News!
          </motion.h2>
          
          <motion.p 
            className="welcome-popup-subtitle"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.5, duration: 0.5 }}
          >
            We're thrilled to announce our new Mortgage Services! Discover tailored mortgage solutions designed to make your homeownership dreams a reality.
          </motion.p>
          
          <motion.div 
            className="welcome-popup-actions"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.6, duration: 0.5 }}
          >
            <Link to="/services/mortgages" className="welcome-popup-button">
              Explore Mortgage Services
              <ChevronRightIcon className="welcome-popup-icon" />
            </Link>
          </motion.div>
        </motion.div>
      </motion.div>
    </div>
  );
};
*/

const HeroSection = () => {
  const [modalType, setModalType] = useState(null);

  const handleBlockClick = (type) => {
    setModalType(type);
    document.body.style.overflow = 'hidden'; // Prevent background scrolling
  };

  const handleCloseModal = () => {
    setModalType(null);
    document.body.style.overflow = 'visible'; // Restore scrolling
  };

  // Handle escape key press
  useEffect(() => {
    const handleEscape = (e) => {
      if (e.key === 'Escape') {
        handleCloseModal();
      }
    };

    if (modalType) {
      window.addEventListener('keydown', handleEscape);
    }

    return () => {
      window.removeEventListener('keydown', handleEscape);
    };
  }, [modalType]);

  const getModalContent = () => {
    switch (modalType) {
      case 'platforms':
        return {
          title: 'Our Platforms',
          links: [
            { to: '/platfom/e-cart', text: 'E-Cart' },
            { to: '/platforms/OnlineTutor', text: 'Online Tutor' },
            { to: '/platform/cloudecipher', text: 'Cloudcipher' },
            { to: '/platforms/OnlineAnalytics', text: 'Online Analytics' },
            { to: '/platform/ISPMonitoring', text: 'ISP Monitoring' },
            { to: '/platforms/Smartvision', text: 'AI Smart Vision' }
          ]
        };
      case 'services':
        return {
          title: 'Our Services',
          links: [
            { to: '/service/StafAugmentation', text: 'Staff Augmentation' },
            { to: '/service/IOTServices', text: 'IOT' },
            { to: '/service/ManagedServices', text: 'Managed Services' },
            { to: '/service/AI', text: 'Artificial Intelligence' },
            { to: '/service/blockchain', text: 'Blockchain' },
            { to: '/service/CyberSecurity1', text: 'Cyber Security' },
            { to: '/service/mortgages', text: 'Mortgages' }
          ]
        };
      case 'solutions':
        return {
          title: 'Our Solutions',
          links: [
            { to: '/solution/DroneSolution', text: 'Drone Solutions' },
            { to: '/solution/SuplyCL', text: 'Supplychain & Logistics' },
            { to: '/solution/FinancialS', text: 'Financial Services' },
            { to: '/solution/HealthcareAndPharma', text: 'Healthcare and Pharma' },
            { to: '/solution/E-commerce', text: 'E-commerce' },
            { to: '/solution/Automative', text: 'Automotive' }
          ]
        };
      default:
        return null;
    }
  };

  return (
    <section id="hero" className="hero-section">
      <div className="animated-bg">
        <div className="wave"></div>
        <div className="wave"></div>
      </div>

      <div className="floating-shapes">
        <div className="shape"></div>
        <div className="shape"></div>
        <div className="shape"></div>
      </div>

      <div className="hero-content-wrapper">
        <div className="hero-text">
          <motion.div 
            className="title-wrapper"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
          >
            <h1 className="hero-title">
              <span className="title-line">Technology Hub</span>
              <span className="title-line accent">For AI Driven</span>
              <span className="title-line">Innovative Solutions</span>
            </h1>
          </motion.div>

          <div className="hero-cards">
            <motion.div 
              className="hero-card"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8, delay: 0.6 }}
              onClick={() => handleBlockClick('platforms')}
              style={{ cursor: 'pointer' }}
            >
              <LightBulbIcon className="card-icon" />
              <h3 className="card-title">Platforms</h3>
              <p className="card-description">Digital platforms powering modern enterprises</p>
            </motion.div>

            <motion.div 
              className="hero-card"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8, delay: 0.8 }}
              onClick={() => handleBlockClick('services')}
              style={{ cursor: 'pointer' }}
            >
              <RocketLaunchIcon className="card-icon" />
              <h3 className="card-title">Services</h3>
              <p className="card-description">Comprehensive digital transformation services</p>
            </motion.div>

            <motion.div 
              className="hero-card"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8, delay: 1 }}
              onClick={() => handleBlockClick('solutions')}
              style={{ cursor: 'pointer' }}
            >
              <SparklesIcon className="card-icon" />
              <h3 className="card-title">Solutions</h3>
              <p className="card-description">Custom solutions for business growth</p>
            </motion.div>
          </div>
        </div>

        <motion.div 
          className="hero-visual"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          <div className="tech-image-container">
            <img 
              src="https://images.unsplash.com/photo-1486406146926-c627a92ad1ab?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80" 
              alt="Modern Corporate Buildings" 
              className="tech-image"
            />
            <div className="tech-overlay">
              <div className="tech-dots"></div>
              <div className="tech-line"></div>
            </div>
          </div>
        </motion.div>
      </div>

      {/* Hero Modal */}
      {modalType && (
        <div className={`hero-modal-overlay ${modalType ? 'active' : ''}`} onClick={handleCloseModal}>
          <div className="hero-modal" onClick={e => e.stopPropagation()}>
            <button className="hero-modal-close" onClick={handleCloseModal} aria-label="Close modal">×</button>
            <div className="hero-modal-content">
              <h2 className="hero-modal-title">{getModalContent().title}</h2>
              <div className="hero-modal-links">
                {getModalContent().links.map((link, index) => (
                  <Link 
                    key={index}
                    to={link.to}
                    className="hero-modal-link"
                    onClick={handleCloseModal}
                  >
                    {link.text}
                  </Link>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

const Gateway = () => {
  const features = [
    {
      icon: RocketLaunchIcon,
      title: "Managed Services",
      description: "Comprehensive IT management and support solutions",
      color: "#8B4513",
      link: "/service/ManagedServices"
    },
    {
      icon: CpuChipIcon,
      title: "Smart Vision",
      description: "AI-powered vision and monitoring solutions",
      color: "#8B4513",
      link: "/platforms/Smartvision"
    },
    {
      icon: ChartBarIcon,
      title: "Data Analytics",
      description: "Advanced data analysis and insights",
      color: "#8B4513",
      link: "/platforms/OnlineAnalytics"
    },
    {
      icon: ShieldCheckIcon,
      title: "Cyber Security",
      description: "Robust security solutions and services",
      color: "#8B4513",
      link: "/service/CyberSecurity1"
    },
    {
      icon: SparklesIcon,
      title: "BPM Solutions",
      description: "MLOBuddy.com & Kalpvrishk.com",
      color: "#8B4513",
      links: [
        { url: "https://mlobuddy.com", text: "MLOBuddy" },
        { url: "https://kalpvrishk.com", text: "Kalpvrishk" }
      ]
    },
    {
      icon: LightBulbIcon,
      title: "Case Studies",
      description: "Success stories and use cases",
      color: "#8B4513",
      link: "/platform/E-cart/casestudy-vitualwallet"
    }
  ];

  return (
    <GatewaySection id="gateway">
      <Container maxWidth="lg">
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          viewport={{ once: true }}
          transition={{ duration: 0.8 }}
        >
          <Box textAlign="center" mb={8} sx={{ position: 'relative', py: 3 }}>
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.8 }}
            >
              <Typography
                variant="h1"
                sx={{
                  fontSize: { xs: '4rem', md: '8rem' },
                  fontWeight: 800,
                  color: 'rgba(255, 255, 255, 0.02)',
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  width: '100%',
                  letterSpacing: '0.2em',
                  textTransform: 'uppercase',
                  pointerEvents: 'none',
                  textAlign: 'center',
                  whiteSpace: 'nowrap',
                  zIndex: 0,
                  userSelect: 'none'
                }}
              >
                GATEWAY
              </Typography>
              <Box sx={{ position: 'relative', zIndex: 1 }}>
                <Typography
                  variant="h2"
                  sx={{
                    fontSize: { xs: '1.8rem', md: '3.5rem' },
                    fontWeight: 800,
                    marginBottom: 2,
                    color: '#FFFFFF',
                    textTransform: 'uppercase',
                    letterSpacing: '0.1em',
                    position: 'relative',
                    zIndex: 1,
                    '&::after': {
                      content: '""',
                      position: 'absolute',
                      bottom: '-10px',
                      left: '50%',
                      transform: 'translateX(-50%)',
                      width: '60px',
                      height: '3px',
                      background: '#FFFFFF'
                    }
                  }}
                >
                  Comprehensive Digital Excellence
                </Typography>
                <Typography
                  sx={{
                    fontSize: { xs: '0.95rem', md: '1.1rem' },
                    color: '#666',
                    maxWidth: '700px',
                    margin: '1.5rem auto 0',
                    lineHeight: { xs: 1.8, md: 1.6 },
                    padding: { xs: '0 1rem', md: 0 },
                    fontWeight: 600
                  }}
                >
                  
                </Typography>
              </Box>
            </motion.div>
          </Box>

          <Grid container spacing={4} justifyContent="center">
            {features.map((feature, index) => (
              <Grid item xs={12} sm={6} md={4} key={feature.title}>
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true }}
                  transition={{ duration: 0.5, delay: index * 0.1 }}
                >
                  {feature.links ? (
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                      <Paper
                        elevation={0}
                        sx={{
                          background: `linear-gradient(135deg, ${feature.color}dd, ${feature.color}ff)`,
                          padding: '2rem',
                          height: '100%',
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'flex-start',
                          textAlign: 'left',
                          borderRadius: '16px',
                          position: 'relative',
                          overflow: 'hidden',
                          transition: 'all 0.3s ease-in-out',
                          '&:hover': {
                            transform: 'translateY(-8px)',
                            '& .feature-icon': {
                              transform: 'scale(1.1) rotate(5deg)',
                            },
                          },
                          '&::before': {
                            content: '""',
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            background: 'rgba(255, 255, 255, 0.1)',
                            backdropFilter: 'blur(10px)',
                          }
                        }}
                      >
                        <Box sx={{ position: 'relative', zIndex: 1, width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: 2 }}>
                          <feature.icon 
                            className="feature-icon"
                            style={{
                              width: 56,
                              height: 56,
                              color: '#fff',
                              marginLeft: 0,
                              transition: 'transform 0.3s ease-in-out'
                            }}
                          />
                          <Typography
                            variant="h5"
                            sx={{
                              color: '#fff',
                              fontWeight: 600,
                              fontSize: '1.5rem',
                              marginBottom: 1,
                              textAlign: 'left',
                              width: '100%'
                            }}
                          >
                            {feature.title}
                          </Typography>
                          <Typography
                            sx={{
                              color: 'rgba(255, 255, 255, 0.9)',
                              fontSize: '1rem',
                              lineHeight: 1.6,
                              textAlign: 'left',
                              width: '100%'
                            }}
                          >
                            {feature.description}
                          </Typography>
                          <Box sx={{ display: 'flex', gap: 2, mt: 2 }}>
                            {feature.links.map((link, i) => (
                              <a 
                                key={i}
                                href={link.url}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{
                                  color: '#fff',
                                  textDecoration: 'none',
                                  padding: '8px 16px',
                                  borderRadius: '8px',
                                  background: 'rgba(255, 255, 255, 0.2)',
                                  transition: 'all 0.3s ease',
                                  '&:hover': {
                                    background: 'rgba(255, 255, 255, 0.3)',
                                  }
                                }}
                              >
                                {link.text}
                              </a>
                            ))}
                          </Box>
                        </Box>
                      </Paper>
                    </Box>
                  ) : (
                    <Link 
                      to={feature.link} 
                      style={{ textDecoration: 'none' }}
                      onClick={() => window.scrollTo(0, 0)}
                    >
                      <Paper
                        elevation={0}
                        sx={{
                          background: `linear-gradient(135deg, ${feature.color}dd, ${feature.color}ff)`,
                          padding: '2rem',
                          height: '100%',
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'flex-start',
                          textAlign: 'left',
                          borderRadius: '16px',
                          position: 'relative',
                          overflow: 'hidden',
                          cursor: 'pointer',
                          transition: 'all 0.3s ease-in-out',
                          '&:hover': {
                            transform: 'translateY(-8px)',
                            '& .feature-icon': {
                              transform: 'scale(1.1) rotate(5deg)',
                            },
                          },
                          '&::before': {
                            content: '""',
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            background: 'rgba(255, 255, 255, 0.1)',
                            backdropFilter: 'blur(10px)',
                          }
                        }}
                      >
                        <Box sx={{ position: 'relative', zIndex: 1, width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: 2 }}>
                          <feature.icon 
                            className="feature-icon"
                            style={{
                              width: 56,
                              height: 56,
                              color: '#fff',
                              marginLeft: 0,
                              transition: 'transform 0.3s ease-in-out'
                            }}
                          />
                          <Typography
                            variant="h5"
                            sx={{
                              color: '#fff',
                              fontWeight: 600,
                              fontSize: '1.5rem',
                              marginBottom: 1,
                              textAlign: 'left',
                              width: '100%'
                            }}
                          >
                            {feature.title}
                          </Typography>
                          <Typography
                            sx={{
                              color: 'rgba(255, 255, 255, 0.9)',
                              fontSize: '1rem',
                              lineHeight: 1.6,
                              textAlign: 'left',
                              width: '100%'
                            }}
                          >
                            {feature.description}
                          </Typography>
                        </Box>
                      </Paper>
                    </Link>
                  )}
                </motion.div>
              </Grid>
            ))}
          </Grid>
        </motion.div>
      </Container>
    </GatewaySection>
  );
};

const Vision = () => {
  const isMobile = useMediaQuery('(max-width:768px)');
  const visionPoints = [
    {
      icon: LightBulbIcon,
      title: "Innovation Leadership",
      description: "Pioneering breakthrough technologies that reshape industries and set new benchmarks in digital excellence.",
      color: "#8B4513"
    },
    {
      icon: SparklesIcon,
      title: "Digital Excellence",
      description: "Creating seamless, intuitive solutions that transform user experiences and drive business success.",
      color: "#A0522D"
    },
    {
      icon: RocketLaunchIcon,
      title: "Future-Ready Solutions",
      description: "Building scalable, adaptable platforms that evolve with emerging technologies and market demands.",
      color: "#ff5722"
    },
    {
      icon: UserGroupIcon,
      title: "Global Impact",
      description: "Empowering businesses worldwide to achieve their digital transformation goals and reach new heights.",
      color: "#ff7043"
    }
  ];

  return (
    <VisionSection id="vision">
      <Container maxWidth="xl">
        <motion.div
          initial={isMobile ? { opacity: 1 } : { opacity: 0 }}
          whileInView={isMobile ? { opacity: 1 } : { opacity: 1 }}
          viewport={{ once: true }}
          transition={{ duration: isMobile ? 0 : 0.8 }}
        >
          {/* Header with Large Background Title */}
          <Box sx={{ textAlign: 'center', mb: 6, position: 'relative', py: 3 }}>
            <Typography
              variant="h1"
              sx={{
                fontSize: { xs: '2.5rem', md: '8rem' },
                fontWeight: 800,
                color: 'rgba(255, 107, 0, 0.05)',
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '100%',
                letterSpacing: '0.2em',
                textTransform: 'uppercase',
                pointerEvents: 'none',
                display: isMobile ? 'none' : 'block'
              }}
            >
              VISION
            </Typography>
            <Box sx={{ position: 'relative', zIndex: 1 }}>
              <Typography
                variant="h2"
                sx={{
                  fontSize: { xs: '1.8rem', md: '3.5rem' },
                  fontWeight: 800,
                  background: 'linear-gradient(135deg, #8B4513, #A0522D)',
                  WebkitBackgroundClip: 'text',
                  WebkitTextFillColor: 'transparent',
                  marginBottom: 2,
                  textTransform: 'uppercase',
                  letterSpacing: '0.1em',
                  position: 'relative',
                  '&::after': {
                    content: '""',
                    position: 'absolute',
                    bottom: '-10px',
                    left: '50%',
                    transform: 'translateX(-50%)',
                    width: '60px',
                    height: '3px',
                    background: '#8B4513'
                  }
                }}
              >
                Our Vision
              </Typography>
              <Typography
                sx={{
                  fontSize: { xs: '0.95rem', md: '1.1rem' },
                  color: '#666',
                  maxWidth: '700px',
                  margin: '1.5rem auto 0',
                  lineHeight: { xs: 1.8, md: 1.6 },
                  padding: { xs: '0 1rem', md: 0 }
                }}
              >
                We envision a future where technology seamlessly enhances human potential, 
                where innovation drives sustainable growth, and where digital solutions 
                create meaningful impact across industries and communities.
              </Typography>
            </Box>
          </Box>

          <Box sx={{ 
            position: 'relative',
            width: '100%',
            mt: 4,
            overflow: isMobile ? 'visible' : 'hidden'
          }}>
            {/* Gradient Masks - Only show on desktop */}
            {!isMobile && (
              <>
                <Box sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '200px',
                  height: '100%',
                  background: 'linear-gradient(to right, #fff5eb 0%, transparent 100%)',
                  zIndex: 2,
                  pointerEvents: 'none'
                }} />
                <Box sx={{
                  position: 'absolute',
                  top: 0,
                  right: 0,
                  width: '200px',
                  height: '100%',
                  background: 'linear-gradient(to left, #fff5eb 0%, transparent 100%)',
                  zIndex: 2,
                  pointerEvents: 'none'
                }} />
              </>
            )}

            {/* Single Row Marquee */}
            <Box
              sx={{
                display: 'flex',
                gap: 4,
                animation: isMobile ? 'none' : 'marquee 35s linear infinite',
                '&:hover': {
                  animationPlayState: 'paused'
                },
                '@keyframes marquee': {
                  '0%': { transform: 'translateX(0)' },
                  '100%': { transform: 'translateX(-50%)' }
                },
                paddingX: isMobile ? '0' : '100px',
                flexDirection: isMobile ? 'column' : 'row'
              }}
            >
              {/* Only show one set of vision points on mobile */}
              {(isMobile ? visionPoints : [...visionPoints, ...visionPoints]).map((point, index) => (
                <Paper
                  key={index}
                  elevation={4}
                  sx={{
                    padding: 4,
                    minWidth: isMobile ? 'auto' : '500px',
                    background: 'rgba(255, 255, 255, 0.95)',
                    borderRadius: '20px',
                    border: '1px solid rgba(255, 107, 0, 0.1)',
                    transition: 'all 0.4s ease-in-out',
                    position: 'relative',
                    overflow: 'hidden',
                    opacity: 1,
                    filter: 'none',
                    '&::before': {
                      content: '""',
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      right: 0,
                      height: '4px',
                      background: `linear-gradient(90deg, ${point.color}, transparent)`,
                      opacity: 0.7
                    }
                  }}
                >
                  <Box sx={{ 
                    position: 'relative',
                    zIndex: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    textAlign: 'center'
                  }}>
                    <Box sx={{
                      width: '70px',
                      height: '70px',
                      borderRadius: '16px',
                      background: `linear-gradient(135deg, ${point.color}10, ${point.color}20)`,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      marginBottom: 2,
                      transform: 'rotate(-5deg)',
                      transition: 'all 0.3s ease-in-out'
                    }}>
                      <point.icon 
                        className="vision-icon"
          style={{
                          width: 35,
                          height: 35,
                          color: '#666',
                          transition: 'all 0.3s ease-in-out'
                        }}
                      />
                    </Box>
                    <Typography
                      variant="h5"
                      sx={{
                        fontWeight: 700,
                        color: '#333',
                        marginBottom: 2,
                        fontSize: { xs: '1.25rem', md: '1.5rem' },
                        position: 'relative',
                        '&::after': {
                          content: '""',
                          position: 'absolute',
                          bottom: '-8px',
                          left: '50%',
                          transform: 'translateX(-50%)',
                          width: '40px',
                          height: '2px',
                          background: point.color,
                          opacity: 0.5
                        }
                      }}
                    >
                      {point.title}
                    </Typography>
                    <Typography
                      sx={{
                        color: '#666',
                        fontSize: { xs: '0.95rem', md: '1.1rem' },
                        lineHeight: { xs: 1.8, md: 1.6 },
                        position: 'relative',
                        zIndex: 2
                      }}
                    >
                      {point.description}
                    </Typography>
                  </Box>
                </Paper>
              ))}
            </Box>
          </Box>

          {/* Background Decorative Elements - Hidden on mobile */}
          {!isMobile && (
            <Box sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              overflow: 'hidden',
              pointerEvents: 'none',
              zIndex: 1
            }}>
              {[...Array(3)].map((_, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, scale: 0 }}
                  whileInView={{ opacity: 0.3, scale: 1 }}
                  viewport={{ once: true }}
                  transition={{ duration: 1, delay: index * 0.2 }}
                  style={{
                    position: 'absolute',
                    width: '300px',
                    height: '300px',
                    borderRadius: '50%',
                    background: `linear-gradient(135deg, ${visionPoints[index % 4].color}05, ${visionPoints[(index + 1) % 4].color}05)`,
                    left: `${20 + index * 30}%`,
                    top: `${20 + index * 20}%`,
                    transform: 'translate(-50%, -50%)',
                    filter: 'blur(40px)'
                  }}
                />
              ))}
            </Box>
          )}
        </motion.div>
      </Container>
    </VisionSection>
  );
};

const Mission = () => {
  const missionPoints = [
    {
      icon: ChartBarIcon,
      title: "Sustainable Growth",
      description: "Building long-term success strategies that drive continuous business evolution and market leadership.",
      gradient: "linear-gradient(135deg, #8B4513, #A0522D)"
    },
    {
      icon: CpuChipIcon,
      title: "Technical Excellence",
      description: "Maintaining the highest quality standards through cutting-edge technology and innovative solutions.",
      gradient: "linear-gradient(135deg, #A0522D, #C06E33)"
    },
    {
      icon: UserGroupIcon,
      title: "Client Success",
      description: "Empowering our clients with transformative digital solutions that exceed expectations.",
      gradient: "linear-gradient(135deg, #A0522D, #C06E33)"
    },
    {
      icon: SparklesIcon,
      title: "Innovation Focus",
      description: "Constantly pushing boundaries to create groundbreaking solutions for tomorrow's challenges.",
      gradient: "linear-gradient(135deg, #8B4513, #A0522D)"
    }
  ];

  return (
    <section id="mission" className="mission-section">
      <Container maxWidth="xl">
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          viewport={{ once: true }}
          transition={{ duration: 0.8 }}
        >
          {/* Header with Large Background Title */}
          <Box sx={{ textAlign: 'center', mb: 6, position: 'relative', py: 3 }}>
            <Typography
              variant="h1"
              sx={{
                fontSize: { xs: '4rem', md: '8rem' },
                fontWeight: 800,
                color: 'rgba(255, 107, 0, 0.05)',
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '100%',
                letterSpacing: '0.2em',
                textTransform: 'uppercase',
                pointerEvents: 'none'
              }}
            >
              MISSION
            </Typography>
            <Box sx={{ position: 'relative', zIndex: 1 }}>
              <Typography
                variant="h2"
                sx={{
                  fontSize: { xs: '1.8rem', md: '3.5rem' },
                  fontWeight: 800,
                  background: 'linear-gradient(135deg, #8B4513, #A0522D)',
                  WebkitBackgroundClip: 'text',
                  WebkitTextFillColor: 'transparent',
                  marginBottom: 2,
                  textTransform: 'uppercase',
                  letterSpacing: '0.1em',
                  position: 'relative',
                  '&::after': {
                    content: '""',
                    position: 'absolute',
                    bottom: '-10px',
                    left: '50%',
                    transform: 'translateX(-50%)',
                    width: '60px',
                    height: '3px',
                    background: '#8B4513'
                  }
                }}
              >
                Our Mission
              </Typography>
              <Typography
                sx={{
                  fontSize: { xs: '0.95rem', md: '1.1rem' },
                  color: '#666',
                  maxWidth: '700px',
                  margin: '1.5rem auto 0',
                  lineHeight: { xs: 1.8, md: 1.6 },
                  padding: { xs: '0 1rem', md: 0 }
                }}
              >
                We're committed to transforming businesses through innovative digital solutions,
                creating lasting impact and sustainable growth.
              </Typography>
            </Box>
          </Box>

          {/* Mission Points */}
          <Container maxWidth="lg">
            <Box sx={{ 
              display: 'grid',
              gridTemplateColumns: { xs: '1fr', md: 'repeat(2, 1fr)' },
              gap: 4,
              position: 'relative',
              zIndex: 1,
              px: { xs: 2, md: 4 }
            }}>
              {missionPoints.map((point, index) => (
                <motion.div
                  key={point.title}
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true }}
                  transition={{ duration: 0.5, delay: index * 0.1 }}
                >
                  <Paper
                    sx={{
                      position: 'relative',
                      p: 4,
                      borderRadius: 3,
                      background: 'rgba(255, 255, 255, 0.7)',
                      backdropFilter: 'blur(10px)',
                      border: '1px solid rgba(255, 255, 255, 0.3)',
                      overflow: 'hidden',
                      transition: 'all 0.3s ease-in-out',
                      '&:hover': {
                        transform: 'translateY(-5px)',
                        boxShadow: '0 15px 30px rgba(255, 107, 0, 0.1)',
                        borderColor: 'rgba(255, 107, 0, 0.2)',
                        '& .mission-icon-wrapper': {
                          transform: 'scale(1.1)'
                        }
                      },
                      '&::before': {
                        content: '""',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        height: '3px',
                        background: point.gradient,
                        opacity: 0.7
                      }
                    }}
                  >
                    <Box sx={{ position: 'relative', zIndex: 1 }}>
                      <Box
                        className="mission-icon-wrapper"
                        sx={{
                          width: 50,
                          height: 50,
                          borderRadius: '12px',
                          background: point.gradient,
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          mb: 2,
                          transition: 'transform 0.3s ease'
                        }}
                      >
                        <point.icon style={{ width: 25, height: 25, color: 'white' }} />
                      </Box>
                      <Typography
                        variant="h5"
                        sx={{
                          fontWeight: 700,
                          color: '#2D2D2D',
                          mb: 1,
                          fontSize: '1.25rem'
                        }}
                      >
                        {point.title}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: '0.95rem',
                          color: '#666',
                          lineHeight: 1.5
                        }}
                      >
                        {point.description}
                      </Typography>
                    </Box>
                  </Paper>
                </motion.div>
              ))}
            </Box>
          </Container>
        </motion.div>
      </Container>
    </section>
  );
};

const Goals = () => {
  const goals = [
    {
      icon: ChartBarIcon,
      title: "Business Growth",
      description: "Accelerating client success through innovative digital transformation strategies.",
      gradient: "linear-gradient(135deg, #8B4513, #A0522D)"
    },
    {
      icon: RocketLaunchIcon,
      title: "Innovation Leadership",
      description: "Setting new industry standards with cutting-edge solutions.",
      gradient: "linear-gradient(135deg, #A0522D, #C06E33)"
    },
    {
      icon: UserGroupIcon,
      title: "Customer Value",
      description: "Delivering exceptional value through tailored solutions.",
      gradient: "linear-gradient(135deg, #A0522D, #C06E33)"
    },
    {
      icon: SparklesIcon,
      title: "Digital Excellence",
      description: "Maintaining the highest standards in every solution.",
      gradient: "linear-gradient(135deg, #8B4513, #A0522D)"
    }
  ];

  return (
    <section id="goals" className="goals-section">
      <Container maxWidth="xl">
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          viewport={{ once: true }}
          transition={{ duration: 0.8 }}
        >
          {/* Header with Large Background Title */}
          <Box sx={{ textAlign: 'center', mb: 6, position: 'relative', py: 3 }}>
            <Typography
              variant="h1"
              sx={{
                fontSize: { xs: '4rem', md: '8rem' },
                fontWeight: 800,
                color: 'rgba(255, 107, 0, 0.05)',
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '100%',
                letterSpacing: '0.2em',
                textTransform: 'uppercase',
                pointerEvents: 'none'
              }}
            >
              GOALS
            </Typography>
            <Box sx={{ position: 'relative', zIndex: 1 }}>
              <Typography
                variant="h2"
                sx={{
                  fontSize: { xs: '1.8rem', md: '3.5rem' },
                  fontWeight: 800,
                  background: 'linear-gradient(135deg, #8B4513, #A0522D)',
                  WebkitBackgroundClip: 'text',
                  WebkitTextFillColor: 'transparent',
                  marginBottom: 2,
                  textTransform: 'uppercase',
                  letterSpacing: '0.1em',
                  position: 'relative',
                  '&::after': {
                    content: '""',
                    position: 'absolute',
                    bottom: '-10px',
                    left: '50%',
                    transform: 'translateX(-50%)',
                    width: '60px',
                    height: '3px',
                    background: '#8B4513'
                  }
                }}
              >
                Our Goals
              </Typography>
              <Typography
                sx={{
                  fontSize: { xs: '0.95rem', md: '1.1rem' },
                  color: '#666',
                  maxWidth: '700px',
                  margin: '1.5rem auto 0',
                  lineHeight: { xs: 1.8, md: 1.6 },
                  padding: { xs: '0 1rem', md: 0 }
                }}
              >
                We're committed to pushing boundaries and creating lasting impact through innovative digital solutions
              </Typography>
            </Box>
          </Box>

          {/* Goals Grid with New Design */}
          <Container maxWidth="lg">
            <Box sx={{ 
              display: 'grid',
              gridTemplateColumns: { xs: '1fr', md: 'repeat(2, 1fr)' },
              gap: 4,
              position: 'relative',
              zIndex: 1,
              px: { xs: 2, md: 4 }
            }}>
              {goals.map((goal, index) => (
                <motion.div
                  key={goal.title}
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true }}
                  transition={{ duration: 0.5, delay: index * 0.1 }}
                >
                  <Paper
                    sx={{
                      position: 'relative',
                      p: 4,
                      borderRadius: 3,
                      background: 'rgba(255, 255, 255, 0.7)',
                      backdropFilter: 'blur(10px)',
                      border: '1px solid rgba(255, 255, 255, 0.3)',
                      overflow: 'hidden',
                      transition: 'all 0.3s ease-in-out',
                      '&:hover': {
                        transform: 'translateY(-5px)',
                        boxShadow: '0 15px 30px rgba(255, 107, 0, 0.1)',
                        borderColor: 'rgba(255, 107, 0, 0.2)',
                        '& .goal-icon-wrapper': {
                          transform: 'scale(1.1)'
                        }
                      },
                      '&::before': {
                        content: '""',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        height: '3px',
                        background: goal.gradient,
                        opacity: 0.7
                      }
                    }}
                  >
                    <Box sx={{ position: 'relative', zIndex: 1 }}>
                      <Box
                        className="goal-icon-wrapper"
                        sx={{
                          width: 50,
                          height: 50,
                          borderRadius: '12px',
                          background: goal.gradient,
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          mb: 2,
                          transition: 'transform 0.3s ease'
                        }}
                      >
                        <goal.icon style={{ width: 25, height: 25, color: 'white' }} />
                      </Box>
                      <Typography
                        variant="h5"
                        sx={{
                          fontWeight: 700,
                          color: '#2D2D2D',
                          mb: 1,
                          fontSize: '1.25rem'
                        }}
                      >
                        {goal.title}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: '0.95rem',
                          color: '#666',
                          lineHeight: 1.5
                        }}
                      >
                        {goal.description}
                      </Typography>
                    </Box>
                  </Paper>
                </motion.div>
              ))}
            </Box>
          </Container>
        </motion.div>
      </Container>
    </section>
  );
};

const Modal = ({ section, onClose, isActive }) => {
  console.log('Modal render:', { isActive, hasSection: !!section }); // Debug log

  return (
    <div 
      className={`modal-overlay ${isActive ? 'active' : ''}`} 
      onClick={onClose}
      style={{ position: 'fixed' }} // Force fixed positioning
    >
      {section && (
        <div 
          className="modal-content" 
          onClick={e => e.stopPropagation()}
          style={{ position: 'relative' }} // Force relative positioning
        >
          <button className="modal-close" onClick={onClose}>×</button>
          <div className="modal-header">
            <h2 className="modal-title">{section.content.title}</h2>
            <p className="modal-subtitle">{section.content.subtitle}</p>
          </div>
          <div className="modal-grid">
            {section.content.features.map((feature, index) => (
              <div key={index} className="modal-grid-item">
                <feature.icon className="linear-card-icon" />
                <h3>{feature.title}</h3>
                <p>{feature.description}</p>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

const FeatureCard = ({ icon: Icon, title, description }) => (
  <div className="feature-card">
    <Icon className="feature-icon" />
    <h3 className="feature-title">{title}</h3>
    <p className="feature-description">{description}</p>
  </div>
);

const TechnologyAndSuccess = () => {
  const [expandedAccordion, setExpandedAccordion] = useState(false);

  const technologies = [
    {
      icon: CpuChipIcon,
      title: "AI & Machine Learning",
      description: "Transforming businesses with intelligent automation and predictive analytics",
      tags: ["Neural Networks", "Deep Learning", "Predictive Analytics"]
    },
    {
      icon: CloudArrowUpIcon,
      title: "Cloud Computing",
      description: "Scalable, secure, and flexible cloud solutions for modern enterprises",
      tags: ["AWS", "Azure", "Cloud Security"]
    }
  ];

  const caseStudies = [
    {
      title: "Virtual Wallet for E-Cart",
      description: "Digital payment and storage platform for seamless transactions",
      link: "/platform/E-cart/casestudy-vitualwallet"
    },
    {
      title: "Digital Key Store",
      description: "Revolutionary software distribution platform",
      link: "/platform/E-cart/casestudy-digitalkeystore"
    },
    {
      title: "Cloud Cipher for PharmaCo",
      description: "Enhanced data integrity solution for pharmaceutical research",
      link: "/platform/CloudServices/casestudy-cloudcipher2"
    },
    {
      title: "Cloud Cipher for Banking",
      description: "Secure banking operations platform",
      link: "/platform/CloudServices/casestudy-cloudcipher3"
    },
    {
      title: "BharatMake E-commerce",
      description: "Revolutionary E-cart platform for digital commerce",
      link: "/platform/E-cart/casestudt-bharatmake"
    }
  ];

  const successStories = [
    {
      icon: ChartBarIcon,
      metric: 95,
      suffix: "%",
      title: "Client Satisfaction",
      description: "Consistently exceeding expectations"
    },

    {
      icon: RocketLaunchIcon,
      metric: 500,
      suffix: "+",
      title: "Projects Delivered",
      description: "Across various industries"
    },
    {
      icon: UserGroupIcon,
      metric: 200,
      suffix: "+",
      title: "Expert Team",
      description: "Skilled professionals"
    }
  ];

  return (
    <Box id="tech-success" sx={{ py: 8, px: { xs: 2, md: 4 }, background: '#f8f9fa' }}>
      <Container maxWidth="lg">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.6 }}
        >
          {/* Header with Large Background Title */}
          <Box sx={{ textAlign: 'center', mb: 6, position: 'relative', py: 3 }}>
            <Typography
              variant="h1"
              sx={{
                fontSize: { xs: '4rem', md: '8rem' },
                fontWeight: 800,
                color: 'rgba(255, 107, 0, 0.05)',
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '100%',
                letterSpacing: '0.2em',
                textTransform: 'uppercase',
                pointerEvents: 'none'
              }}
            >
              TECHNOLOGY
            </Typography>
            <Box sx={{ position: 'relative', zIndex: 1 }}>
              <Typography
                variant="h2"
                sx={{
                  fontSize: { xs: '1.8rem', md: '3.5rem' },
                  fontWeight: 800,
                  background: 'linear-gradient(135deg, #8B4513, #A0522D)',
                  WebkitBackgroundClip: 'text',
                  WebkitTextFillColor: 'transparent',
                  marginBottom: 2,
                  textTransform: 'uppercase',
                  letterSpacing: '0.1em',
                  position: 'relative',
                  '&::after': {
                    content: '""',
                    position: 'absolute',
                    bottom: '-10px',
                    left: '50%',
                    transform: 'translateX(-50%)',
                    width: '60px',
                    height: '3px',
                    background: '#8B4513'
                  }
                }}
              >
                Technology & Success
              </Typography>
              <Typography
                sx={{
                  fontSize: { xs: '0.95rem', md: '1.1rem' },
                  color: '#666',
                  maxWidth: '700px',
                  margin: '1.5rem auto 0',
                  lineHeight: { xs: 1.8, md: 1.6 },
                  padding: { xs: '0 1rem', md: 0 }
                }}
              >
                Empowering businesses with cutting-edge technology and proven success stories
              </Typography>
            </Box>
          </Box>

          {/* Technologies Grid */}
          <Grid container spacing={3}>
            {technologies.map((tech, index) => (
              <Grid item xs={12} md={6} key={index}>
                <motion.div
                  initial={{ opacity: 0, x: -20 }}
                  whileInView={{ opacity: 1, x: 0 }}
                  viewport={{ once: true }}
                  transition={{ duration: 0.5, delay: index * 0.2 }}
                >
                  <Box
                    sx={{
                      p: 4,
                      height: '100%',
                      borderRadius: 4,
                      background: '#fff',
                      boxShadow: '0 4px 20px rgba(0,0,0,0.08)',
                      transition: 'transform 0.3s ease',
                      '&:hover': {
                        transform: 'translateY(-8px)'
                      }
                    }}
                  >
                    <Box
                      sx={{
                        width: 60,
                        height: 60,
                        borderRadius: '16px',
                        background: 'linear-gradient(135deg, #A0522D, #D2691E)',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        mb: 3,
                        transition: 'all 0.3s ease'
                      }}
                    >
                      <tech.icon style={{ width: 30, height: 30, color: '#fff' }} />
                    </Box>
                    <Typography variant="h5" sx={{ fontWeight: 700, mb: 2, color: '#2D3748' }}>
                      {tech.title}
                    </Typography>
                    <Typography sx={{ color: '#718096', mb: 3 }}>
                      {tech.description}
                    </Typography>
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                      {tech.tags.map((tag, idx) => (
                        <Chip
                          key={idx}
                          label={tag}
                          sx={{
                            background: 'rgba(160, 82, 45, 0.1)',
                            color: '#A0522D',
                            '&:hover': {
                              background: 'rgba(160, 82, 45, 0.2)'
                            }
                          }}
                        />
                      ))}
                    </Box>
                  </Box>
                </motion.div>
              </Grid>
            ))}
          </Grid>

          {/* Horizontal Accordion for Case Studies */}
          <Box sx={{ mt: 8 }}>
            <Paper
              elevation={0}
              onClick={() => setExpandedAccordion(!expandedAccordion)}
              sx={{
                background: 'linear-gradient(135deg, #8B4513, #A0522D)',
                padding: '2rem',
                borderRadius: '16px',
                cursor: 'pointer',
                position: 'relative',
                overflow: 'hidden',
                transition: 'all 0.3s ease-in-out',
                mb: expandedAccordion ? 3 : 0,
                '&:hover': {
                  transform: 'translateY(-5px)',
                },
                '&::before': {
                  content: '""',
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  background: 'rgba(255, 255, 255, 0.1)',
                  backdropFilter: 'blur(10px)',
                }
              }}
            >
              <Box sx={{ 
                position: 'relative', 
                zIndex: 1, 
                display: 'flex', 
                alignItems: 'center', 
                justifyContent: 'space-between'
              }}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                  <LightBulbIcon 
                    style={{ 
                      width: 40, 
                      height: 40, 
                      color: '#fff',
                      transition: 'transform 0.3s ease'
                    }} 
                  />
                  <Typography
                    variant="h5"
                    sx={{
                      color: '#fff',
                      fontWeight: 600,
                      fontSize: '1.5rem'
                    }}
                  >
                    Our Innovations
                  </Typography>
                </Box>
                <ChevronDownIcon 
                  style={{
                    width: 24,
                    height: 24,
                    color: '#fff',
                    transform: expandedAccordion ? 'rotate(180deg)' : 'rotate(0)',
                    transition: 'transform 0.3s ease'
                  }}
                />
              </Box>
            </Paper>

            <Collapse in={expandedAccordion}>
              <Box 
                sx={{ 
                  display: 'flex',
                  overflowX: 'auto',
                  gap: 3,
                  pb: 2,
                  '::-webkit-scrollbar': {
                    height: '8px',
                  },
                  '::-webkit-scrollbar-track': {
                    background: '#f1f1f1',
                    borderRadius: '4px',
                  },
                  '::-webkit-scrollbar-thumb': {
                    background: '#8B4513',
                    borderRadius: '4px',
                    '&:hover': {
                      background: '#A0522D',
                    },
                  },
                }}
              >
                {caseStudies.map((study, index) => (
                  <Link 
                    key={index}
                    to={study.link}
                    style={{ textDecoration: 'none', minWidth: '300px' }}
                  >
                    <Paper
                      elevation={0}
                      sx={{
                        background: '#fff',
                        padding: '1.5rem',
                        borderRadius: '12px',
                        height: '100%',
                        border: '1px solid rgba(139, 69, 19, 0.1)',
                        transition: 'all 0.3s ease',
                        '&:hover': {
                          transform: 'translateY(-5px)',
                          boxShadow: '0 10px 30px rgba(139, 69, 19, 0.1)',
                          borderColor: 'rgba(139, 69, 19, 0.2)',
                        }
                      }}
                    >
                      <Typography
                        variant="h6"
                        sx={{
                          color: '#8B4513',
                          fontWeight: 600,
                          mb: 1,
                          fontSize: '1.1rem'
                        }}
                      >
                        {study.title}
                      </Typography>
                      <Typography
                        sx={{
                          color: '#666',
                          fontSize: '0.9rem',
                          lineHeight: 1.6
                        }}
                      >
                        {study.description}
                      </Typography>
                    </Paper>
                  </Link>
                ))}
              </Box>
            </Collapse>
          </Box>

          {/* Success Stories */}
          <Box sx={{ mt: 8 }}>
            <Grid container spacing={3}>
              {successStories.map((story, index) => (
                <Grid item xs={12} md={4} key={index}>
                  <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    viewport={{ once: true }}
                    transition={{ duration: 0.5, delay: 0.2 + index * 0.1 }}
                  >
                    <Box
                      sx={{
                        textAlign: 'center',
                        p: 3,
                        borderRadius: 3,
                        background: '#fff',
                        boxShadow: '0 4px 20px rgba(0,0,0,0.05)',
                        transition: 'all 0.3s ease',
                        '&:hover': {
                          transform: 'translateY(-5px)',
                          boxShadow: '0 10px 30px rgba(139, 69, 19, 0.1)',
                          '& .success-icon': {
                            transform: 'scale(1.1)',
                            background: 'linear-gradient(135deg, #8B4513, #A0522D)'
                          }
                        }
                      }}
                    >
                      <Box
                        className="success-icon"
                        sx={{
                          width: 48,
                          height: 48,
                          borderRadius: '50%',
                          background: 'linear-gradient(135deg, #A0522D, #D2691E)',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          mx: 'auto',
                          mb: 2,
                          transition: 'all 0.3s ease'
                        }}
                      >
                        <story.icon style={{ width: 24, height: 24, color: '#fff' }} />
                      </Box>
                      <Typography
                        variant="h3"
                        sx={{
                          fontWeight: 700,
                          color: '#A0522D',
                          mb: 1,
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          fontSize: { xs: '2.5rem', md: '3rem' }
                        }}
                      >
                        <CountUp
                          end={story.metric}
                          duration={3}
                          decimals={story.metric < 100 ? 1 : 0}
                          decimal="."
                          separator=","
                          enableScrollSpy
                          scrollSpyOnce
                          scrollSpyDelay={100}
                          delay={0.5}
                          useEasing={true}
                          easingFn={(t, b, c, d) => {
                            return c * ((t = t / d - 1) * t * t + 1) + b;
                          }}
                        />
                        <span style={{ marginLeft: '2px' }}>{story.suffix}</span>
                      </Typography>
                      <Typography
                        variant="h6"
                        sx={{
                          fontWeight: 600,
                          color: '#2D3748',
                          mb: 1,
                          fontSize: { xs: '1.1rem', md: '1.25rem' }
                        }}
                      >
                        {story.title}
                      </Typography>
                      <Typography 
                        sx={{ 
                          color: '#718096',
                          fontSize: { xs: '0.9rem', md: '1rem' }
                        }}
                      >
                        {story.description}
                      </Typography>
                    </Box>
                  </motion.div>
                </Grid>
              ))}
            </Grid>
          </Box>
        </motion.div>
      </Container>
    </Box>
  );
};

const Home = () => {
  const [activeSection, setActiveSection] = useState("hero");
  const [activeModal, setActiveModal] = useState(null);
  const [isModalActive, setIsModalActive] = useState(false);
  const [showNav, setShowNav] = useState(false);
  // Comment out welcome popup states
  /*
  const [showWelcomePopup, setShowWelcomePopup] = useState(false);
  const [hasShownPopup, setHasShownPopup] = useState(false);
  */

  const sections = [
    {
      id: "approach",
      title: "Our Approach",
      description: "A methodical and client-focused approach to digital transformation",
      icon: LightBulbIcon,
      content: {
        title: "Our Approach to Digital Excellence",
        subtitle: "We follow a comprehensive methodology to ensure successful digital transformation",
        features: [
          { icon: LightBulbIcon, title: "Understand", description: "Deep analysis of client needs" },
          { icon: SparklesIcon, title: "Innovate", description: "Creative solution design" },
          { icon: WrenchScrewdriverIcon, title: "Implement", description: "Efficient execution" },
          { icon: ChartBarIcon, title: "Optimize", description: "Continuous improvement" }
        ]
      }
    },
    {
      id: "innovation",
      title: "Innovation Hub",
      description: "Driving digital transformation through cutting-edge technologies",
      icon: SparklesIcon,
      content: {
        title: "Innovation Hub",
        subtitle: "Explore our cutting-edge technological solutions",
        features: [
          { icon: CpuChipIcon, title: "AI & ML", description: "Smart solutions powered by artificial intelligence" },
          { icon: CircleStackIcon, title: "Cloud Computing", description: "Scalable and secure infrastructure" },
          { icon: BoltIcon, title: "Digital Solutions", description: "Next-gen digital transformation" }
        ]
      }
    },
    {
      id: "clients",
      title: "Our Clients & Partners",
      description: "Building strong relationships and delivering value across industries",
      icon: UserGroupIcon,
      content: {
        title: "Our Clients & Partners",
        subtitle: "Success stories and partnerships that drive innovation",
        features: [
          { icon: GlobeAltIcon, title: "Global Network", description: "Worldwide partnerships and collaborations" },
          { icon: UserGroupIcon, title: "Industry Leaders", description: "Trusted by leading organizations" },
          { icon: RocketLaunchIcon, title: "Success Stories", description: "Proven track record of excellence" }
        ]
      }
    },
    {
      id: "strategies",
      title: "Our Strategies",
      description: "Strategic planning and execution for sustainable digital success",
      icon: ChartBarIcon,
      content: {
        title: "Our Strategies",
        subtitle: "Comprehensive approaches for digital transformation",
        features: [
          { icon: LightBulbIcon, title: "Digital Strategy", description: "Comprehensive digital transformation plans" },
          { icon: ChartBarIcon, title: "Growth Planning", description: "Scalable business solutions" },
          { icon: SparklesIcon, title: "Innovation Focus", description: "Future-ready solutions" }
        ]
      }
    }
  ];

  const handleOpenModal = (section) => {
    console.log("Opening modal for section:", section);
    setActiveModal(section);
    setIsModalActive(true);
  };

  const handleCloseModal = () => {
    console.log("Closing modal");
    setIsModalActive(false);
    setTimeout(() => setActiveModal(null), 300);
  };

  useEffect(() => {
    const handleScroll = () => {
      // Get the hero section height
      const heroSection = document.getElementById('hero');
      const heroHeight = heroSection?.offsetHeight || 0;
      
      // Show navigation only after scrolling past hero section
      setShowNav(window.scrollY > heroHeight - 100);

      // Get all sections
      const sections = [
        'gateway',
        'vision',
        'mission',
        'goals',
        'linear-section',
        'tech-success',
        'contact'
      ];

      // Find the current section
      let currentSection = sections[0];
      let minDistance = Infinity;

      sections.forEach((sectionId) => {
        const section = document.getElementById(sectionId);
        if (section) {
          const rect = section.getBoundingClientRect();
          const distance = Math.abs(rect.top);
          if (distance < minDistance) {
            minDistance = distance;
            currentSection = sectionId;
          }
        }
      });

      setActiveSection(currentSection);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // Comment out welcome popup effect
  /*
  useEffect(() => {
    // Only show popup if it hasn't been shown before
    if (!hasShownPopup) {
      const timer = setTimeout(() => {
        setShowWelcomePopup(true);
        setHasShownPopup(true);
      }, 1500);

      return () => clearTimeout(timer);
    }
  }, [hasShownPopup]);
  */

  return (
    <div className="home">
      {/* Comment out welcome popup component */}
      {/*
      <WelcomePopup 
        isOpen={showWelcomePopup} 
        onClose={() => setShowWelcomePopup(false)} 
      />
      */}
      
      {/* Hero Section */}
      <HeroSection />

      {/* Gateway Section */}
      <Gateway />

      {/* Vision Section */}
      <Vision />

      {/* Mission Section */}
      <Mission />

      {/* Goals Section */}
      <Goals />

      {/* Combined Section with Linear Cards */}
      <section id="linear-section" className="linear-section">
        <Container>
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
            transition={{ duration: 0.8 }}
          >
            {/* Header with Large Background Title */}
            <Box sx={{ textAlign: 'center', mb: 6, position: 'relative', py: 3 }}>
              <Typography
                variant="h1"
                sx={{
                  fontSize: { xs: '4rem', md: '8rem' },
                  fontWeight: 800,
                  color: 'rgba(255, 107, 0, 0.05)',
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  width: '100%',
                  letterSpacing: '0.2em',
                  textTransform: 'uppercase',
                  pointerEvents: 'none'
                }}
              >
                SERVICES
              </Typography>
              <Box sx={{ position: 'relative', zIndex: 1 }}>
                <Typography
                  variant="h2"
                  sx={{
                    fontSize: { xs: '1.8rem', md: '3.5rem' },
                    fontWeight: 800,
                    background: 'linear-gradient(135deg, #8B4513, #A0522D)',
                    WebkitBackgroundClip: 'text',
                    WebkitTextFillColor: 'transparent',
                    marginBottom: 2,
                    textTransform: 'uppercase',
                    letterSpacing: '0.1em',
                    position: 'relative',
                    '&::after': {
                      content: '""',
                      position: 'absolute',
                      bottom: '-10px',
                      left: '50%',
                      transform: 'translateX(-50%)',
                      width: '60px',
                      height: '3px',
                      background: '#8B4513'
                    }
                  }}
                >
                  Our Services & Solutions
                </Typography>
                <Typography
                  sx={{
                    fontSize: { xs: '0.95rem', md: '1.1rem' },
                    color: '#666',
                    maxWidth: '700px',
                    margin: '1.5rem auto 0',
                    lineHeight: { xs: 1.8, md: 1.6 },
                    padding: { xs: '0 1rem', md: 0 }
                  }}
                >
                  Comprehensive digital transformation solutions for your business
                </Typography>
              </Box>
            </Box>

            <div className="linear-cards">
              {sections.map((section, index) => (
                <motion.div
                  key={section.id}
                  initial={{ opacity: 0, x: -20 }}
                  whileInView={{ opacity: 1, x: 0 }}
                  viewport={{ once: true }}
                  transition={{ duration: 0.5, delay: index * 0.1 }}
                >
                  <div 
                    className="linear-card" 
                    onClick={() => {
                      console.log("Card clicked:", section); // Add this debug log
                      handleOpenModal(section);
                    }}
                    role="button"
                    tabIndex={0}
                    onKeyPress={(e) => {
                      if (e.key === 'Enter' || e.key === ' ') {
                        handleOpenModal(section);
                      }
                    }}
                  >
                    <div className="linear-card-content">
                      <h3 className="linear-card-title">{section.title}</h3>
                      <p className="linear-card-description">{section.description}</p>
                    </div>
                    <section.icon className="linear-card-icon" />
                  </div>
                </motion.div>
              ))}
            </div>
          </motion.div>
        </Container>
      </section>

      {/* Modal */}
      <Modal 
        section={activeModal}
        isActive={isModalActive}
        onClose={handleCloseModal}
      />

      {/* Combined Tech Stack & Success Stories Section */}
      <TechnologyAndSuccess />

      {/* Start Your Digital Journey Section */}
      <section id="contact" className="journey-section">
        <Container>
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
            transition={{ duration: 0.8 }}
          >
            {/* Header with Large Background Title */}
            <Box sx={{ textAlign: 'center', mb: 6, position: 'relative', py: 3 }}>
              <Typography
                variant="h1"
                sx={{
                  fontSize: { xs: '4rem', md: '8rem' },
                  fontWeight: 800,
                  color: 'rgba(139, 69, 19, 0.05)',
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  width: '100%',
                  letterSpacing: '0.2em',
                  textTransform: 'uppercase',
                  pointerEvents: 'none'
                }}
              >
                JOURNEY
              </Typography>
              <Box sx={{ position: 'relative', zIndex: 1 }}>
                <Typography
                  variant="h2"
                  sx={{
                    fontSize: { xs: '1.8rem', md: '3.5rem' },
                    fontWeight: 800,
                    background: 'linear-gradient(135deg, #8B4513, #A0522D)',
                    WebkitBackgroundClip: 'text',
                    WebkitTextFillColor: 'transparent',
                    marginBottom: 2,
                    textTransform: 'uppercase',
                    letterSpacing: '0.1em',
                    position: 'relative'
                  }}
                >
                  Start Your Journey
                </Typography>
                <Typography
                  sx={{
                    fontSize: { xs: '0.95rem', md: '1.1rem' },
                    color: '#666',
                    maxWidth: '700px',
                    margin: '1.5rem auto 0',
                    lineHeight: { xs: 1.8, md: 1.6 },
                    padding: { xs: '0 1rem', md: 0 }
                  }}
                >
                  Transform your business with our innovative solutions. Let's create something extraordinary together 
                  and drive your success in the digital age.
                </Typography>
              </Box>
            </Box>

            <motion.div 
              className="journey-cards"
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.8, delay: 0.4 }}
            >
              {/* Innovation Card */}
              <div className="journey-card">
                <div className="journey-card-content">
                  <LightBulbIcon className="journey-icon" />
                  <h3 className="journey-card-title">Innovative Solutions</h3>
                  <p className="journey-card-description">
                    Cutting-edge technology solutions tailored to your unique business needs and goals.
                  </p>
                </div>
              </div>

              {/* Growth Card */}
              <div className="journey-card">
                <div className="journey-card-content">
                  <ChartBarIcon className="journey-icon" />
                  <h3 className="journey-card-title">Sustainable Growth</h3>
                  <p className="journey-card-description">
                    Strategic approaches that ensure long-term success and continuous business evolution.
                  </p>
                </div>
              </div>

              {/* Excellence Card */}
              <div className="journey-card">
                <div className="journey-card-content">
                  <SparklesIcon className="journey-icon" />
                  <h3 className="journey-card-title">Digital Excellence</h3>
                  <p className="journey-card-description">
                    Exceptional quality and performance in every digital solution we deliver.
                  </p>
                </div>
              </div>
            </motion.div>

            <motion.div 
              className="journey-cta"
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.8, delay: 0.6 }}
            >
              <Link to="/contact" style={{ textDecoration: 'none' }}>
                <button className="journey-button">
                  Get Started Today
                  <ChevronRightIcon className="journey-button-icon" />
                </button>
              </Link>
            </motion.div>
          </motion.div>
        </Container>
      </section>

      {/* Navigation Dots */}
      <nav className={`nav-dots ${showNav ? 'visible' : ''}`}>
        {[
          { id: 'gateway', label: 'Gateway', icon: RocketLaunchIcon },
          { id: 'vision', label: 'Vision', icon: LightBulbIcon },
          { id: 'mission', label: 'Mission', icon: ChartBarIcon },
          { id: 'goals', label: 'Goals', icon: SparklesIcon },
          { id: 'linear-section', label: 'Services', icon: WrenchScrewdriverIcon },
          { id: 'tech-success', label: 'Technology & Success', icon: CpuChipIcon },
          { id: 'contact', label: 'Contact', icon: UserGroupIcon }
        ].map(({ id, label, icon: Icon }) => (
          <button
            key={id}
            onClick={() => {
              const element = document.getElementById(id);
              if (element) {
                element.scrollIntoView({ 
                  behavior: 'smooth',
                  block: 'start'
                });
              }
            }}
            className={`nav-dot ${activeSection === id ? 'active' : ''}`}
            aria-label={label}
          >
            <Icon />
          </button>
        ))}
      </nav>
    </div>
  );
};

export default Home;

