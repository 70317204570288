import "./template.css";

import { Link } from "react-router-dom";
import ReadModal2 from "./readMore.js";
import React, { useState, useRef, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CarouselSlider from "./Carousel.js";

// import "./cyberCard.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faArrowRight, faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";

// Custom arrow components
const NextArrow = ({ onClick }) => (
  <div className="slick-arrow slick-next custom" onClick={onClick}>
    <FontAwesomeIcon icon={faChevronRight} />
  </div>
);

const PrevArrow = ({ onClick }) => (
  <div className="slick-arrow slick-prev custom" onClick={onClick}>
    <FontAwesomeIcon icon={faChevronLeft} />
  </div>
);

function CyberSecurity1() {
  const [activeModal, setActiveModal] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const sliderRef = useRef(null);
  const modalRef = useRef(null);
  const [selectedSlide, setSelectedSlide] = useState(null);
  const [currentSlide, setCurrentSlide] = useState(0);

  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: false,
    cssEase: "linear",
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          dots: true,
        },
      }
    ],
  };

  //
  // {
  //   id: 6,
  //   heading: 'Sixth Slide',
  //   text: 'A different text for the second slide.',
  //   buttonLabel: 'Read More',
  //   modalContent: {
  //     modalHeading: 'Modal Heading 6',
  //     modalText: 'Modal Text 6',
  //   },
  // },

  // {
  //   id: 7,
  //   heading: 'Seventh Slide',
  //   text: 'A different text for the second slide.',
  //   buttonLabel: 'Read More',
  //   modalContent: {
  //     modalHeading: 'Modal Heading 7',
  //     modalText: 'Modal Text 7',
  //   },
  // },

  // {
  //   id: 8,
  //   heading: 'Eighth Slide',
  //   text: 'A different text for the second slide.',
  //   buttonLabel: 'Read More',
  //   modalContent: {
  //     modalHeading: 'Modal Heading 8',
  //     modalText: 'Modal Text 8',
  //   },
  // },
  // ... (more slides)

  const openModal = (slide) => {
    setSelectedSlide(slide);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  // Handle clicks outside the modal to close it
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        closeModal();
      }
    };

    window.addEventListener("mousedown", handleOutsideClick);

    return () => {
      window.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const modalContent = (
    <>
      <p className="modal-np">The Purpose Behind Our Cyber Security Service </p>
      Welcome to DigiTecHub, your trusted partner in cyber security audits and
      solutions. We offer a comprehensive range of services designed to assess,
      enhance, and protect your organization's digital ecosystem against cyber
      threats and vulnerabilities.
      <hr />
      <strong>Protection from digital attacks</strong>
 
      Cybersecurity aims to defend against various types of digital attacks, including malware infections, phishing attempts, denial-of-service attacks, and ransomware incidents. By implementing appropriate security measures, organizations can mitigate the risk of these attacks and protect their digital assets from unauthorized access or manipulation.
      
      <br/><br/>  <strong>Prevention of damage</strong>
        Cybersecurity measures are put in place to prevent damage to computer systems, networks, and data. This includes implementing firewalls, antivirus software, and intrusion detection systems to detect and block malicious activities before they cause harm. By proactively identifying and addressing vulnerabilities, organizations can minimize the potential impact of cyber threats.
       
          <br/><br/><strong>Protection against theft</strong>
          Cybersecurity helps protect digital assets from theft or unauthorized copying. This includes safeguarding sensitive data such as customer information, intellectual property, and financial records from being stolen or compromised. Encryption and data loss prevention techniques are often used to prevent unauthorized access to confidential information.
 
          {/* <br/><br/><strong>Platform Upgrades</strong>
          Keeping applications up-to-date with the latest technologies and standards improves features, security, and performance.
           */}
      {/* Your dynamic content here */}
      {/* You can pass any JSX content you want */}
    </>
  );

  return (
    <div id="maindivs">
      <div id="carouselExampleCaptions" className="carousel slide">
        <div className="carousel-inner">
          <div className="carousel-item active">
            <img
              src="/Images/cybersecuritybgimages.jpg"
              className="d-block w-100"
              style={{ height: "auto",opacity:"0.5" }}
              alt="slider first"
            />
            <div className="carousel-caption  d-md-block">
              <div className="component-1 text-dark">Cyber Security </div>

              <div
                className="component-paras"
                style={{ color: "black", marginTop: "-20px",fontStyle:"italic" ,fontWeight:"700"}}
              >
                "Safeguarding Your Digital Assets from Threats "
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-np2">
        {/* <!-- section2 title --> */}
        <div className="cont2-titles">
          {/* <div class="cont2-titletext">What is Cyber Security </div> */}
        </div>

        {/* <!-- section part --> */}
        <div className="container21">
          {/* <!-- left part --> */}

          <div className="cont2-left cont2-text">
            <div
              className="left-text"
              style={{
                // fontFamily: "arial",

                fontFamily: "system-ui",
              
              }}
            >
              Cybersecurity is the practice of protecting computer systems,
              networks, programs, and data from digital attacks, damage,
              unauthorized access, or theft. It encompasses a broad range of
              techniques, technologies, and processes designed to safeguard
              information and infrastructure in the digital realm.
            </div>
          </div>

          {/* right part */}
          <div className="cont2-right cont2-text">
            <div className="right-text">
              <img
                className="content2img"
                src="/Images/pattern-lock.gif"
                alt="Description of the image"
              />
            </div>
          </div>
        </div>
        <div className="readmore">
          <ReadModal2 buttonText="Read More" modalContent={modalContent} />
        </div>
      </div>

      {/* section3 */}
      <section className="section3">
        <div className="cont2-title title ">Our Offerings</div>

        <div className="section3parts">
          <div
            className="content-np item31"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end",
            }}
          >
            <div>
              <strong style={{ paddingLeft: "20px" }}>
                Application Level Security
              </strong>
              <div
                className="aligned-paragraph item3text"
                style={{ marginBottom: "30px" }}
              >
                Our experts conduct thorough assessments of your applications to
                identify vulnerabilities and weaknesses that hackers could
                exploit. We provide actionable recommendations to fortify your
                software against attacks.
              </div>
            </div>
          </div>

          <div className="content-np item322">
            <strong
              className="item32-heading item31-conent"
              style={{
                paddingLeft: "15px",
              }}
            >
              Network Security
            </strong>
            <div className="item31-conent-img"></div>
            <div
              className="aligned-paragraph item3text mt-2"
              style={{ color: "black" }}
            >
              We help secure your network infrastructure through comprehensive
              assessments, monitoring, and implementation of robust security
              measures.
            </div>
          </div>

          <div className="content-np item33">
            <strong
              className="item32-heading"
              style={{
                paddingLeft: "15px",
              }}
            >
              Cloud Security
            </strong>
            <div className="aligned-paragraph item3text">
              Our cloud security solutions ensure your data and applications in
              the cloud are protected against unauthorized access and cyber
              threats.
            </div>
          </div>

          <div className="content-np item34">
            <strong
              style={{
                paddingLeft: "15px",
              }}
            >
              Data Security
            </strong>
            <div className="aligned-paragraph item3text" style={{ color: "black" }}>
              We implement robust data protection measures to safeguard your
              sensitive information from breaches and unauthorized access.
            </div>
          </div>

          <div className="content-np item35">
            <strong
              style={{
                paddingLeft: "15px",
              }}
            >
              Security Audits
            </strong>
            <div className="aligned-paragraph item3text">
              Regular security audits to identify vulnerabilities and ensure
              compliance with industry standards and regulations.
            </div>
          </div>

          <div className="content-np item36">
            <strong
              style={{
                paddingLeft: "15px",
              }}
            >
              Incident Response
            </strong>
            <div className="aligned-paragraph item3text">
              Quick and effective response to security incidents, minimizing
              damage and restoring normal operations.
            </div>
          </div>

          <div className="content-np item37">
            <strong
              style={{
                paddingLeft: "15px",
              }}
            >
              Employee Training
            </strong>
            <div className="aligned-paragraph item3text" style={{ color: "black" }}>
              Comprehensive security awareness training for employees to create a
              security-conscious workforce.
            </div>
          </div>
        </div>
        {/* <div class="readmore">
            <button class="read1">Read More</button>
          </div> */}
      </section>

      <div className="section4">
        <div className="main-cont">
          <div className="Cardcomp">
            <div className="card-container">
              <div className="row-ca">
                <div className="col-ca">
                  <div className="card-head text-center">
                    <h2 className="head-t ">Why Choose Us</h2>
                    {/* <p className="t-color">
                    Delivering domain expertise and technology-driven offerings
                    to help you turn digital challenges into opportunities.
                  </p> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card-container">
            <div className="row-ck">
              <div className="col-ck">
                <div className="card-wrapper">
                  <div className="main-card">
                    <div className="innercon">
                      <div className="card-content1">
                        <h2>
                          Expertise <FontAwesomeIcon icon={faArrowRight} />
                        </h2>
                        {/* <p>vbhf</p> */}
                      </div>
                      <img src="/Images/organigram.gif" alt="" />
                    </div>
                  </div>
                  <div className="hover-card">
                    <div className="hover-content">
                      <h2>Expertise</h2>
                      <p>
                        Our certified cyber security professionals possess
                        extensive knowledge and experience in identifying and
                        mitigating security risks.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-ck">
                <div className="card-wrapper">
                  <div className="main-card">
                    <div className="innercon">
                      <div className="card-content1">
                        <h2>
                          Comprehensive Approach{" "}
                          <FontAwesomeIcon icon={faArrowRight} />{" "}
                        </h2>
                        {/* <p>vbhf</p> */}
                      </div>
                      <img src="/Images/approch.gif" alt="" />
                    </div>
                  </div>
                  <div className="hover-card">
                    <div className="hover-content">
                      <h2>Comprehensive Approach</h2>
                      <p>
                        We examine every layer of your digital infrastructure to
                        provide holistic security solutions.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-ck">
                <div className="card-wrapper">
                  <div className="main-card">
                    <div className="innercon">
                      <div className="card-content1">
                        <h2>
                          Cutting-Edge Tools{" "}
                          <FontAwesomeIcon icon={faArrowRight} />
                        </h2>
                        {/* <p>vbhf</p> */}
                      </div>
                      <img src="/Images/labor-day.gif" alt="" />
                    </div>
                  </div>
                  <div className="hover-card">
                    <div className="hover-content">
                      <h2>Cutting-Edge Tools</h2>
                      <p>
                        We leverage the latest security tools and methodologies
                        to ensure accurate assessments.{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-ck">
                <div className="card-wrapper">
                  <div className="main-card">
                    <div className="innercon">
                      <div className="card-content1">
                        <h2>
                          Custom Solutions{" "}
                          <FontAwesomeIcon icon={faArrowRight} />
                        </h2>
                        {/* <p>vbhf</p> */}
                      </div>
                      <img src="/Images/hand.gif" alt="" />
                    </div>
                  </div>
                  <div className="hover-card">
                    <div className="hover-content">
                      <h2>Custom Solutions</h2>
                      <p>
                        We tailor our recommendations to your organization's
                        specific needs and industry requirements.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-ck">
                <div className="card-wrapper">
                  <div className="main-card">
                    <div className="innercon">
                      <div className="card-content1">
                        <h2>
                          Regulatory Compliance
                          <FontAwesomeIcon icon={faArrowRight} />
                        </h2>
                        {/* <p>vbhf</p> */}
                      </div>
                      <img src="/Images/steps.gif" alt="" />
                    </div>
                  </div>
                  <div className="hover-card">
                    <div className="hover-content">
                      <h2>Regulatory Compliance</h2>
                      <p>
                        We help you meet industry standards and compliance
                        regulations related to data protection and cyber
                        security.{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-ck">
                <div className="card-wrapper">
                  <div className="main-card">
                    <div className="innercon">
                      <div className="card-content1">
                        <h2>
                          Continuous Monitoring{" "}
                          <FontAwesomeIcon icon={faArrowRight} />
                        </h2>
                        {/* <p>vbhf</p> */}
                      </div>
                      <img src="/Images/interact.gif" alt="" />
                    </div>
                  </div>
                  <div className="hover-card">
                    <div className="hover-content">
                      <h2>Continuous Monitoring</h2>
                      <p>
                        We offer continuous monitoring services to keep abreast
                        of evolving cyber threats and vulnerabilities
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              {/* <div className='col-ck'>
        <div className="card-wrapper">
            <div className="main-card">
            <div className="innercon">
                  <div className="card-content1">
                    <h2>cvgd <FontAwesomeIcon icon={faArrowRight} /></h2>
                   
                  </div>
                  <img src="/Images/cybercardico.svg" alt="" />
                </div>
            </div>
            <div className="hover-card">
                <div className="hover-content">
                <h2>bhhgjfx</h2>
                    <p>vndxhdmnhit bnxilhk cvbnrildh</p>
                   
                </div>
            </div>
        </div>
        </div>
        <div className='col-ck'>
        <div className="card-wrapper">
        <div className="main-card">
        <div className="innercon">
                  <div className="card-content1">
                    <h2>cvgd <FontAwesomeIcon icon={faArrowRight} /></h2>
                   
                  </div>
                  <img src="/Images/cybercardico.svg" alt="" />
                </div>
        </div>
        <div className="hover-card">
            <div className="hover-content">
            <h2>bhhgjfx</h2>
            <p>vndxhdmnhit bnxilhk cvbnrildh</p>
                   
            </div>
        </div>
    </div>
    </div> */}
              {/* <div className='col-ck'>
    <div className="card-wrapper">
        <div className="main-card">
        <div className="innercon">
                  <div className="card-content1">
                    <h2>cvgd <FontAwesomeIcon icon={faArrowRight} /></h2>
                   
                  </div>
                  <img src="/Images/cybercardico.svg" alt="" />
                </div>
        </div>
        <div className="hover-card">
            <div className="hover-content">
              <h2>bhhgjfx</h2>
                   
              <p>vndxhdmnhit bnxilhk cvbnrildh</p>
                   
            </div>
        </div>
    </div>
    </div> */}
            </div>
          </div>
        </div>

        {/* <CarouselSlider/> */}
      </div>

      {/* <div class="section5">
          <div class="section5heading">
            <div class="section5heading1">Our Case Studies</div>
         
          </div>
   
          <div class="section5cards">
            <div class="item51 item55">
              <div class="item5content">
                <div class="item51img">Expert Team</div>
   
                <div class="item51text item5text">
                  <a href="" class="full-width-link a-np">
                    Our team comprises experienced web designers, developers, and
                    UX/UI experts who excel at translating ideas into exceptional
                    websites
                  </a>
                </div>
              </div>
            </div>
   
            <div class="item52 item55">
              <div class="item5content">
                <div class="item52img">Scalability</div>
   
                <div class="item52text item5text">
                  <a href="" class="full-width-link a-np">
                    We build websites that are scalable and adaptable to
                    accommodate your business growth and changing requirements.
                  </a>
                </div>
              </div>
            </div>
   
            <div class="item53 item55">
              <div class="item5content">
                <div class="item53img">Responsive Design</div>
   
                <div class="item53text item5text">
                  <a href="" class="full-width-link a-np">
                    We ensure that your website looks and functions flawlessly on
                    desktops, tablets, and smartphones, providing a consistent
                    experience to users.
                  </a>
                </div>
              </div>
            </div>
   
            <div class="item54 item55">
              <div class="item5content">
                <div class="item54img">User-Centric Approach</div>
   
                <div class="item54text item5text">
                  <a href="" class="full-width-link a-np">
                    Our design and development process centers around the needs
                    and preferences of your target audience, ensuring a
                    user-focused website.
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div> */}

      {/* <div class="readmore">
      <button class="read">show More</button>
      </div> */}

      {/* section 6 */}

      <section className="section7">
        {/* Topic Section */}
        <div className="topic-section">
          <h1 className="main-heading">Industries We Serve</h1>
          <p className="main-description">
            Our cyber security audit services cater to a wide range of industries, ensuring comprehensive protection
          </p>
        </div>

        {/* Slider Section */}
        <div className="slider-section">
          <Slider {...settings} className="cards7np">
            {[
              {
                icon: "/Images/cybercardico.svg",
                title: "Finance and Banking",
                description: "Comprehensive security solutions for financial institutions, protecting sensitive transactions and customer data."
              },
              {
                icon: "/Images/cybercardico.svg",
                title: "Healthcare",
                description: "HIPAA-compliant security measures protecting patient data and medical records."
              },
              {
                icon: "/Images/cybercardico.svg",
                title: "E-commerce",
                description: "Secure payment processing and customer data protection for online retailers."
              },
              {
                icon: "/Images/cybercardico.svg",
                title: "Manufacturing",
                description: "Industrial cybersecurity protecting production systems and intellectual property."
              },
              {
                icon: "/Images/cybercardico.svg",
                title: "Technology",
                description: "Advanced security solutions for tech companies and digital service providers."
              },
              {
                icon: "/Images/cybercardico.svg",
                title: "Government",
                description: "High-level security protocols for government agencies and public services."
              }
            ].map((item, index) => (
              <div className="slider-card" key={index}>
                <div className="slider-card-content">
                  <div className="card-icon">
                    <img src={item.icon} alt={item.title} />
                  </div>
                  <h3>{item.title}</h3>
                  <p>{item.description}</p>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </section>
    </div>
  );
}

export default CyberSecurity1;
