import React from 'react';
import { Container, Typography, Box, Grid, Paper } from '@mui/material';
import { motion } from 'framer-motion';
import { ShieldCheckIcon, DocumentTextIcon, ChartBarIcon, ClipboardDocumentCheckIcon } from '@heroicons/react/24/outline';

const OperationalCompliance = () => {
  const complianceAreas = [
    {
      title: "Federal Law Adherence",
      description: "Strict compliance with federal mortgage and banking regulations",
      Icon: ShieldCheckIcon,
      points: [
        "Truth in Lending Act (TILA) compliance",
        "Real Estate Settlement Procedures Act (RESPA)",
        "Equal Credit Opportunity Act (ECOA)",
        "Fair Housing Act requirements"
      ]
    },
    {
      title: "Documentation Standards",
      description: "Comprehensive documentation and record-keeping practices",
      Icon: DocumentTextIcon,
      points: [
        "Standardized loan documentation",
        "Secure record retention",
        "Digital and physical file management",
        "Audit trail maintenance"
      ]
    },
    {
      title: "Regular Audits",
      description: "Systematic review and verification processes",
      Icon: ChartBarIcon,
      points: [
        "Internal compliance audits",
        "External third-party reviews",
        "Quality control checks",
        "Performance monitoring"
      ]
    },
    {
      title: "Risk Assessment",
      description: "Continuous evaluation and mitigation of operational risks",
      Icon: ClipboardDocumentCheckIcon,
      points: [
        "Risk identification protocols",
        "Mitigation strategies",
        "Compliance monitoring",
        "Regular risk reviews"
      ]
    }
  ];

  return (
    <Container maxWidth="lg" sx={{ 
      py: 8,
      mt: { xs: 8, md: 12 }
    }}>
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
      >
        <Typography variant="h2" sx={{ 
          mb: 2, 
          color: '#1A202C',
          fontWeight: 700,
          textAlign: 'center',
          fontSize: { xs: '2.5rem', md: '3.5rem' },
          background: 'linear-gradient(135deg, #8B4513, #D2691E)',
          WebkitBackgroundClip: 'text',
          WebkitTextFillColor: 'transparent'
        }}>
          Operational Compliance
        </Typography>
        
        <Typography variant="h4" sx={{ 
          mb: 4, 
          color: '#8B4513',
          fontWeight: 600,
          textAlign: 'center',
          fontSize: { xs: '1.2rem', md: '1.5rem' },
          textTransform: 'uppercase',
          letterSpacing: '0.1em'
        }}>
          Regulatory Excellence & Risk Management
        </Typography>
        
        <Typography variant="body1" sx={{ 
          mb: 8, 
          color: '#4A5568',
          textAlign: 'center',
          maxWidth: '800px',
          mx: 'auto',
          fontSize: { xs: '1rem', md: '1.1rem' },
          lineHeight: 1.8
        }}>
          Our comprehensive operational compliance framework ensures adherence to all regulatory requirements while maintaining efficient and secure mortgage operations.
        </Typography>

        <Grid container spacing={4}>
          {complianceAreas.map((area, index) => (
            <Grid item xs={12} md={6} key={index}>
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
              >
                <Paper elevation={0} sx={{
                  p: 4,
                  height: '100%',
                  borderRadius: '16px',
                  border: '1px solid rgba(139, 69, 19, 0.2)',
                  background: 'linear-gradient(135deg, rgba(139, 69, 19, 0.05), rgba(210, 105, 30, 0.05))',
                  transition: 'all 0.3s ease',
                  '&:hover': {
                    transform: 'translateY(-4px)',
                    boxShadow: '0 4px 20px rgba(139, 69, 19, 0.15)'
                  }
                }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
                    <area.Icon className="w-8 h-8" style={{ color: '#8B4513', marginRight: '12px' }} />
                    <Typography variant="h5" sx={{ color: '#8B4513', fontWeight: 600 }}>
                      {area.title}
                    </Typography>
                  </Box>
                  <Typography variant="body1" sx={{ color: '#4A5568', mb: 3 }}>
                    {area.description}
                  </Typography>
                  <Box sx={{ pl: 2 }}>
                    {area.points.map((point, idx) => (
                      <Typography 
                        key={idx} 
                        variant="body2" 
                        sx={{ 
                          color: '#4A5568',
                          mb: 2,
                          position: 'relative',
                          pl: 3,
                          '&::before': {
                            content: '"•"',
                            position: 'absolute',
                            left: 0,
                            color: '#8B4513',
                            fontWeight: 'bold'
                          }
                        }}
                      >
                        {point}
                      </Typography>
                    ))}
                  </Box>
                </Paper>
              </motion.div>
            </Grid>
          ))}
        </Grid>
      </motion.div>
    </Container>
  );
};

export default OperationalCompliance; 