import React from 'react';
import "./template.css";

function ERPservice() {
  return (
    <div id="maindivs">
      <div id="carouselExampleCaptions" className="carousel slide">
        <div className="carousel-inner">
          <div className="carousel-item active">
            <img
              src="/Images/erpservicesbg.jpg"
              className="d-block w-100"
              style={{ height: "auto", opacity: "0.35" }}
              alt="slider first"
            />
            <div className="carousel-caption d-md-block">
              <div className="component-1 text-dark">ERP Services</div>
              <div
                className="component-paras"
                style={{ color: "black", marginTop: "-20px", fontWeight: "700", fontStyle: 'Italic' }}
              >
                "Streamline Your Business Operations with Our Comprehensive ERP Solutions"
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-np2">
        <div className="cont2-titles"></div>
        <div className="container21">
          <div className="cont2-left cont2-text">
            <div
              className="left-text"
              style={{
                fontFamily: "system-ui",
              }}
            >
              Our ERP services provide comprehensive solutions for managing and integrating your core business processes. From implementation to customization and ongoing support, we help you optimize your operations, improve efficiency, and drive growth through effective ERP management.
            </div>
          </div>
          <div className="cont2-right cont2-text">
            <div className="right-text">
              <img
                className="content2img"
                src="/Images/erp-system.gif"
                alt="ERP Services"
              />
            </div>
          </div>
        </div>
      </div>

      <section className="section3">
        <div className="cont2-title title">Our ERP Services</div>
        <div className="section3parts">
          <div className="content-np item31">
            <strong style={{ paddingLeft: '15px' }}>Implementation & Integration</strong>
            <p className="aligned-paragraph item3text">
              Expert implementation of ERP systems with seamless integration into your existing infrastructure.
            </p>
          </div>

          <div className="content-np item34">
            <strong style={{ paddingLeft: '15px' }}>Customization & Configuration</strong>
            <p className="aligned-paragraph item3text" style={{ color: "black" }}>
              Tailored ERP solutions configured to match your specific business requirements and processes.
            </p>
          </div>

          <div className="content-np item35">
            <strong style={{ paddingLeft: '15px' }}>Training & Support</strong>
            <p className="aligned-paragraph item3text">
              Comprehensive training programs and ongoing support to ensure effective system utilization.
            </p>
          </div>

          <div className="content-np item36">
            <strong style={{ paddingLeft: '15px' }}>Maintenance & Updates</strong>
            <p className="aligned-paragraph item3text">
              Regular maintenance, updates, and optimization to keep your ERP system running efficiently.
            </p>
          </div>
        </div>
      </section>
    </div>
  );
}

export default ERPservice; 