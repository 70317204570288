import React from 'react';
import './CaseStudyCard.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

const CaseStudyCard1 = ({ title, description, linkUrl }) => {
  return (
    <div className="card">
      <div className="card-content">
        <h2 className='casestudyjsx-h2'>{title}</h2>
        <div className='casestudyjsx-p'>{description}</div>
      </div>
      <div className="overlay-content">
        <a href={linkUrl}>
          Case Study
          <FontAwesomeIcon icon={faArrowRight} />
        </a>
      </div>
    </div>
  );
}

export default CaseStudyCard1;
