import "./template.css";

import { Link } from "react-router-dom";
import ReadModal2 from "./readMore.js";
import React, { useState, useRef, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CarouselSlider from "./Carousel.js";
import CaseStudyCard from "./CaseStudyCard.js";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Accordion from "./Accordion";
import "./Accordion.css";

import {
  faArrowRight
} from '@fortawesome/free-solid-svg-icons';

function StafAugmentation() {
  const [activeModal, setActiveModal] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const sliderRef = useRef(null);
  const modalRef = useRef(null);
  const [selectedSlide, setSelectedSlide] = useState(null);
  const settings = {
    // dots: true,
    // infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },

      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },

      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 3000,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const slideData = [
    {
      id: 1,
      heading: "Flexibility and Scalability",
      text: "Tailoring workforce solutions that offer flexibility and scalability to meet your dynamic project needs.",
      buttonLabel: "Read More",
      modalContent: {
        // modalHeading: 'Modal Heading 1',
        modalText:
          "CloudCipher seamlessly integrates with existing workflows and software ecosystems, minimizing disruption and streamlining the adoption process. Whether migrating from another platform or implementing new security measures, CloudCipher ensures a smooth transition with minimal downtime.",
      },
    },
    {
      id: 2,
      heading: "High-Quality Talent Pool",
      text: "Access to a vast network of highly skilled professionals across various domains. ",
      buttonLabel: "Read More",
      modalContent: {
        modalHeading: "Modal Heading 2",
        modalText: "Modal Text 2",
      },
    },

    {
      id: 3,
      heading: "Cost-Effective Solutions",
      text: "Our Staff Augmentation services are designed to be cost-effective, reducing the burden of permanent hires",
      buttonLabel: "Read More",
      modalContent: {
        modalHeading: "Modal Heading 3",
        modalText: "Modal Text 3",
      },
    },

    {
      id: 4,
      heading: "Quick Turnaround Time",
      text: "Ability to quickly deploy the right talent, reducing your time-to-market for projects. ",
      buttonLabel: "Read More",
      modalContent: {
        modalHeading: "Modal Heading 4",
        modalText: "Modal Text 4",
      },
    },

    {
      id: 5,
      heading: "Dedicated Support",
      text: "Providing ongoing support to ensure smooth integration and performance of augmented staff. ",
      buttonLabel: "Read More",
      modalContent: {
        modalHeading: "Modal Heading 5",
        modalText: "Modal Text 5",
      },
    },

    // {
    //   id: 6,
    //   heading: 'Sixth Slide',
    //   text: 'A different text for the second slide.',
    //   buttonLabel: 'Read More',
    //   modalContent: {
    //     modalHeading: 'Modal Heading 6',
    //     modalText: 'Modal Text 6',
    //   },
    // },

    // {
    //   id: 7,
    //   heading: 'Seventh Slide',
    //   text: 'A different text for the second slide.',
    //   buttonLabel: 'Read More',
    //   modalContent: {
    //     modalHeading: 'Modal Heading 7',
    //     modalText: 'Modal Text 7',
    //   },
    // },

    // {
    //   id: 8,
    //   heading: 'Eighth Slide',
    //   text: 'A different text for the second slide.',
    //   buttonLabel: 'Read More',
    //   modalContent: {
    //     modalHeading: 'Modal Heading 8',
    //     modalText: 'Modal Text 8',
    //   },
    // },
    // ... (more slides)
  ];


  let items = [
    {
      title: "Q: How does Staff Augmentation differ from traditional hiring?  ",
      content:
        "A: Staff Augmentation offers more flexibility than traditional hiring, as it allows businesses to scale their workforce based on project requirements without long-term commitments.  ",
    },
    {
      title: "Q: Can we select the candidates ourselves? ",
      content:
        " A: Yes, you have the flexibility to interview and select candidates to ensure they fit your specific project requirements and company culture.  ",
    },
    {
      title: "Q: Are there any industries you specialize in for Staff Augmentation?  ",
      content:
        "A: We cater to a wide range of industries, with a strong focus on tech and IT-related sectors.   ",
    },
   
    
   
  ];

  const openModal = (slide) => {
    setSelectedSlide(slide);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  // Handle clicks outside the modal to close it
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        closeModal();
      }
    };

    window.addEventListener("mousedown", handleOutsideClick);

    return () => {
      window.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  // .................
  const modalContent = (
    <>
      <p className="modal-np">The Purpose Behind Our Staff Augmentation</p>
      Our Staff Augmentation service is designed to help your business swiftly
      adapt to changing project needs and skill requirements. By providing
      skilled professionals on an as-needed basis, we enable your organization
      to enhance its capabilities without the overheads and commitments
      associated with permanent hires.
      <hr />
      <br /> <strong>Flexibility and Scalability</strong>
      Staff augmentation allows organizations to scale their workforce up or
      down quickly in response to changing business needs, project demands, or
      market conditions. Instead of hiring full-time employees, companies can
      augment their staff with temporary resources as needed, reducing overhead
      costs and administrative burdens.
      <br />
      <br />
      <strong> Access to Specialized Skills</strong>
      Staff augmentation enables organizations to access specialized skills and
      expertise that may not be available in-house. Whether it's technical
      expertise in software development, industry-specific knowledge, or niche
      skills in areas like data analysis or cybersecurity, augmenting staff with
      external talent can help fill skill gaps and enhance project capabilities.
      <br />
      <br />
      <strong>Knowledge Transfer and Innovation</strong> Staff augmentation
      provides opportunities for knowledge transfer and innovation by bringing
      in external perspectives, best practices, and fresh ideas from experienced
      professionals. Collaborating with external talent can foster
      cross-pollination of ideas, promote learning and development, and drive
      innovation within the organization.
      <br />
      <br />
      {/* Your dynamic content here */}
      {/* You can pass any JSX content you want */}
    </>
  );

  return (
    <div id="maindivs">
      <div id="carouselExampleCaptions" className="carousel slide">
        <div className="carousel-inner">
          <div className="carousel-item active">
            <img
              src="/Images/staffAugumentationbgimage.jpg"
              className="d-block w-100"
              style={{ height: "auto",opacity:'0.44' }}
              alt="slider first"
            />
            <div className="carousel-caption  d-md-block">
              <div className="component-1 text-dark">Staff Augmentation</div>

              <div
                className="component-paras text-dark"
                style={{ color: "white", marginTop: "-20px",fontWeight:'700' }}
              >
                "Expanding Your Team's Capabilities – Flexibly and Efficiently"
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-np2">
        {/* <!-- section2 title --> */}
        <div className="cont2-titles">
          {/* <div class="cont2-titletext">What is Staff Augmentation?</div> */}
        </div>

        {/* <!-- section part --> */}
        <div className="container21">
          {/* <!-- left part --> */}

          <div className="cont2-left cont2-text">
            <div
              className="left-text"
              style={{
                // fontFamily: "arial",

                fontFamily: "system-ui",
              }}
            >
              Staff Augmentation is a flexible outsourcing strategy that enables you to hire tech talent globally and manage augmented teams directly. You can hire one or more team members through us for a specific period or project, depending on your needs. This service offers the agility to scale your workforce up or down as projects demand, with skilled professionals in FTE, C2C, and long-term contract arrangements. 
            </div>
          </div>

          {/* right part */}
          <div className="cont2-right cont2-text">
            <div className="right-text">
              <img
                className="content2img"
                src="/Images/staff-management.png"
                alt="Description of the image"
              />
            </div>
          </div>
        </div>
        <div className="readmore">
          <ReadModal2 buttonText="Read More" modalContent={modalContent} />
        </div>
      </div>

      {/* section3 */}
      <section className="section3">
        <div className="cont2-title title ">Our Features</div>

        <div className="section3parts">
          <div
            className="content-np item31"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end",
            }}
          >
            <div>
              <strong style={{ paddingLeft: "20px" }}>
                {" "}
                Full-Time Employee (FTE) Augmentation
              </strong>
              <p
                className="aligned-paragraph item3text"
                style={{ marginBottom: "30px" }}
              >
                Providing dedicated professionals who can seamlessly integrate
                with your in-house team for ongoing projects.
              </p>
            </div>
          </div>

          <div className="content-np item322">
            <strong
              className="item32-heading item31-conent"
              style={{
                fontSize:"18px",
                paddingLeft: "15px",
                color: "black",
              }}
            >
              Rapid Mobilization
            </strong>
            <div className=" item31-conent-img  "></div>
            <p
              className="aligned-paragraph item3text mt-1"
              style={{ color: "black" }}
            >
              Quick turnaround in deploying professionals to meet your urgent
              project needs.
            </p>
          </div>

          <div className="content-np item33">
            <strong
              className=""
              style={{
                paddingLeft: "15px",
              }}
            >
              {" "}
              Long-Term Contract Professionals
            </strong>
            <p
              className="aligned-paragraph item3text"
              style={{
                paddingLeft: "15px",
              }}
            >
              Offering experienced professionals for long-term contracts to
              support extended project cycles and initiatives.
            </p>
            {/* <div class="item32-button"><button class="item32-button">Read more</button></div> */}
          </div>

          <div className="content-np item34">
            <strong
              style={{
                paddingLeft: "15px",
              }}
            >
              Wide Range of Skill sets
            </strong>
            <p className="aligned-paragraph item3text" style={{ color: "black" }}>
              Collaborative document editing. Organization-level chat for
              seamless internal communication. Personal and group calendars,
              contacts management, and task tracking.
            </p>
          </div>
          <div className="content-np item35">
            <strong
              style={{
                paddingLeft: "15px",
              }}
            >
              {" "}
              Corp-to-Corp (C2C) Solutions
            </strong>
            <p className="aligned-paragraph item3text">
              Flexible C2C arrangements that allow you to augment your workforce
              for specific projects or timeframes.
            </p>
          </div>

          <div className="content-np item36">
            <strong
              style={{
                paddingLeft: "15px",
              }}
            >
              Communication Channels
            </strong>
            <p className="aligned-paragraph item3text">
              Integrated messaging systems, video conferencing tools, and
              collaboration platforms to facilitate real-time communication
              between internal teams and augmented staff.
            </p>
          </div>

          <div className="content-np item37">
            <strong
              style={{
                paddingLeft: "15px",
              }}
            >
              Performance Evaluation
            </strong>
            <p className="aligned-paragraph item3text" style={{ color: "black" }}>
              Mechanisms for assessing the performance of augmented staff,
              providing feedback, and identifying areas for improvement.
            </p>
          </div>
        </div>
        {/* <div class="readmore">
          <button class="read1">Read More</button>
        </div> */}
      </section>

      <div className="section4">
        {/* <div className="sliders"> */}
          {/* Previous and Next buttons */}
          {/* <button
            className="nav-button-slider prev"
            onClick={() => sliderRef.current?.slickPrev()}
          >
            &lt;
          </button>
          <button
            className="nav-button-slider next"
            onClick={() => sliderRef.current?.slickNext()}
          >
            &gt;
          </button> */}
          {/* Modal */}
          {/* {isModalOpen && selectedSlide && (
<div className="modal-overlay" ref={modalRef}>
  <div className="modal-content-m" style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
   
    <div id="close-m" onClick={closeModal}>
      <i className="fas fa-times closeicon"></i>
    </div>
    <h2>{selectedSlide. modalContent.modalHeading}</h2>
    <p>{selectedSlide. modalContent.modalText}</p>
  </div>
</div>
)} */}

          {/* <div className="slidercontainer">
            <Slider ref={sliderRef} {...settings}>
              {slideData.map((slide) => (
                <div key={slide.id} className="slider-item">
                  <div className="slidecontents">
                    <h2 className="slideheading h2-np slidecontent">
                      {slide.heading}
                    </h2>
                    <p className="slidetext p-np slidecontent">{slide.text}</p> */}
                    {/* <button
                      className="slidebutton read1"
                      onClick={() => openModal(slide)}
                    >
                      {slide.buttonLabel}
                    </button> */}
                  {/* </div>
                </div>
              ))}
            </Slider>
          </div>
        </div> */}

<div className="main-cont">
        <div className="Cardcomp">
          <div className="card-container">
            <div className="row-ca">
              <div className="col-ca">
                <div className="card-head text-center">
                  <h2 className="head-t ">USP of Staff Augmentation Services</h2>
                  {/* <p className="t-color">
                    Delivering domain expertise and technology-driven offerings
                    to help you turn digital challenges into opportunities.
                  </p> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='card-container'>
        <div className='row-ck'>
            <div className='col-ck'>
        <div className="card-wrapper">
            <div className="main-card">
            <div className="innercon">
                  <div className="card-content1" >
                    <h2>Flexibility and Scalability <FontAwesomeIcon icon={faArrowRight} /></h2>
                    {/* <p>vbhf</p> */}
                  </div>
                  <img src="/Images/scalability.gif" alt="" />
                </div>
            </div>
            <div className="hover-card">
                <div className="hover-content">
                    <h2>Flexibility and Scalability</h2>
                    <p> Tailoring workforce solutions that offer flexibility and scalability to meet your dynamic project needs. </p>
                    
                </div>
            </div>
        </div>
        </div>
        <div className='col-ck'>
        <div className="card-wrapper">
        <div className="main-card">
        <div className="innercon">
                  <div className="card-content1">
                    <h2>High-Quality Talent Pool <FontAwesomeIcon icon={faArrowRight} /> </h2>
                    {/* <p>vbhf</p> */}
                  </div>
                  <img src="/Images/artificial-intelligence-sa.gif" alt="" />
                </div>
        </div>
        <div className="hover-card">
            <div className="hover-content">
                  <h2>High-Quality Talent Pool</h2>
                    <p>Access to a vast network of highly skilled professionals across various domains.  </p>
                    
            </div>
        </div>
    </div>
    </div>
    <div className='col-ck'>
    <div className="card-wrapper">
        <div className="main-card">
        <div className="innercon">
                  <div className="card-content1">
                    <h2>Cost-Effective Solutions <FontAwesomeIcon icon={faArrowRight} /></h2>
                    {/* <p>vbhf</p> */}
                  </div>
                  <img src="/Images/hand.gif" alt="" />
                </div>
        </div>
        <div className="hover-card">
            <div className="hover-content">
                  <h2>Cost-Effective Solutions</h2>
                    <p>Our Staff Augmentation services are designed to be cost-effective, reducing the burden of permanent hires.  </p>
                    
            </div>
        </div>
    </div>
    </div>
    
    
        
            <div className='col-ck'>
        <div className="card-wrapper">
            <div className="main-card">
            <div className="innercon">
                  <div className="card-content1">
                    <h2>Quick Turnaround Time <FontAwesomeIcon icon={faArrowRight} /></h2>
                    {/* <p>vbhf</p> */}
                  </div>
                  <img src="/Images/hourglass.gif" alt="" />
                </div>
            </div>
            <div className="hover-card">
                <div className="hover-content">
                <h2>Quick Turnaround Time</h2>
                    <p>Ability to quickly deploy the right talent, reducing your time-to-market for projects. </p>
                    
                </div>
            </div>
        </div>
        </div>
        <div className='col-ck'>
        <div className="card-wrapper">
        <div className="main-card">
        <div className="innercon">
                  <div className="card-content1">
                    <h2>Dedicated Support<FontAwesomeIcon icon={faArrowRight} /></h2>
                    {/* <p>vbhf</p> */}
                  </div>
                  <img src="/Images/social-care.gif" alt="" />
                </div>
        </div>
        <div className="hover-card">
            <div className="hover-content">
            <h2>Dedicated Support</h2>
                    <p>Providing ongoing support to ensure smooth integration and performance of augmented staff.  </p>
                    
            </div>
        </div>
    </div>
    </div>
    <div className='col-ck'>
    <div className="card-wrapper">
        <div className="main-card">
        <div className="innercon">
                  <div className="card-content1">
                    <h2>Seamless Integration and Collaboration<FontAwesomeIcon icon={faArrowRight} /></h2>
                    {/* <p>vbhf</p> */}
                  </div>
                  <img src="/Images/copywriting.gif" alt="" />
                </div>
        </div>
        <div className="hover-card">
            <div className="hover-content">
            <h2>Seamless Integration and Collaboration </h2>
                    <p>Tailored talent integration for seamless collaboration and accelerated project success.</p>
                    
            </div>
        </div>
    </div>
    </div>
  
    
        
            {/* <div className='col-ck'>
        <div className="card-wrapper">
            <div className="main-card">
            <div className="innercon">
                  <div className="card-content1">
                    <h2>cvgd <FontAwesomeIcon icon={faArrowRight} /></h2>
                    
                  </div>
                  <img src="/Images/cybercardico.svg" alt="" />
                </div>
            </div>
            <div className="hover-card">
                <div className="hover-content">
                <h2>bhhgjfx</h2>
                    <p>vndxhdmnhit bnxilhk cvbnrildh</p>
                    
                </div>
            </div>
        </div>
        </div>
        <div className='col-ck'>
        <div className="card-wrapper">
        <div className="main-card">
        <div className="innercon">
                  <div className="card-content1">
                    <h2>cvgd <FontAwesomeIcon icon={faArrowRight} /></h2>
                    
                  </div>
                  <img src="/Images/cybercardico.svg" alt="" />
                </div>
        </div>
        <div className="hover-card">
            <div className="hover-content">
            <h2>bhhgjfx</h2>
            <p>vndxhdmnhit bnxilhk cvbnrildh</p>
                    
            </div>
        </div>
    </div>
    </div> */}
    {/* <div className='col-ck'>
    <div className="card-wrapper">
        <div className="main-card">
        <div className="innercon">
                  <div className="card-content1">
                    <h2>cvgd <FontAwesomeIcon icon={faArrowRight} /></h2>
                    
                  </div>
                  <img src="/Images/cybercardico.svg" alt="" />
                </div>
        </div>
        <div className="hover-card">
            <div className="hover-content">
              <h2>bhhgjfx</h2>
                   
              <p>vndxhdmnhit bnxilhk cvbnrildh</p>
                    
            </div>
        </div>
    </div>
    </div> */}
    </div>
    </div>
    </div>

        {/* <CarouselSlider/> */}
      </div>

      <div className="section5">
        <h1 className="section5heading">How We are Handling</h1>
        <div className="section5jsxcard">
          <CaseStudyCard
            imageUrl="/Images/casestudyimg1.jpg"
            title="Requirement Analysis"
            description="Understanding your specific needs, including skill requirements and project duration. "
            linkUrl="#"
          />
          <CaseStudyCard
            imageUrl="/Images/casestudyimg2.jpg"
            title="Talent Sourcing and Screening"
            description="Identifying the best candidates through a rigorous sourcing and screening process."
            linkUrl=""
          />
          <CaseStudyCard
            imageUrl="/Images/casestudyimg3.jpg"
            title="Seamless Integration"
            description="Facilitating smooth onboarding and integration of staff into your existing teams. "
            linkUrl=""
          />

          <CaseStudyCard
            imageUrl="/Images/casestudyimg4.jpg"
            title="Ongoing Management and Support"
            description="Providing continuous support and management to ensure optimal performance and satisfaction. "
            linkUrl=""
          />

          {/* <div class="readmore">
  <button class="read">show More</button>
  </div> */}

          {/* section 6 */}
        </div>
      </div>
      {/* <div class="readmore">
  <button class="read">show More</button>
  </div> */}

      {/* section 6 */}

      <section className="FAQ">
        <div className="FAQbody">
          <h1 className="section5heading text-dark" >FAQ's for this service</h1>
          <Accordion items={items} />
        </div>
      </section>

    </div>
  );
}

export default StafAugmentation;
