import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./Navbar.css";
import SideHoverModal from "./SideHoverModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Dropdownmenu5 from "./Dropdownmenu5";
import DropdownMenu from "./DropdownMenu";
import DropdownMenu2 from "./DropdownMenu2";
import "./DropdownMenu.css"; // Import the CSS file for styling

import {
  faYoutube,
  faFacebook,
  faTwitter,
  faInstagram,
  faLinkedin,
  faGoogle, faWhatsapp
} from "@fortawesome/free-brands-svg-icons";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import DropdownMenu3 from "./DropdownMenu3";
import DropdownMenu4 from "./DropdownMenu4";


export default function NavBar() {
  // Group all state declarations together at the top
  const [activeSection, setActiveSection] = useState("default-section");
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [sideHoverModal, setSideHoverModal] = useState(false);
  const [modalPage, setModalPage] = useState("business");
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth); // Move this up with other state declarations

  // Combine scroll and resize handlers into one useEffect
  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 0);
    };

    const handleResize = () => {
      setWindowWidth(window.innerWidth);
      
      // Handle mobile sidebar logic here
      const sidebar = document.getElementById("sidebar");
      const sideModal = document.getElementById("hover-modal");

      if (window.innerWidth < 500) {
        if (sidebar) {
          sidebar.classList.add("close");
        }
        if (sideModal) {
          sideModal.style.width = "100%";
        }
      } else if (sidebar) {
        sidebar.classList.remove("close");
      }
    };

    // Add event listeners
    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleResize);
    
    // Initial check for mobile view
    handleResize();

    // Cleanup
    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleResize);
    };
  }, []); // Empty dependency array since we don't need any dependencies

  // Remove duplicate scroll handlers and window width effects

  // Remove the standalone document.addEventListener calls and replace with useEffect

  // Add WhatsApp event listeners using useEffect
  useEffect(() => {
    const whatsappIcon = document.querySelector(".whatsapp.social");
    const whatsappPopup = document.getElementById("whatsapp-popup");

    if (whatsappIcon && whatsappPopup) {
      const handleClick = () => whatsappPopup.classList.toggle("show");
      const handleMouseEnter = () => whatsappPopup.classList.add("show");
      const handleMouseLeave = () => whatsappPopup.classList.remove("show");

      whatsappIcon.addEventListener("click", handleClick);
      whatsappIcon.addEventListener("mouseenter", handleMouseEnter);
      whatsappIcon.addEventListener("mouseleave", handleMouseLeave);

      // Cleanup
      return () => {
        whatsappIcon.removeEventListener("click", handleClick);
        whatsappIcon.removeEventListener("mouseenter", handleMouseEnter);
        whatsappIcon.removeEventListener("mouseleave", handleMouseLeave);
      };
    }
  }, []); // Empty dependency array means this runs once after mount

  // Add scroll line event listener using useEffect
  useEffect(() => {
    const line = document.getElementById("scrollLine");
    
    if (line) {
      const handleScroll = () => {
        if (window.scrollY > 0) {
          line.classList.add("scrolled");
        } else {
          line.classList.remove("scrolled");
        }
      };

      window.addEventListener("scroll", handleScroll);

      // Cleanup
      return () => window.removeEventListener("scroll", handleScroll);
    }
  }, []); // Empty dependency array means this runs once after mount

  function scrollToSection(sectionId) {
    // Implementation of scrollToSection function, e.g., scroll to the specified section
  }

  function showDropdown() {
    const dropdown = document.getElementById("dropdownMenu");
    if (dropdown) {
      dropdown.classList.add("active");
    }
  }

  function hideDropdown() {
    const dropdown = document.getElementById("dropdownMenu");
    if (dropdown) {
      dropdown.classList.remove("active");
    }
  }

  const handleToggleButtonClick = () => {
    setSideHoverModal(false);
    setSidebarOpen(!sidebarOpen);
  };

  const toggleDropdown = (dropdownName) => {
    setActiveDropdown(activeDropdown === dropdownName ? null : dropdownName);
  };

  return (
    <div
      id="navbar-container"
      className={`${isScrolled ? "nav-background" : ""} container-fluid`}
    >
      <nav className="navbar navbar-expand-lg">
        <Link to="/">
          <img
            src="/Images/Digitechub_SVG_file.svg"
            id="myImage"
            width="170"
            height="100"
            onClick={() => window.scrollTo(0, 0)}
            className="navbar-logo"
          />
        </Link>

        {/* Hamburger Menu Button */}
        <div className="mobile-menu">
          <button 
            className={`hamburger ${sidebarOpen ? 'open' : ''}`} 
            onClick={() => setSidebarOpen(!sidebarOpen)}
            aria-label="Toggle menu"
          >
            <span className="ver-line"></span>
            <span className="ver-line"></span>
            <span className="ver-line"></span>
          </button>
        </div>

        <div
          className={`nav-data`}
          data-aos="fade-out"
          id="navbarSupportedContent"
        >
          <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <div className="menu-links">
                <DropdownMenu />
              </div>
            </li>

            <li className="nav-item">
              <div className="menu-links">
                <DropdownMenu2 />
              </div>
            </li>

            <li className="nav-item">
              <div className="menu-links">
                <DropdownMenu3 />
              </div>
            </li>
            <li className="nav-item">
              <div className="menu-links">
                <Dropdownmenu5 />
              </div>
            </li>

            {/* About Us link temporarily hidden
            <li className="nav-item">
              <Link to="/about-us" className="menu-links">
                About Us
              </Link>
            </li>
            */}

            <li className="nav-item">
              <Link to="/blog" className="menu-links">
                Blogs
              </Link>
            </li>
          </ul>
        </div>
      </nav>

      {/* Mobile Sidebar */}
      <div className={`sidebar ${sidebarOpen ? 'open' : ''}`}>
        <button 
          onClick={() => setSidebarOpen(false)}
          style={{
            position: 'absolute',
            top: '15px',
            right: '15px',
            background: 'none',
            border: 'none',
            cursor: 'pointer',
            padding: '10px',
            color: '#333',
            zIndex: 1000
          }}
          aria-label="Close menu"
        >
          <FontAwesomeIcon icon={faTimes} size="lg" />
        </button>
        <div className="sidebar-content">
          <Link to="/" onClick={() => setSidebarOpen(false)}>
            <img
              src="/Images/Digitechub_SVG_file.svg"
              alt="DigiTechHub"
              className="sidebar-logo"
              style={{ height: '40px', marginBottom: '2rem' }}
            />
          </Link>
          
          <div className="sidebar-links">
            <Link to="/" className="menu-links" onClick={() => setSidebarOpen(false)}>
              Home
            </Link>
            
            <div 
              className={`menu-links has-dropdown ${activeDropdown === 'platforms' ? 'active' : ''}`}
              onClick={() => toggleDropdown('platforms')}
            >
              Platforms
            </div>
            <div className={`sidebar-dropdown ${activeDropdown === 'platforms' ? 'active' : ''}`}>
              <Link to="/platfom/e-cart" onClick={() => setSidebarOpen(false)}>E-Cart</Link>
              <Link to="/platforms/OnlineTutor" onClick={() => setSidebarOpen(false)}>Online Tutor</Link>
              <Link to="/platform/cloudecipher" onClick={() => setSidebarOpen(false)}>Cloudcipher</Link>
              <Link to="/platforms/OnlineAnalytics" onClick={() => setSidebarOpen(false)}>Online Analytics</Link>
              <Link to="/platform/ISPMonitoring" onClick={() => setSidebarOpen(false)}>ISP Monitoring</Link>
              <Link to="/platforms/Smartvision" onClick={() => setSidebarOpen(false)}>AI Smart Vision</Link>
            </div>

            <div 
              className={`menu-links has-dropdown ${activeDropdown === 'services' ? 'active' : ''}`}
              onClick={() => toggleDropdown('services')}
            >
              Services
            </div>
            <div className={`sidebar-dropdown ${activeDropdown === 'services' ? 'active' : ''}`}>
              <Link to="/service/StafAugmentation" onClick={() => setSidebarOpen(false)}>Staff Augmentation</Link>
              <Link to="/service/IOTServices" onClick={() => setSidebarOpen(false)}>IOT</Link>
              <Link to="/service/ManagedServices" onClick={() => setSidebarOpen(false)}>Managed Services</Link>
              <Link to="/service/AI" onClick={() => setSidebarOpen(false)}>Artificial Intelligence</Link>
              <Link to="/service/blockchain" onClick={() => setSidebarOpen(false)}>Blockchain</Link>
              <Link to="/service/CyberSecurity1" onClick={() => setSidebarOpen(false)}>Cyber Security</Link>
              <Link to="/service/mortgages" onClick={() => setSidebarOpen(false)}>Mortgages</Link>
            </div>

            <div 
              className={`menu-links has-dropdown ${activeDropdown === 'solutions' ? 'active' : ''}`}
              onClick={() => toggleDropdown('solutions')}
            >
              Solutions
            </div>
            <div className={`sidebar-dropdown ${activeDropdown === 'solutions' ? 'active' : ''}`}>
              <Link to="/solution/DroneSolution" onClick={() => setSidebarOpen(false)}>Drone Solutions</Link>
              <Link to="/solution/SuplyCL" onClick={() => setSidebarOpen(false)}>Supplychain & Logistics</Link>
              <Link to="/solution/FinancialS" onClick={() => setSidebarOpen(false)}>Financial Services</Link>
              <Link to="/solution/HealthcareAndPharma" onClick={() => setSidebarOpen(false)}>Healthcare and Pharma</Link>
              <Link to="/solution/E-commerce" onClick={() => setSidebarOpen(false)}>E commerce</Link>
              <Link to="/solution/Automative" onClick={() => setSidebarOpen(false)}>Automotive</Link>
            </div>

            <div 
              className={`menu-links has-dropdown ${activeDropdown === 'case-studies' ? 'active' : ''}`}
              onClick={() => toggleDropdown('case-studies')}
            >
              Innovations
            </div>
            <div className={`sidebar-dropdown ${activeDropdown === 'case-studies' ? 'active' : ''}`}>
              <Link to="/platform/E-cart/casestudt-bharatmake" onClick={() => setSidebarOpen(false)}>BharatMake.com</Link>
              <Link to="/platform/E-cart/casestudy-digitalkeystore" onClick={() => setSidebarOpen(false)}>Digital Key Store</Link>
              <Link to="/platform/E-cart/casestudy-vitualwallet" onClick={() => setSidebarOpen(false)}>Virtual Wallet</Link>
              <Link to="/platform/cloudecipher/casestudy-Empowering-taxcollection" onClick={() => setSidebarOpen(false)}>Tax Collection Department</Link>
              <Link to="/plaform/cloudcipher/casestudy-Enhancing-Data-Integrity" onClick={() => setSidebarOpen(false)}>Integrity in PharmaCo's R&D Division</Link>
              <Link to="/platform/cloudcipher/casestudy-Securing-Operations-Bank" onClick={() => setSidebarOpen(false)}>Bank Security</Link>
            </div>

            {/* About Us link temporarily hidden
            <Link to="/about-us" className="menu-links" onClick={() => setSidebarOpen(false)}>
              About Us
            </Link>
            */}
            
            <Link to="/blog" className="menu-links" onClick={() => setSidebarOpen(false)}>
              Blogs
            </Link>
          </div>
          
          <Link to="/Contact" className="menu-links" onClick={() => setSidebarOpen(false)}>
            Contact Us
          </Link>
        </div>
      </div>

      {/* Social Media Bar - Only visible on desktop */}
      <div className="social-media-bar">
        <a href="https://www.youtube.com/@Digitec-hub" className="youtube social" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faYoutube} size="1x" />
        </a>
        <a href="https://www.instagram.com/digitechubinc/" className="instagram social" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faInstagram} size="1x" />
        </a>
        <a href="https://www.twitter.com" className="twitter social" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faTwitter} size="1x" />
        </a>
        <a href="https://www.facebook.com/digitechubinc" className="facebook social" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faFacebook} size="1x" />
        </a>
        <a href="https://www.linkedin.com/company/digitechub-inc/?viewAsMember=true" className="Linkedin social" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faLinkedin} size="1x" />
        </a>
        <a href="https://www.google.com" className="google social" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faGoogle} size="1x" />
        </a>
        <a href="https://api.whatsapp.com/send?phone=+919999999999" className="whatsapp social" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faWhatsapp} size="1x" />
        </a>
      </div>

      {sideHoverModal && (
        <SideHoverModal
          handleClick={() => {
            setSideHoverModal(!sideHoverModal);
            setSidebarOpen(false);
          }}
          page={modalPage}
        />
      )}
    </div>
  );
}