import React from "react";

import  "./terms.css";




export default function VM() {

    return (

        <>
         
       {/* <div><NavBar/></div>  */}

   

        <div className="banner parbase industries-gradient aem-GridColumn aem-GridColumn--default--12" >





        <section id="sml_ht_home_banner" >

            <article className="container">

            <div className="row">

              <div className="col-md-9 col-sm-12 col-xs-12 ">

                    <h1 className="h2-heading white-color l-top" id="termsedit1s">
                    
                      Terms of Use

                    </h1>

                  </div>

            </div>

          </article>

        </section>

       

</div>
<section class="privacy"  >
    <article class="container">
      <div class="row row-margin">
        
         <div  class="col-md-12 col-sm12 col-xs-12 wow fadeInLeft" data-wow-delay="0.3s" >
          <h3 className="termsheading">Terms of Use for www.Digitechub.com</h3> 
        <div className="termspara"> 
         <div className="term">The use of any product, service or feature (the "Materials") available through the internet websites accessible at Digitechub.com (collectively, the "Website") by any user of the Website ("You" or "Your" hereafter) shall be governed by the following terms of use:
           
           <ul id="terms-list">
            <li>This Website, including all Materials present (excluding any applicable third party materials), is the property of Digitechub and is copyrighted and protected by worldwide copyright laws and treaty provisions. You hereby agree to comply with all copyright laws worldwide in Your use of this Website and to prevent any unauthorized copying of the Materials. Digitechub does not grant any express or implied rights under any patents, trademarks, copyrights or trade secret information.</li>
            
            <li>Digitechub has business relationships with thousands of customers, suppliers, governments, and others. For convenience and simplicity, words like joint venture, partnership, and partner are used to indicate business relationships involving common activities and interests, and those words may not indicate precise legal relationships.</li>
           
           </ul>
          

          <div className="termsheadings">
          Limited License:
          </div>
          <div className="termsubpoint">Subject to the terms and conditions set forth in these Terms of Use, Digitechub grants You a non-exclusive, non-transferable, limited right to access, use and display this Website and the Materials thereon. You agree not to interrupt or attempt to interrupt the operation of the Website in any manner. Unless otherwise specified, the Website is for Your personal and non-commercial use. You shall not modify, copy, distribute, transmit, display, perform, reproduce, publish, license, create derivative works from, transfer, or sell any information, software, products or services obtained from this Website.</div>
        
          <div className="termsheadings">
          Blogs:
          </div>
          <div className="termsubpoint">This is to inform and clarify that individuals (including but not limited to employees of Digitechub and referred to as “Individuals” hereafter) may contribute articles and opinions on this Website entirely at the sole discretion of Digitechub, in the form of “blogs”, as such term is generally understood. You hereby acknowledge and agree that these blogs constitute the opinion of the Individuals in their personal capacity, and may not represent official positions of Digitechub in any manner. Digitechub retains all copyright to these blogs.</div>
          <div className="termsubpoint"><br/>You may be permitted to post comments and feedback to these blogs. By doing so, You expressly agree and acknowledge to abide by the following:</div>
          <ul id="terms-list">
    <li>You shall not defame, abuse, harass or threaten Digitechub or any third party, or otherwise violate the legal rights of Digitechub or any third party.</li>
    <li>You shall not contribute any content or take any action that may in any manner adversely affect the reputation of Digitechub, or that is otherwise detrimental to Digitechub.</li>
    <li>You shall not in any manner publish or post any inappropriate, defamatory, infringing, obscene, racist, terrorist, politically slanted, indecent or unlawful topic, name, material or information.</li>
    <li>You shall not use profane and objectionable language or abbreviations. You shall not use any character(s) as a substitute for objectionable language.</li>
    <li>You shall not in any manner reveal confidential or proprietary information of any third party. Specifically, You shall not post any material for which You do not have requisite and applicable right(s) under law.</li>
    <li>You shall not conduct any contests or publish or propagate any forwards.</li>
    <li>You shall not defame, abuse, harass, stalk, threaten or otherwise violate the legal rights (such as rights of privacy and publicity but not limited to the foregoing) of any other party including Digitechub.</li>
    <li>You shall not publish, post, upload, distribute or disseminate any inappropriate, profane, defamatory, obscene, indecent or unlawful topic, name, material or information.</li>
    <li>You shall not upload or otherwise make available, files that contain images, photographs, software or other material protected by intellectual property laws, including, by way of example, and not as limitation, copyright or trademark laws (or by rights of privacy or publicity) unless You own or control the rights thereto or have received all necessary consent to do the same.</li>
    <li>You shall not upload files that contain viruses, trojan horses, worms, time bombs, cancelbots, corrupted files, or any other similar software or programs that may damage the operation of another's computer or property of another.</li>
    <li>You shall not advertise or offer to sell or buy any goods or services for any business purpose.</li>
</ul>

        

          <div className="termsheadings">
         Third Party Content:
          </div>
          <div className="termsubpoint">The Website makes information of third parties available, including articles, analyst reports, news reports, tools to facilitate calculation, company information and data about financial markets, including any regulatory authority and other financial markets and other data from external sources (the "Third Party Content"). You acknowledge and agree that the Third Party Content is not created or endorsed by Digitechub. The provision of Third Party Content is for general informational purposes only and does not constitute a recommendation or solicitation to purchase or sell any securities or shares or to make any other type of investment or investment decision. In addition, the Third Party Content is not intended to provide tax, legal or investment advice. You acknowledge that the Third Party Content provided to You is obtained from sources believed to be reliable, but that no guarantees are made by Digitechub or the providers of the Third Party Content as to its accuracy, completeness, timeliness. You agree not to hold Digitechub, any business offering products or services through the Website or any provider of Third Party Content liable for any investment decision or other transaction You may make based on Your reliance on or use of such data, or any liability that may arise due to delays or interruptions in the delivery of the Third Party Content for any reason.</div>
          <div className="termsubpoint"> <br/>By using any Third Party Content, You may leave this Website and be directed to an external website, or to a website maintained by an entity other than Digitechub. If You decide to visit any such site, You do so at Your own risk and it is Your responsibility to take all protective measures to guard against viruses or any other destructive elements. Digitechub makes no warranty or representation regarding, and does not endorse, any linked Websites or the information appearing thereon or any of the products or services described thereon. Links do not imply that Digitechub or this Website sponsors, endorses, is affiliated or associated with, or is legally authorized to use any trademark, trade name, logo or copyright symbol displayed in or accessible through the links, or that any linked site is authorized to use any trademark, trade name, logo or copyright symbol of Digitechub or any of its affiliates or subsidiaries. You hereby expressly acknowledge and agree that the linked sites are not under the control of Digitechub and Digitechub is not responsible for the contents of any linked site or any link contained in a linked site, or any changes or updates to such sites. Digitechub is not responsible for webcasting or any other form of transmission received from any linked site. Digitechub is providing these links to You only as a convenience, and the inclusion of any link shall not be construed to imply endorsement by Digitechub in any manner of the website.</div>
        
          <div className="termsheadings">
          No Warranties:
          </div>
          <div className="termsubpoint">This website, the information and materials on the site, and any software made available on the Website, are provided "as is" without any representation or warranty, express or implied, of any kind, including, but not limited to, warranties of merchantability, non-infringement, or fitness for any particular purpose. There is no warranty of any kind, express or implied, regarding third party content. In spite of Digitechub’ best endeavors, there is no warranty on behalf of Digitechub that this Website will be free of any computer viruses. Some jurisdictions do not allow for the exclusion of implied warranties, so the above exclusions may not apply to you.</div>
        
          <div className="termsheadings">
        Limitation of Damages:
          </div>
          <div className="termsubpoint">In no event shall Digitechub or any of its subsidiaries or affiliates be liable to any entity for any direct, indirect, special, consequential or other damages (including, without limitation, any lost profits, business interruption, loss of information or programs or other data on your information handling system) that are related to the use of, or the inability to use, the content, materials, and functions of this Website or any linked Website, even if Digitechub is expressly advised of the possibility of such damages.</div>
        
          <div className="termsheadings">
          Disclaimer:
          </div>
          <div className="termsubpoint">The website may contain inaccuracies and typographical and clerical errors. Digitechub expressly disclaims any obligation(s) to update this website or any of the materials on this website. Digitechub does not warrant the accuracy or completeness of the materials or the reliability of any advice, opinion, statement or other information displayed or distributed through the Website. You acknowledge that any reliance on any such opinion, advice, statement, memorandum, or information shall be at your sole risk. Digitechub reserves the right, in its sole discretion, to correct any errors or omissions in any portion of the Website. Digitechub may make any other changes to the Website, the materials and the products, programs, services or prices (if any) described in the Website at any time without notice. This Website is for informational purposes only and should not be construed as technical advice of any manner.</div>
        
          <div className="termsheadings">
          Positions on the Digitechub Website:
          </div>
          <div className="termsubpoint">Digitechub does not claim ownership of the materials You provide to Digitechub (including feedback and suggestions) or post, upload, input or submit to any section of the Website, (each a "Submission" and collectively "Submissions"). However, by posting, uploading, inputting, providing or submitting ("Posting") Your Submission You are granting Digitechub, its affiliated companies and necessary sub-licensees permission to use Your Submission in connection with the operation of their Internet businesses (including, without limitation, all services offered by Digitechub), including, without limitation, the license rights to: copy, distribute, transmit, publicly display, publicly perform, reproduce, edit, translate and reformat Your Submission; to publish Your name in connection with Your Submission; and the right to sublicense such rights to any other party.</div>
          <div className="termsubpoint"><br/>
            <div>You hereby acknowledge and agree that no compensation shall be paid or no future commercial consideration has accrued with respect to the use of Your Submission by Digitechub, as provided herein. Digitechub shall be under no obligation to post or use any Submission You may provide and Digitechub shall remove any Submission at any time at its own sole discretion.</div>
           <br/>
           <div>By Posting a Submission You hereby warrant and represent that You own or otherwise control all of the rights required under worldwide law for Your Submission as described in these Terms of Use including, without limitation, all the rights necessary for You to provide, post, upload, input or submit the Submissions.</div>
          </div>

          <div className="termsheadings">
          Indemnify:
          </div>
          <div className="termsubpoint">You agree to indemnify and hold harmless Digitechub, its subsidiaries and affiliates from any claim, cost, expense, judgment or other loss relating to Your use of this Website in any manner, including without limitation of the foregoing, any action You take which is in violation of the terms and conditions of these Terms of Use and against any applicable law.</div>
        
          <div className="termsheadings">
          Changes:
          </div>
          <div className="termsubpoint">Digitechub reserves the rights, at its sole discretion, to change, modify, add or remove any portion of these Terms of Use in whole or in part, at any time. Changes in these Terms of Use will be effective when notice of such change is posted. Your continued use of the Website after any changes to these Terms of Use are posted will be considered acceptance of those changes. Digitechub may terminate, change, suspend or discontinue any aspect of the Website, including the availability of any feature(s) of the Website, at any time. Digitechub may also impose limits on certain features and services or restrict Your access to certain sections or all of the Website without notice or liability. You hereby acknowledge and agree that Digitechub may terminate the authorization, rights and license given above at any point of time at its own sole discretion and upon such termination; You shall immediately destroy all Materials.</div>
        
          <div className="termsheadings">
          International users and Choice of law:
          </div>
          <div className="termsubpoint">This Site is controlled, operated and administered by Digitechub from its offices within India. Digitechub makes no representation that Materials on this Website are appropriate or available for use at any other location(s) outside India. Any access to this Website from territories where their contents are illegal is prohibited. You may not use the Website or export the Materials in violation of any applicable export laws and regulations. If You access this Website from a location outside India, You are responsible for compliance with all local laws..</div>
          <div className="termsubpoint"><br/>
            <div>These Terms of Use shall be governed by the laws of India, without giving effect to its conflict of laws provisions. You agree that the appropriate court(s) in Bangalore, India, will have the exclusive jurisdiction to resolve all disputes arising under these Terms of Use and You hereby consent to personal jurisdiction in such forum.</div>
            <br/>
            <div>These Terms of Use constitutes the entire agreement between Digitechub and You with respect to Your use of the Website. Any claim You may have with respect to Your use of the Website must be commenced within one (1) year of the cause of action. If any provision(s) of this Terms of Use is held by a court of competent jurisdiction to be contrary to law then such provision(s) shall be severed from this Terms of Use and the other remaining provisions of this Terms of Use shall remain in full force and effect.</div>
          </div>


         </div>
          </div>  
        </div>
        
        </div>
    
    </article>
  </section>
 

        

        </>
        

    )

  }
