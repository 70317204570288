import "./template.css";
 
 import { Link } from "react-router-dom";
import ReadModal2 from "./readMore.js";
 import React, { useState, useRef, useEffect} from 'react';
 import Slider from 'react-slick';
 import 'slick-carousel/slick/slick.css';
 import 'slick-carousel/slick/slick-theme.css';
import CarouselSlider from "./Carousel.js";
import CaseStudyCard from "./CaseStudyCard.js";

import Accordion from "./Accordion";
import "./Accordion.css";


import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

function CloudServices() {



    const [activeModal, setActiveModal] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const sliderRef = useRef(null);
  const modalRef = useRef(null);
  const [selectedSlide, setSelectedSlide] = useState(null);
  const settings = {
    // dots: true,
    // infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },

      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },

      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 3000,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
     
    ],
  };
  const slideData = [
    {
      id: 1,
      heading: 'Customized Cloud Strategies',
      text: 'Tailor-made cloud solutions that align with your specific business needs and goals. ',
      buttonLabel: 'Read More',
      modalContent: {
        // modalHeading: 'Modal Heading 1',
        modalText: 'CloudCipher seamlessly integrates with existing workflows and software ecosystems, minimizing disruption and streamlining the adoption process. Whether migrating from another platform or implementing new security measures, CloudCipher ensures a smooth transition with minimal downtime.',
      },
    },
    {
      id: 2,
      heading: 'Expertise in Cloud Technology',
      text: 'A team of experts adept in the latest cloud technologies and best practices. ',
      buttonLabel: 'Read More',
      modalContent: {
        modalHeading: 'Modal Heading 2',
        modalText: 'Modal Text 2',
      },
    },
 
    {
      id: 3,
      heading: 'Proactive Management and Support',
      text: 'Ongoing management and proactive support to ensure your cloud environment runs smoothly.',
      buttonLabel: 'Read More',
      modalContent: {
        modalHeading: 'Modal Heading 3',
        modalText: 'Modal Text 3',
      },
    },
 
    {
      id: 4,
      heading: 'Security and Compliance Focus',
      text: 'A strong emphasis on maintaining a secure and compliant cloud environment. ',
      buttonLabel: 'Read More',
      modalContent: {
        modalHeading: 'Modal Heading 4',
        modalText: 'Modal Text 4',
      },
    },
 
    {
      id: 5,
      heading: 'Performance Optimization',
      text: 'Continuous monitoring and optimization to ensure peak performance of your cloud resources."',
      buttonLabel: 'Read More',
      modalContent: {
        modalHeading: 'Modal Heading 5',
        modalText: 'Modal Text 5',
      },
    },
 
    // {
    //   id: 6,
    //   heading: 'Sixth Slide',
    //   text: 'A different text for the second slide.',
    //   buttonLabel: 'Read More',
    //   modalContent: {
    //     modalHeading: 'Modal Heading 6',
    //     modalText: 'Modal Text 6',
    //   },
    // },
 
    // {
    //   id: 7,
    //   heading: 'Seventh Slide',
    //   text: 'A different text for the second slide.',
    //   buttonLabel: 'Read More',
    //   modalContent: {
    //     modalHeading: 'Modal Heading 7',
    //     modalText: 'Modal Text 7',
    //   },
    // },
 
    // {
    //   id: 8,
    //   heading: 'Eighth Slide',
    //   text: 'A different text for the second slide.',
    //   buttonLabel: 'Read More',
    //   modalContent: {
    //     modalHeading: 'Modal Heading 8',
    //     modalText: 'Modal Text 8',
    //   },
    // },
    // ... (more slides)
  ];


  let items = [
    {
      title: "Q: What makes cloud migration necessary for modern businesses? ",
      content:
        "A: Cloud migration is key for businesses seeking scalability, flexibility, and efficiency in their operations, allowing for better resource management and cost savings.  ",
    },
    {
      title: "Q: How do you handle data security and privacy during cloud migration?",
      content:
        "A: We employ rigorous security protocols, including data encryption and compliance checks, to ensure your data's security and privacy during migration and beyond.  ",
    },
    {
      title: "Q: Can cloud services be scaled according to business growth?  ",
      content:
        "A: Absolutely. Our cloud services are inherently scalable, allowing you to easily adjust resources as your business grows.  ",
    },
   
    
   
  ];
 
 
 
  const openModal = (slide) => {
    setSelectedSlide(slide);
    setModalOpen(true);
  };
 
  const closeModal = () => {
    setModalOpen(false);
  };
 
  // Handle clicks outside the modal to close it
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        closeModal();
      }
    };
 
    window.addEventListener('mousedown', handleOutsideClick);
 
    return () => {
      window.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);



  const modalContent = (
    <>
      <p className="modal-np" >The Purpose Behind Our Cloud Services </p>
      
      Managed Services & End to End Implementation is designed to streamline the transition to cloud computing for businesses, providing comprehensive management and seamless implementation from start to finish. We at DigiTecHub Inc, believe in “Simplify your journey to the cloud with Digitechub’s expert management & seamless implementation."

        <hr/>
             {/* <br/> <strong>Scalability</strong> 
 
             Cloud services provide scalability, allowing users to quickly and easily scale resources up or down based on demand. This flexibility enables businesses to adapt to changing workload requirements without the need for significant upfront investment in infrastructure.
         <br/><br/>
        <strong> Cost Efficiency</strong>
          Cloud services offer a pay-as-you-go model, where users only pay for the resources they consume. This eliminates the need for costly hardware purchases, maintenance, and upgrades, making cloud computing a cost-effective solution for businesses of all sizes.
 
          <br/><br/><strong>Security</strong>
          Cloud services employ advanced security measures, including encryption, access controls, and threat detection, to protect data and applications from unauthorized access, cyber attacks, and data breaches. Cloud providers invest heavily in security infrastructure and compliance certifications to ensure data privacy and regulatory compliance
 
 
          <br/><br/><strong>Accessibility</strong>
          Cloud services provide anytime, anywhere access to data and applications via the internet. This accessibility enables remote work, collaboration among geographically dispersed teams, and seamless access to resources from various devices, improving productivity and efficiency. */}
  
 
      {/* Your dynamic content here */}
      {/* You can pass any JSX content you want */}
    </>
  );


  return (

    <div>

<div id="maindivs">
        <div id="carouselExampleCaptions" className="carousel slide">
          <div className="carousel-inner">
            <div className="carousel-item active">
              <img
                src="/Images/cloudservicesbgmain.png"
                className="d-block w-100"
                style={{ height: "auto",opacity:"0.3"  }}
                alt="slider first"
              />
              <div className="carousel-caption  d-md-block">
                <div className="component-1 text-dark " >Cloud Services</div>
              
                <div
                  className="component-paras"
                  style={{ color: "black", marginTop: "-20px" ,fontStyle:'italic',fontWeight:"700" }}
                >
                 "Managed Services & End to End Implementation  " 
                </div>
              </div>
            </div>
    
        </div>
        </div>
        
    
        <div className="container-np2">
          {/* <!-- section2 title --> */}
          <div className="cont2-titles">
            {/* <div class="cont2-titletext">What is Cloud Services?</div> */}
            
          </div>
    
          {/* <!-- section part --> */}
          <div class="container21">
            {/* <!-- left part --> */}
    
            <div className="cont2-left cont2-text">
              <div
                className="left-text"
                style={{
                  // fontFamily: "arial",
                fontSize:"22px",
                  fontFamily: "system-ui",
                }}
              >
              At DigiTecHub Inc, we offer highest levels of cloud Managed Services. A cloud service refers to any resource or application made available to users on demand via the internet from cloud computing provider's servers. These services can include storage, computing power, databases, software, & more, enabling users to access & utilize them remotely without the need for physical infrastructure or on-premises hardware. Cloud services typically operate on  subscription-based or pay-per-use model, offering scalability, flexibility, & cost-effectiveness compared to traditional IT solutions. 
              </div>
            </div>
    
            {/* right part */}
            <div className="cont2-right cont2-text">
              <div className="right-text">
                <img
                  class="content2img"

           

                  src="/Images/cloud-network.gif"

                  alt="Description of the image"
                />
              </div>
            </div>
          </div>
          <div class="readmore">
            <ReadModal2 buttonText="Read More" modalContent={modalContent} />

            
          </div>
        </div>
    
        {/* section3 */}
        <section class="section3">
          <div class="cont2-title title ">Our Features</div>
    
          <div class="section3parts">
            <div class="content-np item31" style={{ display: "flex", flexDirection: "column", justifyContent: "flex-end" }}>
              <div>
                <strong style={{
                   paddingLeft: '20px'
                }}> Cloud Infrastructure Management
    </strong>
                <p class="aligned-paragraph item3text" style={{ marginBottom: "30px" }}>
                Provisioning & management of virtual servers, storage, & networking resources. 

   Monitoring system performance & ensuring optimal resource utilization. 

    Implementing security measures to safeguard infrastructure & data. 
                </p>

                
              </div>
            </div>
    
            <div class="content-np item322">
              <strong class="item32-heading item31-conent"style={{
                   paddingLeft:'15px',
                   fontSize:"18px"
                }} >
              Backup & Disaster Recovery
    
              </strong>
              <div class=" item31-conent-img  "></div>
              <p class="aligned-paragraph item3text" style={{color:'black'}}>
              User is interested in data protection, disaster recovery, and backup and recovery processes.
              </p>
            </div>
    
            <div class="content-np item33">
              <strong class=""style={{
                  paddingLeft:'15px'
                }} > Security & Compliance
    </strong>
              <p class="aligned-paragraph item3text">
              Implementation of robust security measures to protect against cyber threats & unauthorized access,

   Compliance with industry regulations & standards to maintain data integrity & privacy,

    Regular security audits & updates to mitigate risks & vulnerabilities. 
              </p>
              {/* <div class="item32-button"><button class="item32-button">Read more</button></div> */}
            </div>
    
            <div class="content-np item34" >
              <strong style={{
                   paddingLeft:'15px'
                }} >Cost Optimization
     </strong>
              <p class="aligned-paragraph item3text" style={{color:'black'}}>
              User is interested in analyzing cloud usage & spending, optimizing resource allocation, and making recommendations for improving cloud architecture efficiency & cost-effectiveness.
              </p>
            </div>
            <div class="content-np item35">
              <strong style={{
                   paddingLeft:'15px'
                }}> Performance Optimization
     </strong>
              <p class="aligned-paragraph item3text">
              User is interested in fine-tuning cloud infrastructure for optimal performance, scalability, and continuous monitoring & optimization under varying workloads.

              </p>
            </div>
    
            <div class="content-np item36">
              <strong style={{
                  paddingLeft:'15px'
                }}> Migration & Onboarding
    </strong>
              <p class="aligned-paragraph item3text">
              User is interested in seamless migration of existing systems & data to the cloud, including onboarding assistance and planning for minimal disruption.
              </p>
            </div>
    
            <div class="content-np item37">
              <strong style={{
                  paddingLeft:'15px'
                }}>Training & Documentation
    </strong>
              <p class="aligned-paragraph item3text" style={{color:'black'}}>
              User is interested in providing training programs & resources and creating comprehensive documentation for effective cloud resource utilization.

              </p>
            </div>
          </div>
          {/* <div class="readmore">
            <button class="read1">Read More</button>
          </div> */}
        </section>

        
    
        <div class="section4">
          
          <div className="main-cont">
            <div className="Cardcomp">
              <div className="card-container">
                <div className="row-ca">
                  <div className="col-ca">
                    <div className="card-head text-center">
                      <h2 className="head-t ">USP of Cloud Services</h2>
                     
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-container">
              <div className="row-ck">
                <div className="col-ck">
                  <div className="card-wrapper">
                    <div className="main-card">
                      <div className="innercon">
                        <div className="card-content1">
                          <h2>
                          Comprehensive Managed Services
                            <FontAwesomeIcon icon={faArrowRight} />
                          </h2>
                          {/* <p>vbhf</p> */}
                        </div>
                        <img src="/Images/approch.gif" alt="" />
                      </div>
                    </div>
                    <div className="hover-card">
                      <div className="hover-content">
                        <h2>  Comprehensive Managed Services </h2>
                        <p>
                        At DigiTecHub Inc, our cloud services offer end-to-end management, including planning, monitoring, & support. We provide a holistic approach to cloud computing, expertly managing & optimizing every aspect for performance, security, & cost-efficiency.

 
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-ck">
                  <div className="card-wrapper">
                    <div className="main-card">
                      <div className="innercon">
                        <div className="card-content1">
                          <h2>
                          Tailored Solutions for Every Business
                            <FontAwesomeIcon icon={faArrowRight} />{" "}
                          </h2>
                          {/* <p>vbhf</p> */}
                        </div>
                        <img src="/Images/Tailored_for_Small_and_Midsize_Tutors.gif" alt="" />
                      </div>
                    </div>
                    <div className="hover-card">
                      <div className="hover-content">
                        <h2>Tailored Solutions for Every Business</h2>
                        <p>
                        At DigiTecHub Inc, we offer customized cloud solutions tailored to your unique needs, from small startups to large enterprises. Our personalized services drive growth, innovation, & success, aligning with your business goals.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-ck">
                  <div className="card-wrapper">
                    <div className="main-card">
                      <div className="innercon">
                        <div className="card-content1">
                          <h2>
                          Robust Security & Compliance
                            <FontAwesomeIcon icon={faArrowRight} />
                          </h2>
                          {/* <p>vbhf</p> */}
                        </div>
                        <img src="/Images/shield.gif" alt="" />
                      </div>
                    </div>
                    <div className="hover-card">
                      <div className="hover-content">
                        <h2>Robust Security & Compliance</h2>
                        <p>
                        At DigiTecHub Inc, security is our top priority. With advanced encryption, access controls, threat detection, & compliance certifications, trust us to protect your data & applications in a secure & compliant environment.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-ck">
                  <div className="card-wrapper">
                    <div className="main-card">
                      <div className="innercon">
                        <div className="card-content1">
                          <h2>
                          Scalability & Flexibility
       <FontAwesomeIcon icon={faArrowRight} />
                          </h2>
                          {/* <p>vbhf</p> */}
                        </div>
                        <img src="/Images/scalability.gif" alt="" />
                      </div>
                    </div>
                    <div className="hover-card">
                      <div className="hover-content">
                        <h2> Scalability & Flexibility</h2>
                        <p>
                        At DigiTecHub Inc, our cloud services offer scalability & flexibility with on-demand resources, automatic scaling, & pay-as-you-go pricing. Easily adapt to changing business needs without unnecessary costs or complexity.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-ck">
                  <div className="card-wrapper">
                    <div className="main-card">
                      <div className="innercon">
                        <div className="card-content1">
                          <h2>
                          Cost Optimization & Transparency
     
                            <FontAwesomeIcon icon={faArrowRight} />
                          </h2>
                          {/* <p>vbhf</p> */}
                        </div>
                        <img src="/Images/hand.gif" alt="" />
                      </div>
                    </div>
                    <div className="hover-card">
                      <div className="hover-content">
                        <h2> Cost Optimization & Transparency</h2>
                        <p>
                        At DigiTecHub Inc, we prioritize maximizing value for our customers. We optimize your cloud spending by analyzing usage patterns, identifying cost-saving opportunities, & providing transparent billing & reporting for greater cost efficiency & control.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>


                <div className="col-ck">
                  <div className="card-wrapper">
                    <div className="main-card">
                      <div className="innercon">
                        <div className="card-content1">
                          <h2>
                          Continuous Innovation & Improvement
                            <FontAwesomeIcon icon={faArrowRight} />
                          </h2>
                          
                        </div>
                        <img src="/Images/implementation.gif" alt="" />
                      </div>
                    </div>
                    <div className="hover-card">
                      <div className="hover-content">
                        <h2>Continuous Innovation & Improvement </h2>
                        <p>
                          {" "}
                          At DigiTecHub Inc, we're committed to staying at the forefront of cloud innovation, delivering the latest advancements to help you leverage the full potential of the cloud for your business success.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>





               

                




              




               
                
              </div>
            </div>
          </div>




          {/* <CarouselSlider/> */}
        </div>



    
        <div className="section5" >
    <h1 className="section5heading">How We are Handling</h1>
      <div className="section5jsxcard">
      <CaseStudyCard
        imageUrl="/Images/casestudyimg1.jpg"
        title="Assessment & Planning"
        description="At DigiTecHub Inc, we conduct a comprehensive assessment to develop a tailored cloud strategy & implementation plan for a smooth transition to the cloud.  "
        // linkUrl="/platform/E-cart/casestudy-vitualwallet"
      />
      <CaseStudyCard
        imageUrl="/Images/casestudyimg2.jpg"
        title="Migration & Onboarding"
        description="At DigiTecHub Inc, our team manages the migration of your applications, data, & workloads to the cloud, ensuring minimal downtime & data integrity, with support every step of the way. "
        linkUrl=""
      />
      <CaseStudyCard
        imageUrl="/Images/casestudyimg3.jpg"
        title="Configuration & Optimization"
        description="At DigiTecHub Inc, we configure & optimize your cloud environment for performance, security, & cost-efficiency, leveraging automation tools & best practices. "
        linkUrl="/platform/E-cart/casestudy-vitualwallet"
      />
      <CaseStudyCard
            imageUrl="/Images/casestudyimg4.jpg"
            title="Security & Compliance"
  
     
        description="At DigiTecHub Inc, security is paramount. We implement robust measures, including encryption, access controls, & audits, to protect your data & ensure compliance with industry regulations."
        linkUrl=""
      />
      
     
      
      
     
    {/* <div class="readmore">
  <button class="read">show More</button>
  </div> */}


{/* section 6 */}


  
    </div>
    </div>
    
     
    <section className="FAQ">
        <div className="FAQbody">
          <h1 className="section5heading text-dark" >FAQ's for this service</h1>
          <Accordion items={items} />
        </div>
      </section>

        
      </div>
      
    </div>
  )
}

export default CloudServices
