import React from 'react';
import { Container, Typography, Box, Grid, Paper, Button } from '@mui/material';
import { motion } from 'framer-motion';
import { 
  DocumentTextIcon, 
  DocumentDuplicateIcon, 
  DocumentCheckIcon, 
  DocumentMagnifyingGlassIcon,
  ArrowDownTrayIcon
} from '@heroicons/react/24/outline';

const Documentation = () => {
  const documents = [
    {
      category: "Loan Documentation",
      icon: DocumentTextIcon,
      files: [
        {
          title: "Loan Application Forms",
          description: "Standard forms required for mortgage loan applications",
          format: "PDF",
          size: "2.4 MB",
          version: "2024.1"
        },
        {
          title: "Income Verification Templates",
          description: "Templates for verifying employment and income",
          format: "DOCX",
          size: "1.8 MB",
          version: "2024.1"
        }
      ]
    },
    {
      category: "Disclosure Documents",
      icon: DocumentDuplicateIcon,
      files: [
        {
          title: "Initial Disclosure Package",
          description: "Required disclosures for loan application process",
          format: "PDF",
          size: "3.2 MB",
          version: "2024.1"
        },
        {
          title: "Fee Schedules",
          description: "Comprehensive list of fees and charges",
          format: "PDF",
          size: "1.5 MB",
          version: "2024.1"
        }
      ]
    },
    {
      category: "Compliance Checklists",
      icon: DocumentCheckIcon,
      files: [
        {
          title: "Loan Officer Checklist",
          description: "Step-by-step compliance verification for loan officers",
          format: "PDF",
          size: "1.1 MB",
          version: "2024.1"
        },
        {
          title: "Quality Control Checklist",
          description: "Compliance review and quality control procedures",
          format: "PDF",
          size: "1.3 MB",
          version: "2024.1"
        }
      ]
    },
    {
      category: "Process Guidelines",
      icon: DocumentMagnifyingGlassIcon,
      files: [
        {
          title: "Underwriting Guidelines",
          description: "Detailed guidelines for loan underwriting process",
          format: "PDF",
          size: "4.2 MB",
          version: "2024.1"
        },
        {
          title: "Closing Procedures",
          description: "Step-by-step guide for loan closing process",
          format: "PDF",
          size: "2.8 MB",
          version: "2024.1"
        }
      ]
    }
  ];

  return (
    <Container maxWidth="lg" sx={{ 
      py: 8,
      mt: { xs: 8, md: 12 }
    }}>
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
      >
        <Typography variant="h2" sx={{ 
          mb: 2, 
          color: '#1A202C',
          fontWeight: 700,
          textAlign: 'center',
          fontSize: { xs: '2.5rem', md: '3.5rem' },
          background: 'linear-gradient(135deg, #8B4513, #D2691E)',
          WebkitBackgroundClip: 'text',
          WebkitTextFillColor: 'transparent'
        }}>
          Documentation
        </Typography>
        
        <Typography variant="h4" sx={{ 
          mb: 4, 
          color: '#8B4513',
          fontWeight: 600,
          textAlign: 'center',
          fontSize: { xs: '1.2rem', md: '1.5rem' },
          textTransform: 'uppercase',
          letterSpacing: '0.1em'
        }}>
          Forms, Templates & Guidelines
        </Typography>
        
        <Typography variant="body1" sx={{ 
          mb: 8, 
          color: '#4A5568',
          textAlign: 'center',
          maxWidth: '800px',
          mx: 'auto',
          fontSize: { xs: '1rem', md: '1.1rem' },
          lineHeight: 1.8
        }}>
          Access all necessary documentation for mortgage operations, including forms, templates, and procedural guidelines. All documents are regularly updated to ensure compliance with current regulations.
        </Typography>

        <Grid container spacing={4}>
          {documents.map((category, index) => (
            <Grid item xs={12} key={index}>
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
              >
                <Paper elevation={0} sx={{
                  p: 4,
                  borderRadius: '16px',
                  border: '1px solid rgba(139, 69, 19, 0.2)',
                  background: 'linear-gradient(135deg, rgba(139, 69, 19, 0.05), rgba(210, 105, 30, 0.05))',
                }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
                    <category.icon className="w-8 h-8" style={{ color: '#8B4513', marginRight: '12px' }} />
                    <Typography variant="h5" sx={{ color: '#8B4513', fontWeight: 600 }}>
                      {category.category}
                    </Typography>
                  </Box>

                  <Grid container spacing={3}>
                    {category.files.map((file, idx) => (
                      <Grid item xs={12} md={6} key={idx}>
                        <Paper elevation={0} sx={{
                          p: 3,
                          height: '100%',
                          borderRadius: '12px',
                          border: '1px solid rgba(139, 69, 19, 0.1)',
                          background: 'rgba(255, 255, 255, 0.5)',
                          transition: 'all 0.3s ease',
                          '&:hover': {
                            transform: 'translateY(-4px)',
                            boxShadow: '0 4px 20px rgba(139, 69, 19, 0.15)'
                          }
                        }}>
                          <Typography variant="h6" sx={{ color: '#1A202C', mb: 1, fontWeight: 600 }}>
                            {file.title}
                          </Typography>
                          <Typography variant="body2" sx={{ color: '#4A5568', mb: 2 }}>
                            {file.description}
                          </Typography>
                          <Box sx={{ 
                            display: 'flex', 
                            justifyContent: 'space-between',
                            alignItems: 'center'
                          }}>
                            <Box>
                              <Typography variant="caption" sx={{ color: '#718096', display: 'block' }}>
                                Format: {file.format}
                              </Typography>
                              <Typography variant="caption" sx={{ color: '#718096', display: 'block' }}>
                                Size: {file.size}
                              </Typography>
                              <Typography variant="caption" sx={{ color: '#718096', display: 'block' }}>
                                Version: {file.version}
                              </Typography>
                            </Box>
                            <Button
                              variant="contained"
                              startIcon={<ArrowDownTrayIcon className="w-5 h-5" />}
                              sx={{
                                background: 'linear-gradient(135deg, #8B4513, #D2691E)',
                                color: '#fff',
                                '&:hover': {
                                  background: 'linear-gradient(135deg, #D2691E, #8B4513)',
                                }
                              }}
                            >
                              Download
                            </Button>
                          </Box>
                        </Paper>
                      </Grid>
                    ))}
                  </Grid>
                </Paper>
              </motion.div>
            </Grid>
          ))}
        </Grid>
      </motion.div>
    </Container>
  );
};

export default Documentation; 