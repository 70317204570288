import "./template.css";
import { Link } from "react-router-dom";
import CarouselSlider from "./Carousel.js";
import ReadModal2 from "./readMore.js";
import React, { useState, useRef, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CaseStudyCard from "./CaseStudyCard.js";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

function Smartvision() {
  const [activeModal, setActiveModal] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const sliderRef = useRef(null);
  const modalRef = useRef(null);
  const [selectedSlide, setSelectedSlide] = useState(null);
  const settings = {
    // dots: true,
    // infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },

      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },

      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 3000,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const slideData = [
    {
      id: 1,
      heading: "Leading-Edge Technology",
      text: "SmartVision is built with the latest in camera and AI technology, offering unparalleled monitoring and analytics capabilities.",
      buttonLabel: "Read More",
      modalContent: {
        modalHeading: "Modal Heading 1",
        modalText: "Modal Text 1",
      },
    },
    {
      id: 2,
      heading: "Adaptable and Scalable",
      text: "Whether it's for small-scale operations or large-scale surveillance, SmartVision is designed to scale and adapt to your specific needs. ",
      buttonLabel: "Read More",
      modalContent: {
        modalHeading: "Modal Heading 2",
        modalText: "Modal Text 2",
      },
    },

    {
      id: 3,
      heading: "Enhanced Security and Efficiency",
      text: "Provides enhanced security features while streamlining operational processes through intelligent automation.",
      buttonLabel: "Read More",
      modalContent: {
        modalHeading: "Modal Heading 3",
        modalText: "Modal Text 3",
      },
    },

    {
      id: 4,
      heading: "Diverse Application",
      text: "Ideal for a range of applications, from business security to traffic monitoring and beyond. ",
      buttonLabel: "Read More",
      modalContent: {
        modalHeading: "Modal Heading 4",
        modalText: "Modal Text 4",
      },
    },

    {
      id: 5,
      heading: "Continuous Innovation and Support",
      text: "We continually update and support SmartVision, ensuring it stays at the cutting edge of surveillance technology. ",
      buttonLabel: "Read More",
      modalContent: {
        modalHeading: "Modal Heading 5",
        modalText: "Modal Text 5",
      },
    },

    // {
    //   id: 6,
    //   heading: 'Sixth Slide',
    //   text: 'A different text for the second slide.',
    //   buttonLabel: 'Read More',
    //   modalContent: {
    //     modalHeading: 'Modal Heading 6',
    //     modalText: 'Modal Text 6',
    //   },
    // },

    // {
    //   id: 7,
    //   heading: 'Seventh Slide',
    //   text: 'A different text for the second slide.',
    //   buttonLabel: 'Read More',
    //   modalContent: {
    //     modalHeading: 'Modal Heading 7',
    //     modalText: 'Modal Text 7',
    //   },
    // },

    // {
    //   id: 8,
    //   heading: 'Eighth Slide',
    //   text: 'A different text for the second slide.',
    //   buttonLabel: 'Read More',
    //   modalContent: {
    //     modalHeading: 'Modal Heading 8',
    //     modalText: 'Modal Text 8',
    //   },
    // },
    // ... (more slides)
  ];

  const openModal = (slide) => {
    setSelectedSlide(slide);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  // Handle clicks outside the modal to close it
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        closeModal();
      }
    };

    window.addEventListener("mousedown", handleOutsideClick);

    return () => {
      window.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const modalContent = (
    <>
      <p className="modal-np">The Purpose Behind Our Smartvision Services </p>
      At Digitech SmartVision is engineered to redefine monitoring solutions,
      blending advanced CCTV technology with AI-powered analytics. This platform
      is not just about surveillance; it's about empowering businesses and
      communities with smart, automated, and insightful monitoring capabilities.
      <hr />
      <br />
      <strong>Enhanced Surveillance and Security </strong>
      SmartVision systems can monitor environments in real-time, detect
      anomalies, and provide alerts for potential security threats. These
      systems can be deployed in public spaces, transportation hubs, critical
      infrastructure, and smart cities to enhance safety and security.
      <br />
      <br />
      <strong>Automated Manufacturing and Quality Control</strong>
      In industrial settings, SmartVision solutions automate inspection
      processes by analyzing visual data to identify defects, ensure product
      quality, and optimize production efficiency. This can lead to reduced
      costs, improved product consistency, and increased throughput.
      <br />
      <br />
      <strong>Smart Retail and Marketing</strong>
      SmartVision technology enables retailers to gather insights into customer
      behavior, preferences, and demographics by analyzing video feeds from
      cameras installed in stores. This data can be used to personalize
      marketing efforts, optimize store layouts, and improve the overall
      shopping experience.
      <br />
      <br />
      <strong>Traffic Management and Transportation</strong>
      SmartVision systems can monitor traffic flow, detect congestion, and
      manage traffic signals to optimize traffic flow in urban areas.
      Additionally, these systems can assist with automated vehicle guidance,
      parking management, and pedestrian safety.
      {/* Your dynamic content here */}
      {/* You can pass any JSX content you want */}
    </>
  );

  return (
    <div id="maindivs">
      <div id="carouselExampleCaptions" className="carousel slide">
        <div className="carousel-inner">
          <div className="carousel-item active">
            <img
              src="/Images/image (2).png"
              className="d-block w-100"
              style={{opacity:"0.4", height: "auto" }}
              alt="slider first"
            />
            <div className="carousel-caption  d-md-block">
              <div className="component-1 text-dark">AI Smartvision</div>
              <div
                className="component-paras text-dark"
                style={{
                  color: "white",
                 
                  fontStyle: "italic",
                  fontWeight:"700",
                  marginTop: "-20px"
                }}
              >
                "Envision Safety and Efficiency – The Future of Monitoring with
                SmartVision"
                {/* Discover a world where cutting-edge technology meetseCart, introducing the ExperteCart Platform. Built for entrepreneurs by entrepreneurs, our platform encapsulates everything you'd ever need to run a successful online store and more.  */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-np2">
        {/* <!-- section2 title --> */}
        <div className="cont2-titles">
          {/* <div className="cont2-titletext">What is Smartvision?</div> */}
        </div>

        {/* <!-- section part --> */}
        <div className="container21">
          {/* <!-- left part --> */}

          <div className="cont2-left cont2-text">
            <div
              className="left-text"
              style={{
                // fontFamily: "arial",

                fontFamily: "system-ui",
              }}
            >
              SmartVision is an innovative fusion of high-quality camera systems
              and cutting-edge AI software, designed to offer unparalleled
              monitoring, alerting, and environmental control capabilities.
              Tailored for diverse environments, SmartVision represents the next
              leap in intelligent surveillance technology.
            </div>
          </div>

          {/* right part */}
          <div className="cont2-right cont2-text">
            <div className="right-text">
              <img
                className="content2img"
                src="/Images/AI.gif"
                alt="Description of the image"
              />
            </div>
          </div>
        </div>

        <div className="readmore">
          <ReadModal2 buttonText="Read More" modalContent={modalContent} />
          {/* <ReadModal1 /> */}
        </div>
      </div>

      {/* section3 */}
      <section className="section3">
        <div className="cont2-title title ">Our Features</div>

        <div className="section3parts">
          <div className="content-np item31">
            <div>
              <strong style={{ paddingLeft: "20px" }}>
                Intelligent Camera System
              </strong>
              <p
                className="aligned-paragraph item3text"
                style={{ marginBottom: "30px" }}
              >
                Incorporates a set of certified cameras with IoT connectivity
                and essential sensors, ensuring comprehensive monitoring
                capabilities.
              </p>
            </div>
          </div>

          <div className="content-np item322">
            <strong
              className="item32-heading item31-conent"
              style={{
                paddingLeft: "15px",
                color: "black",
              }}
            >
              User-Friendly Interface
            </strong>
            <div className=" item31-conent-img  "></div>
            <p
              className="aligned-paragraph item3text mt-1"
              style={{ color: "black" }}
            >
              Designed with intuitive interfaces, making it accessible and easy
              to use across multiple devices.
            </p>
          </div>

          <div className="content-np item33">
            <strong
              className=""
              style={{
                paddingLeft: "15px",
              }}
            >
              Cross-Platform Accessibility
            </strong>
            <p className="aligned-paragraph item3text">
              Ensures easy and seamless access via mobile phones, websites,
              SmartTVs, and smartwatches, enhancing user convenience and
              operational flexibility.
            </p>
            {/* <div class="item32-button"><button class="item32-button">Read more</button></div> */}
          </div>

          <div className="content-np item34">
            <strong
              style={{
                paddingLeft: "15px",
              }}
            >
              AI-Enabled SaaS Platform
            </strong>
            <p className="aligned-paragraph item3text" style={{ color: "black" }}>
              Offers advanced features such as event logging, broadcasting, and
              commanding, along with user-friendly templates tailored for
              diverse use cases.
            </p>
          </div>
          <div className="content-np item35">
            <strong
              style={{
                paddingLeft: "15px",
              }}
            >
              Advanced Integration
            </strong>
            <p className="aligned-paragraph item3text">
              Uniquely combines camera hardware with AI-driven software, setting
              a new standard in intelligent surveillance.
            </p>
          </div>

          <div className="content-np item36">
            <strong
              style={{
                paddingLeft: "15px",
              }}
            >
              Customization
            </strong>
            <p className="aligned-paragraph item3text">
              Highly adaptable to a wide range of environments and use cases,
              ensuring versatility and applicability across industries.
            </p>
          </div>

          <div className="content-np item37">
            <strong
              style={{
                paddingLeft: "15px",
              }}
            >
              Customizable and Scalable
            </strong>
            <p className="aligned-paragraph item3text" style={{ color: "black" }}>
              Adaptable to a wide range of environments, from residential to
              industrial, ensuring scalability as your needs evolve.
            </p>
          </div>
        </div>
        {/* <div class="readmore">
          <button class="read1">Read More</button>
        </div> */}
      </section>

      <div className="section4">
          
          <div className="main-cont">
            <div className="Cardcomp">
              <div className="card-container">
                <div className="row-ca">
                  <div className="col-ca">
                    <div className="card-head text-center">
                      <h2 className="head-t ">USP of AI Smartvision Platforms</h2>
                     
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-container">
              <div className="row-ck">
                <div className="col-ck">
                  <div className="card-wrapper">
                    <div className="main-card">
                      <div className="innercon">
                        <div className="card-content1">
                          <h2>
                          Leading-Edge Technology{" "}
                            <FontAwesomeIcon icon={faArrowRight} />
                          </h2>
                          {/* <p>vbhf</p> */}
                        </div>
                        <img src="/Images/organigram.gif" alt="" />
                      </div>
                    </div>
                    <div className="hover-card">
                      <div className="hover-content">
                        <h2>Leading-Edge Technology </h2>
                        <p>
                        SmartVision is built with the latest in camera and AI technology, offering unparalleled monitoring and analytics capabilities. {" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-ck">
                  <div className="card-wrapper">
                    <div className="main-card">
                      <div className="innercon">
                        <div className="card-content1">
                          <h2>
                          Adaptable and Scalable
                            <FontAwesomeIcon icon={faArrowRight} />{" "}
                          </h2>
                          {/* <p>vbhf</p> */}
                        </div>
                        <img src="/Images/scalability.gif" alt="" />
                      </div>
                    </div>
                    <div className="hover-card">
                      <div className="hover-content">
                        <h2>Adaptable and Scalable</h2>
                        <p>
                          {" "}
                          Whether it's for small-scale operations or large-scale surveillance, SmartVision is designed to scale and adapt to your specific needs.{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-ck">
                  <div className="card-wrapper">
                    <div className="main-card">
                      <div className="innercon">
                        <div className="card-content1">
                          <h2>
                          Enhanced Security and Efficiency{" "}
                            <FontAwesomeIcon icon={faArrowRight} />
                          </h2>
                          {/* <p>vbhf</p> */}
                        </div>
                        <img src="/Images/Secure_Payment_Processing.gif" alt="" />
                      </div>
                    </div>
                    <div className="hover-card">
                      <div className="hover-content">
                        <h2>Enhanced Security and Efficiency</h2>
                        <p>
                        Provides enhanced security features while streamlining operational processes through intelligent automation.{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-ck">
                  <div className="card-wrapper">
                    <div className="main-card">
                      <div className="innercon">
                        <div className="card-content1">
                          <h2>
                          Diverse Application<FontAwesomeIcon icon={faArrowRight} />
                          </h2>
                          {/* <p>vbhf</p> */}
                        </div>
                        <img src="/Images/Engagement_and_Interaction.gif" alt="" />
                      </div>
                    </div>
                    <div className="hover-card">
                      <div className="hover-content">
                        <h2>Diverse Application</h2>
                        <p>
                        Ideal for a range of applications, from business security to traffic monitoring and beyond.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-ck">
                  <div className="card-wrapper">
                    <div className="main-card">
                      <div className="innercon">
                        <div className="card-content1">
                          <h2>
                          Continuous Innovation and Support
                            <FontAwesomeIcon icon={faArrowRight} />
                          </h2>
                          {/* <p>vbhf</p> */}
                        </div>
                        <img src="/Images/implementation.gif" alt="" />
                      </div>
                    </div>
                    <div className="hover-card">
                      <div className="hover-content">
                        <h2> Continuous Innovation and Support</h2>
                        <p>
                        We continually update and support SmartVision, ensuring it stays at the cutting edge of surveillance technology. {" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-ck">
                  <div className="card-wrapper">
                    <div className="main-card">
                      <div className="innercon">
                        <div className="card-content1">
                          <h2>
                          Customizable Solutions{" "}
                            <FontAwesomeIcon icon={faArrowRight} />
                          </h2>
                          {/* <p>vbhf</p> */}
                        </div>
                        <img src="/Images/hand.gif" alt="" />
                      </div>
                    </div>
                    <div className="hover-card">
                      <div className="hover-content">
                        <h2>Customizable Solutions</h2>
                        <p>
                          {" "}
                          We offer customizable AI Smartvision solutions tailored to your specific needs, ensuring that you get the most out of our technology.{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

               
                
              </div>
            </div>
          </div>




          {/* <CarouselSlider/> */}
        </div>


      <div className="section5">
        <h1 className="section5heading">How we are Handling</h1>
        <div className="section5jsxcard">
          <CaseStudyCard
            imageUrl="/Images/casestudyimg1.jpg"
            title="Custom Implementation"
            description="Tailoring SmartVision implementation to fit your unique operational environment and requirements. "
            // linkUrl="/platform/E-cart/casestudy-vitualwallet"
          />
          <CaseStudyCard
            imageUrl="/Images/casestudyimg2.jpg"
            title="Comprehensive Training"
            description="Providing detailed training to ensure your team can fully leverage SmartVision's capabilities. "
            linkUrl=""
          />

          <CaseStudyCard
            imageUrl="/Images/casestudyimg3.jpg"
            title="Ongoing Support and Maintenance"
            description="Offering continuous support and maintenance to ensure optimal performance and up-to-date features."
            linkUrl=""
          />

          <CaseStudyCard
            imageUrl="/Images/casestudyimg4.jpg"
            title="Feedback and Continuous Improvement"
            description=" Actively seeking customer feedback to continuously enhance and update the platform. "
            linkUrl=""
          />
        </div>
      </div>
    </div>
  );
}

export default Smartvision;
