import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Blog.css';
import { Link } from 'react-router-dom';
import { Margin } from '@mui/icons-material';
import { FaComment, FaThumbsUp, FaUser } from 'react-icons/fa';

const BlogList = () => {
  const [recentPosts, setRecentPosts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [postsToShow, setPostsToShow] = useState(6); // Number of blogs to display initially
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const recentPostsResponse = await axios.get(
          'https://demo.expertit.in/api/blogs?populate=*'
        );
        setRecentPosts(recentPostsResponse.data.data);
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);
  useEffect(() => {
    const fetchCategories = async () => {
      
    
    try {
      const catrgorydata = await axios.get('https://demo.expertit.in/api/categories?populate=*'
    );
    setCategories(catrgorydata.data.data);

      
    } catch (error) {
      
    }
  };
  fetchCategories();
  },[]);
  
  // Function to format date in DD-MM-YYYY format
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  // Function to render blog cards
  const renderBlogCards = (blogs) => {
    return blogs.slice(0, postsToShow).map(blog => (
      <div key={blog.id} className="cardblog">
        {blog.attributes.CoverImg && (
          <img
            src={`https://demo.expertit.in${
              Array.isArray(blog.attributes.CoverImg.data)
                ? blog.attributes.CoverImg.data[0].attributes.url
                : blog.attributes.CoverImg.data.attributes.url
            }`}
            alt={blog.attributes.Title}
            className="img-blogs"
          />
        )}
        <div className="card-content-blogblog">
          <Link to={`/BlogDetails/${encodeURIComponent(blog.attributes.slug)}`} className="blog-link">
            <h2>{blog.attributes.Title}</h2>
          </Link>
          {Array.isArray(blog.attributes.Subtitle) ? (
            blog.attributes.Subtitle.map((paragraph, index) => (
              <p key={index}>{paragraph.children[0].text}</p>
            ))
          ) : (
            <p>{blog.attributes.Subtitle}</p>

          )}
          <p>{formatDate(blog.attributes.BlogDate)}</p>
          <div className="d-flex">
          <FaUser size={20} />
          <p>{blog.attributes.author.data.attributes.name}</p>

          </div>
          
        <div className="d-flex">
            <FaThumbsUp size={20} style={{marginRight:'10px'}}  /> <p style={{marginRight:'10px'}}>{blog.attributes.likes}</p>
            <FaComment size={20} /> 
           

          </div>
        
          
           
          
        </div>
      </div>
    ));
  };
  

  // Function to handle "Read More" button click
  const handleReadMore = () => {
    setPostsToShow((prevPostsToShow) => prevPostsToShow + 6);
  };

  return (
    <>
      <div>
        <img
          src="/Images/blogs-banner.jpg"
          className="Imgblog"
        />
      </div>
      <div className="cont2-title"style={{marginBottom:'10px !important'}}>All Blogs</div>

      <div className="blog-container">

      {/* {categories && categories.map((category) => (
        <Link to={`/category/${category.attributes.slug}`} key={category.id} className="cont2-title title">{category.attributes.Title}</Link>
      ))} */}
      {categories && (
  <div class="btn-group" role="group" aria-label="Basic example">
    {categories.map((category) => (
      <Link
        to={`/category/${category.attributes.slug}`}
        key={category.id}
        className="btn btn-primary"
      >
        {category.attributes.Title}
        
      </Link>
    ))}
  </div>
)}
        <div className="containerblog">
          {recentPosts && renderBlogCards(recentPosts)}
          {postsToShow < recentPosts.length && (
            <div className="readmore" onClick={handleReadMore}>
             <button className='Loadmore'>Load More</button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default BlogList;
