import Home from "./components/Home";
import NavBar from "./Layouts/NavBar";
import ScrollToTop from "./components/ScrollToTop";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "@fortawesome/fontawesome-svg-core/styles.css";
import "aos/dist/aos.css";
import About from "./components/About";
import React, { useEffect } from "react";
import AOS from "aos";
import CookiesBanner from './components/CookiesBanner';
import Contact from "./components/Contact";
import Terms from "./components/Terms";
import Privacy from "./components/Privacy";
import Cookie from "./components/Cookie";
import Footer from "./components/Footer";
import PageNotFound from "./components/PageNotFound";

// Platform imports
import Ecart from "./components/Ecart";
import OnlineTutor from "./components/OnlineTutor";
import Cloudeciper from "./components/Cloudeciper";
import OnlineAnalytics from "./components/OnlineAnalytics";
import ISPMonitoring from "./components/ISPMonitoring";
import Smartvision from "./components/Smartvision";

// Service imports
import StafAugmentation from "./components/StafAugmentation";
import IOTServices from "./components/IOTServices";
import ManagedServices from "./components/ManagedServices";
import AI from "./components/AI";
import Blockchain from "./components/Blockchain";
import CyberSecurity1 from "./components/CyberSecurity1";
import Mortgages from "./components/Mortgages";
import AdmServices from "./components/AdmServices";
import ERPservice from "./components/ERPservice";
import CloudServices from "./components/CloudServices";
import TestingandQA from "./components/TestingandQA";

// Solution imports
import DroneSolution from "./components/DroneSolution";
import SuplyCL from "./components/SuplyCL";
import FinancialS from "./components/FinancialS";
import HealthcareAndPharma from "./components/HealthcareAndPharma";
import SolECommerce from "./components/SolEcommerce";
import Automative from "./components/Automative";

// Case Study imports
import CsCloudeCipher1 from "./components/CsCloudCipher1";
import CsCloudCipher2 from "./components/CsCloudCipher2";
import CsCloudCipher3 from "./components/CsCloudCipher3";
import CsBharatMake from "./components/CsBharatMake";
import CsDigitalKeyStore from "./components/CsDigitalKeyStore";
import CsVirtualWallet from "./components/CsVirtualWallet";

// Blog related imports
import Blogs from "./components/Blogs";
import BlogDetails from "./components/BlogDetails";

// Compliance imports
import LicensingRequirements from "./components/LicensingRequirements";
import OperationalCompliance from "./components/OperationalCompliance";
import EducationTraining from "./components/EducationTraining";
import ComplianceGuide from "./components/ComplianceGuide";
import Documentation from "./components/Documentation";

function App() {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <Router>
      <ScrollToTop />
      <NavBar />

      <Routes>
        {/* Core Routes */}
        <Route path="/" element={<Home />} />
        <Route path="/about-us" element={<About />} /> 
        <Route path="/blog" element={<Blogs />} />
        <Route path="/Contact" element={<Contact />} />

        {/* Platform Routes */}
        <Route path="/platfom/e-cart" element={<Ecart />} />
        <Route path="/platforms/OnlineTutor" element={<OnlineTutor />} />
        <Route path="/platform/cloudecipher" element={<Cloudeciper />} />
        <Route path="/platforms/OnlineAnalytics" element={<OnlineAnalytics />} />
        <Route path="/platform/ISPMonitoring" element={<ISPMonitoring />} />
        <Route path="/platforms/Smartvision" element={<Smartvision />} />

        {/* Service Routes */}
        <Route path="/service/StafAugmentation" element={<StafAugmentation />} />
        <Route path="/service/IOTServices" element={<IOTServices />} />
        <Route path="/service/ManagedServices" element={<ManagedServices />} />
        <Route path="/service/AdmServices" element={<AdmServices />} />
        <Route path="/service/ERPservice" element={<ERPservice />} />
        <Route path="/service/CloudServices" element={<CloudServices />} />
        <Route path="/service/TestingandQA" element={<TestingandQA />} />
        <Route path="/service/AI" element={<AI />} />
        <Route path="/service/blockchain" element={<Blockchain />} />
        <Route path="/service/CyberSecurity1" element={<CyberSecurity1 />} />
        <Route path="/service/mortgages" element={<Mortgages />} />

        {/* Solution Routes */}
        <Route path="/solution/DroneSolution" element={<DroneSolution />} />
        <Route path="/solution/SuplyCL" element={<SuplyCL />} />
        <Route path="/solution/FinancialS" element={<FinancialS />} />
        <Route path="/solution/HealthcareAndPharma" element={<HealthcareAndPharma />} />
        <Route path="/solution/E-commerce" element={<SolECommerce />} />
        <Route path="/solution/Automative" element={<Automative />} />

        {/* Case Study Routes */}
        <Route path="/platform/E-cart/casestudt-bharatmake" element={<CsBharatMake />} />
        <Route path="/platform/E-cart/casestudy-digitalkeystore" element={<CsDigitalKeyStore />} />
        <Route path="/platform/E-cart/casestudy-vitualwallet" element={<CsVirtualWallet />} />
        <Route path="/platform/cloudecipher/casestudy-Empowering-taxcollection" element={<CsCloudeCipher1 />} />
        <Route path="/plaform/cloudcipher/casestudy-Enhancing-Data-Integrity" element={<CsCloudCipher2 />} />
        <Route path="/platform/cloudcipher/casestudy-Securing-Operations-Bank" element={<CsCloudCipher3 />} />

        {/* Legal Routes */}
        <Route path="/Terms" element={<Terms />} />
        <Route path="/Privacy" element={<Privacy />} />
        <Route path="/Cookie" element={<Cookie />} />

        {/* Blog Routes */}
        <Route path="/blog" element={<Blogs />} />
        <Route path="/BlogDetails/:title" element={<BlogDetails />} />

        {/* Compliance Routes */}
        <Route path="/compliance/licensing" element={<LicensingRequirements />} />
        <Route path="/compliance/operational" element={<OperationalCompliance />} />
        <Route path="/compliance/education" element={<EducationTraining />} />
        <Route path="/compliance/guide" element={<ComplianceGuide />} />
        <Route path="/compliance/documentation" element={<Documentation />} />

        {/* 404 Route */}
         <Route path="*" element={<PageNotFound />} /> 
      </Routes>
      
      <CookiesBanner />
      <Footer />
    </Router>
  );
}

export default App;
