import React from 'react';
import { Container, Typography, Box, Grid, Paper } from '@mui/material';
import { motion } from 'framer-motion';
import { CheckCircleIcon } from '@heroicons/react/24/outline';

const LicensingRequirements = () => {
  const requirements = [
    {
      title: "NMLS Registration",
      description: "Complete registration with the Nationwide Multistate Licensing System & Registry (NMLS)",
      details: [
        "Unique NMLS identifier",
        "Background check and fingerprinting",
        "Credit report review",
        "Company registration"
      ]
    },
    {
      title: "State-specific Licenses",
      description: "Obtain necessary licenses for each state of operation",
      details: [
        "Individual state applications",
        "State-specific bond requirements",
        "Branch office registrations",
        "Annual renewals"
      ]
    },
    {
      title: "Pre-licensing Education",
      description: "Complete required educational courses and training",
      details: [
        "20 hours of NMLS pre-licensing education",
        "State-specific education requirements",
        "Federal law and regulations",
        "Ethics and consumer protection"
      ]
    },
    {
      title: "Background Checks",
      description: "Comprehensive verification of personal and professional history",
      details: [
        "Criminal background screening",
        "Financial background review",
        "Employment history verification",
        "Professional references"
      ]
    }
  ];

  return (
    <Container maxWidth="lg" sx={{ 
      py: 8,
      mt: { xs: 8, md: 12 }
    }}>
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
      >
        <Typography variant="h2" sx={{ 
          mb: 2, 
          color: '#1A202C',
          fontWeight: 700,
          textAlign: 'center',
          fontSize: { xs: '2.5rem', md: '3.5rem' },
          background: 'linear-gradient(135deg, #8B4513, #D2691E)',
          WebkitBackgroundClip: 'text',
          WebkitTextFillColor: 'transparent'
        }}>
          Licensing Requirements
        </Typography>
        
        <Typography variant="h4" sx={{ 
          mb: 4, 
          color: '#8B4513',
          fontWeight: 600,
          textAlign: 'center',
          fontSize: { xs: '1.2rem', md: '1.5rem' },
          textTransform: 'uppercase',
          letterSpacing: '0.1em'
        }}>
          Professional Standards & Compliance
        </Typography>
        
        <Typography variant="body1" sx={{ 
          mb: 8, 
          color: '#4A5568',
          textAlign: 'center',
          maxWidth: '800px',
          mx: 'auto',
          fontSize: { xs: '1rem', md: '1.1rem' },
          lineHeight: 1.8
        }}>
          Understanding the comprehensive licensing requirements for mortgage operations. We maintain full compliance with all regulatory standards to ensure the highest level of service and trust.
        </Typography>

        <Grid container spacing={4}>
          {requirements.map((req, index) => (
            <Grid item xs={12} md={6} key={index}>
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
              >
                <Paper elevation={0} sx={{
                  p: 4,
                  height: '100%',
                  borderRadius: '16px',
                  border: '1px solid rgba(139, 69, 19, 0.2)',
                  background: 'linear-gradient(135deg, rgba(139, 69, 19, 0.05), rgba(210, 105, 30, 0.05))',
                  transition: 'all 0.3s ease',
                  '&:hover': {
                    transform: 'translateY(-4px)',
                    boxShadow: '0 4px 20px rgba(139, 69, 19, 0.15)'
                  }
                }}>
                  <Typography variant="h5" sx={{ color: '#8B4513', mb: 2, fontWeight: 600 }}>
                    {req.title}
                  </Typography>
                  <Typography variant="body1" sx={{ color: '#4A5568', mb: 3 }}>
                    {req.description}
                  </Typography>
                  <Box sx={{ pl: 2 }}>
                    {req.details.map((detail, idx) => (
                      <Box key={idx} sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                        <CheckCircleIcon className="w-5 h-5" style={{ color: '#8B4513', marginRight: '8px' }} />
                        <Typography variant="body2" sx={{ color: '#4A5568' }}>
                          {detail}
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                </Paper>
              </motion.div>
            </Grid>
          ))}
        </Grid>
      </motion.div>
    </Container>
  );
};

export default LicensingRequirements; 