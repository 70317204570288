import React from 'react';
import './CaseStudy.css';
import CsForm from './CsForm.js';

const CsBharatMake = () => {
  return (
    <div className="main-casestudydiv">
      <section className="caseStudysection1">
        <div className="casestudytitle">
          <div className="casestudylable ">CASE STUDY</div>
          <h1 className="case-headind1 ">
            {" "}
            BharatMake.com - Revolutionizing E-commerce with E-cart Platform.
          </h1>
        </div>
      </section>
 
      <div className="part2 col">
        <section className="para-in case-pt75">
          <article className="case-container">
            <div className="row-k">
              <div
                className="row-in case-mb20 fadeInUp animated"
                data-wow-delay="0.3s"
                style={{
                  visibility: "visible",
                  WebkitAnimationDelay: "0.3s",
                  MozAnimationDelay: "0.3s",
                  animationDelay: "0.3s",
                }}
              >
                <p className="part2-para">
                BharatMake.com, a pioneering e-commerce platform, has redefined the online shopping experience for authentic Indian products. Leveraging the E-cart platform, BharatMake.com stands as a testament to technological innovation and cultural preservation. This case study explores how BharatMake.com utilizes the E-cart platform's comprehensive features - including CRM, digital marketing, stock inventory, supply chain management, and payment gateway integration - to effectively operate in both Indian and global markets.
                </p>
                <p className="part2-para">
                The primary goal of BharatMake.com is to provide a seamless and efficient platform for global consumers to access traditional Indian products, while supporting local artisans and preserving India's rich heritage.
                </p>
              </div>
            </div>
            <div className="row-k case-mt45">
              <div className="row-in2 ">
                <div
                  className="yellow-bg pad-all wow fadeInUp animated"
                  data-wow-delay="1.3s"
                  style={{
                    visibility: "visible",
                    WebkitAnimationDelay: "1.3s",
                    MozAnimationDelay: "1.3s",
                    animationDelay: "1.3s",
                  }}
                >
                  <div className="row1 row-k">
                    <div className="col1">
                      <p>
                        {" "}
                        <strong>Key Challenges</strong>
                      </p>
                      <ul className="case-list-items">
                        <li>
                          Global Outreach: Expanding the reach of traditional Indian products to a global audience.
                        </li>
                        <li>
                          Inventory Management: Handling diverse and unique products with varying supply chain requirements
                        </li>
                        <li>
                          User Experience: Ensuring a user-friendly interface that accommodates a wide range of products.
                        </li>
                        <li>
                          Payment Processing: Integrating multiple payment gateways for global transactions.
                        </li>
                      </ul>
                    </div>
                    <div className="col2">
                      <p>
                        {" "}
                        <strong>Ready to experience?</strong>
                      </p>
                      <a
                        href="#rfs"
                        className="scrollto-target pdf"
                        title="Talk To Experts"
                      >
                        TALK TO EXPERTS
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </article>
        </section>
      </div>
      <div className="part3">
        <section  className="case-pt0 case-pb75">
          <article className="case-container">
            <div className="row-in2">
              <div
                className="row-in2 wow fadeInUp animated"
                data-wow-delay="0.3s"
                style={{
                  visibility: "visible",
                  WebkitAnimationDelay: "0.3s",
                  MozAnimationDelay: "0.3s",
                  animationDelay: "0.3s",
                }}
              >
                <h2 className="h2-heading-cs text-center">The Solution</h2>
                <h3 className=" para-txt col-md-part-2 pd-lr light-gray text-center mb50">
                  E-cart was deployed to overcome these obstacles.
                </h3>
              </div>
              <div
                className="col-part1 wow fadeInLeft animated"
                data-wow-delay="0.6s"
                style={{
                  visibility: "visible",
                  WebkitAnimationDelay: "0.6s",
                  MozAnimationDelay: "0.6s",
                  animationDelay: "0.6s",
                }}
              >
                <p>
                  <strong>E-cart Platform Utilization</strong>
                </p>
                <ul className="case-list-items">
                  <li>
                    CRM: Streamlined customer relationship management to enhance customer engagement and support  
                  </li>
                  <li>
                    Digital Marketing Tools: Leveraging SEO, social media integration, and email marketing to increase global visibility.
                  </li>
                  <li>
                  Stock Inventory Management: Real-time tracking of diverse product inventories, ensuring availability and timely updates.
                  </li>
                  <li>
                  Payment Gateway: Incorporating multiple payment options to facilitate international transactions.

                  </li>
                  
                </ul>
                <p>
                  <strong>Customization and Scalability</strong>
                </p>
                <ul className="case-list-items">
                  <li>
                    The E-cart platform's adaptability allowed BharatMake.com to customize features according to specific product categories and business needs
                  </li>
                  <li>
                    Scalable architecture to accommodate growing inventories and expanding market reach.
                  </li>
                </ul>
                <p>
                  <strong>Promoting Indian Heritage</strong>
                </p>
                <ul className="case-list-items">
                  <li>
                  Curating products that reflect India's heritage and supporting local artisans.
                  </li>
                  <li>
                  Educating global consumers about the history and craftsmanship of Indian products.
                  </li>
                </ul>
              </div>
            </div>
            
              
          </article>
        </section>
      </div>
      <div className="part4 col">
        <section className="para-in ">
          <article className="case-container">
            
            <div className="row-k ">
              <div className="row-in2 ">
                <div
                  className=" pad-all wow fadeInUp animated">
                  <div className="part4-row1 row-k">
                    <div className="col1">
                    <p>
                  <strong>Results</strong>
                </p>
                <ul className="case-list-items">
                  <li>
                  Customer Satisfaction: Enhanced user experience leading to higher customer satisfaction and repeat purchases.  
                  </li>
                  <li>
                  Global Reach: Successful penetration in international markets, with a notable presence in Europe, North America, and Southeast Asia.
                  </li>
                  <li>
                  Increased Sales: Significant growth in sales, both in India and internationally.
                  </li>
                </ul>
                    </div>
                    <div className="col2">
                    <p>
                  <strong>Conclusion</strong>
                </p>
                <ul className="-list-items">
                  <li>
                  BharatMake.com, powered by the E-cart platform, has not only provided a robust solution for e-commerce challenges but also played a crucial role in bringing Indian heritage to the global forefront. 
                  </li>
                  <li>
                  The integration of advanced features with a focus on user experience and cultural preservation has set BharatMake.com apart in the e-commerce landscape. As the platform continues to grow, it stands as a beacon of innovation, cultural pride, and economic opportunity for Indian artisans.  
                  </li>
                
                </ul>
                    </div>
                    

                  </div>
                </div>
              </div>
            </div>
          </article>
        </section>
      </div>
        
      <CsForm />
    </div>
  );
};
 
export default CsBharatMake;