import React from 'react'
import './PageNotFound.css'

export default function PageNotFound() {
  return (
   <>
   <div className="banner parbase industries-gradient aem-GridColumn aem-GridColumn--default--12">
        <section id="sml_ht_home_banner">
          <article className="container">
            <div className="row">
              <div className="col-md-9 col-sm-12 col-xs-12 ">
                <h1 id="heading" className="h2-heading white-color l-top">Page Not Found</h1>
              </div>
            </div>
          </article>
        </section>
      </div>
      <div className="not-found">
      <h1>404 Page Not Found</h1>
      <p>The requested page was not found on this server.</p>
      <p>Please check the URL or return to the homepage.</p>
    </div>
   </>
  )
}
