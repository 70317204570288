import "./template.css";

import { Link } from "react-router-dom";
import ReadModal2 from "./readMore.js";
import React, { useState, useRef, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CarouselSlider from "./Carousel.js";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

function ISPmonitoring() {
  const [activeModal, setActiveModal] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const sliderRef = useRef(null);
  const modalRef = useRef(null);
  const [selectedSlide, setSelectedSlide] = useState(null);
  const settings = {
    // dots: true,
    // infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },

      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },

      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 3000,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const slideData = [
    {
      id: 1,
      heading: "Reduced Downtime and Enhanced Reliability ",
      text: "Maximize uptime and reliability with our ISP monitoring, minimizing downtime and optimizing performance.",
      buttonLabel: "Read More",
      modalContent: {
        // modalHeading: 'Modal Heading 1',
        modalText:
          "CloudCipher seamlessly integrates with existing workflows and software ecosystems, minimizing disruption and streamlining the adoption process. Whether migrating from another platform or implementing new security measures, CloudCipher ensures a smooth transition with minimal downtime.",
      },
    },
    {
      id: 2,
      heading: "Proactive Problem-Solving with AI ",
      text: "AI anticipates and prevents network disruptions, ensuring smooth operations and peak performance.",
      buttonLabel: "Read More",
      modalContent: {
        modalHeading: "Modal Heading 2",
        modalText: "Modal Text 2",
      },
    },

    {
      id: 3,
      heading: "Cost-Effective Network Management ",
      text: "Streamline operations with our cost-effective network management, saving resources without compromising performance.",
      buttonLabel: "Read More",
      modalContent: {
        modalHeading: "Modal Heading 3",
        modalText: "Modal Text 3",
      },
    },

    {
      id: 4,
      heading: "Data-Driven Insights for Strategic Decisions ",
      text: "Unlock strategic insights with our data-driven analysis, boosting network efficiency and decision-making.",
      buttonLabel: "Read More",
      modalContent: {
        modalHeading: "Modal Heading 4",
        modalText: "Modal Text 4",
      },
    },

    {
      id: 5,
      heading: "Scalability and Security ",
      text: "Ensure both scalability and security with our comprehensive solution",
      buttonLabel: "Read More",
      modalContent: {
        modalHeading: "Modal Heading 5",
        modalText: "Modal Text 5",
      },
    },

    // {
    //   id: 6,
    //   heading: 'Sixth Slide',
    //   text: 'A different text for the second slide.',
    //   buttonLabel: 'Read More',
    //   modalContent: {
    //     modalHeading: 'Modal Heading 6',
    //     modalText: 'Modal Text 6',
    //   },
    // },

    // {
    //   id: 7,
    //   heading: 'Seventh Slide',
    //   text: 'A different text for the second slide.',
    //   buttonLabel: 'Read More',
    //   modalContent: {
    //     modalHeading: 'Modal Heading 7',
    //     modalText: 'Modal Text 7',
    //   },
    // },

    // {
    //   id: 8,
    //   heading: 'Eighth Slide',
    //   text: 'A different text for the second slide.',
    //   buttonLabel: 'Read More',
    //   modalContent: {
    //     modalHeading: 'Modal Heading 8',
    //     modalText: 'Modal Text 8',
    //   },
    // },
    // ... (more slides)
  ];

  const openModal = (slide) => {
    setSelectedSlide(slide);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  // Handle clicks outside the modal to close it
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        closeModal();
      }
    };

    window.addEventListener("mousedown", handleOutsideClick);

    return () => {
      window.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

 

  const modalContent = (
    <>
      <p className="modal-np">The puspose behind of ISP Monitoring </p>
     
      To the purpose of our system, emphasizing its role in transforming
              telecom and broadband service monitoring through AI
      <hr />
        
     <strong>Performance Optimization</strong> 
 
     Monitoring the performance of an ISP helps ensure that users are getting the advertised speed and bandwidth. By tracking metrics such as latency, throughput, and packet loss, users can identify and address any issues affecting internet speed or reliability, thereby optimizing their internet connection's performance.
         <br/><br/>

        <strong> Reliability Assurance</strong>
        Reliable internet connectivity is crucial for uninterrupted business operations, communication, and online activities. ISP monitoring allows users to track downtime, outages, and disruptions in internet service. By staying informed about the reliability of their ISP, users can take proactive measures to minimize downtime and ensure continuous access to online resources.
 
          <br/><br/><strong>Cost Management</strong> 
          Monitoring ISP usage and performance helps users manage costs associated with their internet connection. By tracking bandwidth usage, users can identify opportunities to optimize their internet service plans, avoid overage charges, and negotiate better pricing with their ISP.
     
          <br/><br/><strong>Network Security</strong> 
          Monitoring ISP traffic and usage patterns enables users to detect and mitigate security threats such as malware, phishing attacks, and unauthorized access attempts. By analyzing network traffic and identifying anomalies, users can strengthen their network security defenses and protect sensitive information from cyber threats.
    
    
      {/* You can pass any JSX content you want */}
    </>
  );
  return (
    <div id="maindivs">
      <div id="carouselExampleCaptions" className="carousel slide">
        <div className="carousel-inner">
          <div className="carousel-item active">
            <img
              src="https://img.freepik.com/premium-photo/black-wifi-router-female-hand-sticking-out-smartphone-screen_295303-1883.jpg"
              className="d-block w-100"
              style={{ opacity:'0.56',height: "auto" }}
              alt="slider first"
            />
            <div className="carousel-caption  d-md-block">
              <div className="component-1 text-dark">ISP-Monitoring</div>

              <div
                className="component-paras"
                style={{fontWeight:"700", color: "black", marginTop: "-20px", fontStyle: "italic" }}
              >
                "Empowering Networks with Advanced AI"
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-np2">
        {/* <!-- section2 title --> */}
        <div className="cont2-titles">
          {/* <div class="cont2-titletext">What is ISP-Monitoring?</div> */}
        </div>

        {/* <!-- section part --> */}
        <div className="container21">
          {/* <!-- left part --> */}

          <div className="cont2-left cont2-text">
            <div
              className="left-text"
              style={{
                // fontFamily: "arial",

                fontFamily: "system-ui",
              }}
            >
            ISP monitoring, or Internet Service Provider monitoring, refers to the process of tracking and analyzing the performance, reliability, and usage of the internet connection provided by an Internet Service Provider (ISP). This monitoring typically involves gathering data related to bandwidth, latency, uptime, downtime, network congestion, and other metrics associated with the ISP's services.
            </div>
          </div>

          {/* right part */}
          <div className="cont2-right cont2-text">
            <div className="right-text">
              <img
                className="content2img"
                src="/Images/ISP.gif"
                alt="Description of the image"
              />
            </div>
          </div>
        </div>
        <div className="readmore">
          <ReadModal2 buttonText="Read More" modalContent={modalContent} />
        </div>
      </div>

      {/* section3 */}
      <section className="section3">
        <div className="cont2-title title ">Our Features</div>

        <div className="section3parts">
          <div
            className="content-np item31"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end",
            }}
          >
            <div>
              <strong style={{ paddingLeft: "20px" }}>
                {" "}
                Dynamic IP Address Monitoring
              </strong>
              <p
                className="aligned-paragraph item3text"
                style={{ marginBottom: "30px" }}
              >
                Explanation of real-time monitoring capabilities.
              </p>
            </div>
          </div>

          <div className="content-np item322">
            <strong
              className="item32-heading item31-conent"
              style={{
                paddingLeft: "15px",
                color: "black",
              }}
            >
              GenAI Automatic Troubleshooting
            </strong>
            <div className=" item31-conent-img  "></div>
            <p
              className="aligned-paragraph item3text mt-2"
              style={{ color: "black" }}
            >
              Detailing the AI-driven diagnostics and resolutions.
            </p>
          </div>

          <div className="content-np item33">
            <strong
              className=""
              style={{
                paddingLeft: "15px",
              }}
            >
              {" "}
              Task Logger and Scheduler
            </strong>
            <p className="aligned-paragraph item3text">
              Showcasing AI's efficiency in task management.
            </p>
          </div>

          <div className="content-np item34">
            <strong
              style={{
                paddingLeft: "15px",
              }}
            >
              Uplink and Downlink Analysis
            </strong>
            <p className="aligned-paragraph item3text" style={{ color: "black" }}>
              Insights into detailed traffic flow analysis.
            </p>
          </div>
          <div className="content-np item35">
            <strong
              style={{
                paddingLeft: "15px",
              }}
            >
              {" "}
              Real-Time Alerts
            </strong>
            <p className="aligned-paragraph item3text">
              Highlighting the proactive notification system for network
              anomalies.
            </p>
          </div>

          <div className="content-np item36">
            <strong
              style={{
                paddingLeft: "15px",
              }}
            >
              Interactive Dashboard
            </strong>
            <p className="aligned-paragraph item3text">
              A look at the user-friendly interface for data interpretation.
            </p>
          </div>

          <div className="content-np item37">
            <strong
              style={{
                paddingLeft: "15px",
              }}
            >
              Integration and API
            </strong>
            <p className="aligned-paragraph item3text" style={{ color: "black" }}>
              Integration with existing network management systems, ticketing
              systems, and other IT tools through APIs for seamless workflow
              automation and data exchange.
            </p>
          </div>
        </div>

        <div className="readmore">
          {/* <button className="read1">Read More</button> */}
        </div>
      </section>

      <div className="section4">
          
          <div className="main-cont">
            <div className="Cardcomp">
              <div className="card-container">
                <div className="row-ca">
                  <div className="col-ca">
                    <div className="card-head text-center">
                      <h2 className="head-t ">USP of ISP Monitoring Platforms</h2>
                     
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-container">
              <div className="row-ck">
                <div className="col-ck">
                  <div className="card-wrapper">
                    <div className="main-card">
                      <div className="innercon">
                        <div className="card-content1">
                          <h2>
                          Reduced Downtime and Enhanced Reliability{" "}
                            <FontAwesomeIcon icon={faArrowRight} />
                          </h2>
                          {/* <p>vbhf</p> */}
                        </div>
                        <img src="/Images/Performance_Optimization.gif" alt="" />
                      </div>
                    </div>
                    <div className="hover-card">
                      <div className="hover-content">
                        <h2> Reduced Downtime and Enhanced Reliability </h2>
                        <p>
                        Maximize uptime and reliability with our ISP monitoring, minimizing downtime and optimizing performance.{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-ck">
                  <div className="card-wrapper">
                    <div className="main-card">
                      <div className="innercon">
                        <div className="card-content1">
                          <h2>
                          Proactive Problem-Solving with AI 
                            <FontAwesomeIcon icon={faArrowRight} />{" "}
                          </h2>
                          {/* <p>vbhf</p> */}
                        </div>
                        <img src="/Images/artificial-intelligence-sa.gif" alt="" />
                      </div>
                    </div>
                    <div className="hover-card">
                      <div className="hover-content">
                        <h2>Proactive Problem-Solving with AI </h2>
                        <p>
                          {" "}
                          AI anticipates and prevents network disruptions, ensuring smooth operations and peak performance. {" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-ck">
                  <div className="card-wrapper">
                    <div className="main-card">
                      <div className="innercon">
                        <div className="card-content1">
                          <h2>
                          Cost-Effective Network Management{" "}
                            <FontAwesomeIcon icon={faArrowRight} />
                          </h2>
                          {/* <p>vbhf</p> */}
                        </div>
                        <img src="/Images/hand.gif" alt="" />
                      </div>
                    </div>
                    <div className="hover-card">
                      <div className="hover-content">
                        <h2>Cost-Effective Network Management</h2>
                        <p>
                        Streamline operations with our cost-effective network management, saving resources without compromising performance.{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-ck">
                  <div className="card-wrapper">
                    <div className="main-card">
                      <div className="innercon">
                        <div className="card-content1">
                          <h2>
                          Data-Driven Insights for Strategic Decisions<FontAwesomeIcon icon={faArrowRight} />
                          </h2>
                          {/* <p>vbhf</p> */}
                        </div>
                        <img src="/Images/shield.gif" alt="" />
                      </div>
                    </div>
                    <div className="hover-card">
                      <div className="hover-content">
                        <h2>Data-Driven Insights for Strategic Decisions</h2>
                        <p>
                        Unlock strategic insights with our data-driven analysis, boosting network efficiency and decision-making.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-ck">
                  <div className="card-wrapper">
                    <div className="main-card">
                      <div className="innercon">
                        <div className="card-content1">
                          <h2>
                          Scalability and Security
                            <FontAwesomeIcon icon={faArrowRight} />
                          </h2>
                          {/* <p>vbhf</p> */}
                        </div>
                        <img src="/Images/scalability.gif" alt="" />
                      </div>
                    </div>
                    <div className="hover-card">
                      <div className="hover-content">
                        <h2> Scalability and Security</h2>
                        <p>
                        Ensure both scalability and security with our comprehensive solution{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-ck">
                  <div className="card-wrapper">
                    <div className="main-card">
                      <div className="innercon">
                        <div className="card-content1">
                          <h2>
                          Expert Support{" "}
                            <FontAwesomeIcon icon={faArrowRight} />
                          </h2>
                          {/* <p>vbhf</p> */}
                        </div>
                        <img src="/Images/social-care.gif" alt="" />
                      </div>
                    </div>
                    <div className="hover-card">
                      <div className="hover-content">
                        <h2>Expert Support</h2>
                        <p>
                          {" "}
                          Our team of experienced professionals is available 24/7 to provide support and guidance, ensuring that your internet service operates smoothly at all times.{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

               
                
              </div>
            </div>
          </div>




          {/* <CarouselSlider/> */}
        </div>


      {/* <div class="section5">
          <div class="section5heading">
            <div class="section5heading1">Our Case Studies</div>
          
          </div>
    
          <div class="section5cards">
            <div class="item51 item55">
              <div class="item5content">
                <div class="item51img">Expert Team</div>
    
                <div class="item51text item5text">
                  <a href="" class="full-width-link a-np">
                    Our team comprises experienced web designers, developers, and
                    UX/UI experts who excel at translating ideas into exceptional
                    websites
                  </a>
                </div>
              </div>
            </div>
    
            <div class="item52 item55">
              <div class="item5content">
                <div class="item52img">Scalability</div>
    
                <div class="item52text item5text">
                  <a href="" class="full-width-link a-np">
                    We build websites that are scalable and adaptable to
                    accommodate your business growth and changing requirements.
                  </a>
                </div>
              </div>
            </div>
    
            <div class="item53 item55">
              <div class="item5content">
                <div class="item53img">Responsive Design</div>
    
                <div class="item53text item5text">
                  <a href="" class="full-width-link a-np">
                    We ensure that your website looks and functions flawlessly on
                    desktops, tablets, and smartphones, providing a consistent
                    experience to users.
                  </a>
                </div>
              </div>
            </div>
    
            <div class="item54 item55">
              <div class="item5content">
                <div class="item54img">User-Centric Approach</div>
    
                <div class="item54text item5text">
                  <a href="" class="full-width-link a-np">
                    Our design and development process centers around the needs
                    and preferences of your target audience, ensuring a
                    user-focused website.
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      {/* <div class="readmore">
      <button class="read">show More</button>
      </div> */}

      {/* section 6 */}

      {/* <section class="section6">
        <div class="section6title">
          <h1 class="section6title-np section6title-np1">Case Studies  </h1>
          <p class="section6title-np2"> */}
            {/* Discover services and offerings that can unlock new possibilities for
            you */}
          {/* </p>
        </div> */}
        {/* <!-- sectcion6 row --> */}

        {/* <div class="section6row">
          <div class="section61 section666"> */}
            {/* <!-- section6 row1 --> */}
            {/* <div class="_6row1col1 section666col _6text">
              <h1 class="_6textheading">Success Stories from Our Clients</h1>
            </div> */}

            {/* <!-- row1 col2 --> */}
            {/* <div class="_6row1col2 section666col"> */}
              {/* <div class="_6text"> */}
                {/* <ul> */}
                  {/* <li>
                    <a href="" class="a-np">
                      Featuring a few brief case studies or testimonials that
                      highlight the real-world impact and benefits of your
                      system
                    </a>
                  </li> */}
                  {/* <li>
                    <a href="" class="a-np"
                      >sdlkfmkInfosys Information Grid Solution Infosys Data
                      Wizard Solution</a
                    >
                  </li> */}
                {/* </ul>
              </div> */}
            {/* </div> */}
            {/* <!-- row1 col3 --> */}
            {/* <div class="_6row1col3 section666col">
              <div class="_6text">
                <ul>
                  <li>
                    <a href="" class="a-np"
                      >sdlkfmkInfosys Information Grid Solution Infosys Data
                      Wizard Solution</a
                    >
                  </li>
                  <li>
                    <a href="" class="a-np"
                      >sdlkfmkInfosys Information Grid Solution Infosys Data
                      Wizard Solution</a
                    >
                  </li>
                </ul>
              </div>
            </div> */}

            {/* <!-- row1 col4 --> */}
            {/* <div class="_6row1col4 section666col">
              <div class="_6text">
                <ul>
                  <li class="a-np">
                    
                      sdlkfmkInfosys Information Grid Solution Infosys Data
                      Wizard Solution
                    
                  </li>
                  <li class="a-np">
                    
                      sdlkfmkInfosys Information Grid Solution Infosys Data
                      Wizard Solution
                    
                  </li>
                </ul>
              </div>
            </div>
          </div> */}
            {/* <!-- section6 row2 --> */}
            {/* <div class="section62 section666"> */}
            {/* <!-- row2 col1 --> */}
            {/* <div class="_6row2col1 section666col">
              <div class="_6textheading"><h1>AI Powered Business</h1></div>
            </div> */}
            {/* <!-- row2 col2 --> */}
            {/* <div class="_6row2col2 section666col">
              <div class="_6text">
                <ul>
                  <li>
                    <a href="" class="a-np"
                      >sdlkfmkInfosys Information Grid Solution Infosys Data
                      Wizard Solution</a
                    >
                  </li>
                  <li>
                    <a href="" class="a-np"
                      >sdlkfmkInfosys Information Grid Solution Infosys Data
                      Wizard Solution</a
                    >
                  </li>
                </ul>
              </div>
            </div> */}
            {/* <!-- row2 col3 --> */}
            {/* <div class="_6row2col3 section666col">
              <div class="_6text">
                <ul>
                  <li>
                    <a href="" class="a-np"
                      >sdlkfmkInfosys Information Grid Solution Infosys Data
                      Wizard Solution</a
                    >
                  </li>
                  <li>
                    <a href="" class="a-np"
                      >sdlkfmkInfosys Information Grid Solution Infosys Data
                      Wizard Solution</a
                    >
                  </li>
                </ul>
              </div>
            </div> */}
            {/* <!-- row2 col4 --> */}
            {/* <div class="_6row2col4 section666col">
              <div class="_6text">
                <ul>
                  <li>
                    <a href="" class="a-np"
                      >sdlkfmkInfosys Information Grid Solution Infosys Data
                      Wizard Solution</a
                    >
                  </li>
                  <li>
                    <a href="" class="a-np"
                      >sdlkfmkInfosys Information Grid Solution Infosys Data
                      Wizard Solution</a
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div> */}
          </div>
      //   </div>
      // </section>
    // </div>
  );
}

export default ISPmonitoring;
