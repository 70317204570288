import React from 'react'
import { Link } from 'react-router-dom'
import "./ProductModal.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBriefcase, faMicrochip, faIndustry, faShoppingCart, faDatabase, faComments, faLightbulb,faCogs } from "@fortawesome/free-solid-svg-icons";

export default function 
() {
  
  return (
    <>
    <div className="col-md-12 col-sm-12 col-xs-12">
    <h2 className="submenu-h2-heading ">Case Study</h2>
  </div>
    <div className="card-Product">
     
      <div className="Product-Product">

      <div className="service-row">
          <div className="service-column">
            {/* <div className="icon-and-text">
              <FontAwesomeIcon icon={faCogs} className="section-icon" />

              <div className="heading-h6"> All Platform </div>
            </div> */}
          </div>

          <h3>E-cart</h3> 

          <div className="service-column">
            <ul className="list-inline mb0">
             
              
                <li className="Product-list-item">
                <Link to="/NavbarCaseStudy/CsBharatMake">
            BharatMake
                </Link>
              </li>

               <li className="Product-list-item">
                <Link to="/NavbarCaseStudy/CsDigitalKeyStore">
                DigitalKeyStore
                </Link>
              </li>

              <li className="Product-list-item">
                <Link to="/NavbarCaseStudy/CsVirtualWallet">
                VirtualWallet
                </Link>
              </li>

              
            </ul>

            </div>

            <hr />

           <h3>CloudCipher</h3> 

            <div className="service-column">


            <ul className="list-inline mb0">
             
              
             

           <li className="experience-list-item">
             <Link to="/NavbarCaseStudy/CsModeration" >Moderation</Link>
           </li>

           <li className="experience-list-item">
             <Link to="/NavbarCaseStudy/CsDataSecurity" >DataSecurity</Link>
           </li>

           <li className="experience-list-item">
             <Link to="/NavbarCaseStudy/CsBankSecurity" >BankSecurity</Link>
           </li>
         </ul>






          </div>


        </div>

       

        {/* ERP */}

       
      </div>
      </div>
      </>
  )
}
