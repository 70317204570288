import React, { useState } from "react";
import "./template.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

const ReadModal2 = ({ buttonText, modalContent }) => {
  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => {
    setIsOpen(true);
    document.body.style.overflow = 'hidden';
  };

  const closeModal = () => {
    setIsOpen(false);
    document.body.style.overflow = 'unset';
  };

  return (
    <div className="modal-container">
      <button className="read1" onClick={openModal}>
        {buttonText}
      </button>

      {isOpen && (
        <div className="modal-backdrop" onClick={closeModal}>
          <div className="modal-dialog" onClick={e => e.stopPropagation()}>
            <button className="modal-close" onClick={closeModal}>
              <FontAwesomeIcon icon={faTimes} />
            </button>
            {modalContent}
          </div>
        </div>
      )}
    </div>
  );
};

export default ReadModal2;